import React, { useState, useEffect } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import StatSelection from './StatSelection';

function DisplayStats(props) {
    const [reportType, setReportType] = useState('Custom');

    let reportTypes = props.tabName === 'contracts'
        ? ['Custom', 'Default Contracts', 'Copy From Above']
        : [
            'Custom',
            'Default Batter',
            'Default Catcher',
            'Default Starting Pitcher',
            'Default Relief Pitcher',
            'Copy From Above'
        ];

    let scaleUpOptions = [
        {
            value: 0,
            label: 'Not Scaled Up',
        },
        {
            value: 1,
            label: 'Scaled Up',
        },
        {
            value: 2,
            label: 'Both',
        },
    ]

    useEffect(() => {
        if (!reportType) {
            return;
        }

        props.updateReport(reportType);
    }, [reportType])

    useEffect(() => {
        if (props.displayStats.length < 1) {
            setReportType('Custom');
        }
    }, [props.displayStats])

    let hasBlankStat = props.displayStats.some(disStat => disStat.stat === '' || disStat.stat == null)

    return (
        <React.Fragment>
            <h4>Display Stats</h4>
            <Row>
                <div id='reportForm' key={'custom-inline-radio'}>
                    {
                        reportTypes.map(lbl => {
                            if (lbl.replaceAll(' ', '') === reportType) {
                                return (
                                    <Form.Check
                                        custom
                                        inline
                                        name='reportType'
                                        label={lbl}
                                        type='radio'
                                        key={lbl.replaceAll(' ', '')}
                                        id={`custom-inline-radio-${lbl.replaceAll(' ', '').toLowerCase()}`}
                                        checked
                                        onChange={(event) => {
                                            if (event.target.value) {
                                                setReportType(lbl.replaceAll(' ', ''))
                                            }
                                        }}
                                    />
                                )
                            }
                            else {
                                return (
                                    <Form.Check
                                        custom
                                        inline
                                        name='reportType'
                                        label={lbl}
                                        type='radio'
                                        key={lbl.replaceAll(' ', '')}
                                        id={`custom-inline-radio-${lbl.replaceAll(' ', '').toLowerCase()}`}
                                        onChange={(event) => {
                                            if (event.target.value) {
                                                setReportType(lbl.replaceAll(' ', ''))
                                            }
                                        }}
                                    />
                                )
                            }
                        })
                    }
                </div>
                {
                    reportType === 'CopyFromAbove' &&
                    <Button variant='secondary' onClick={() => props.updateReport('CopyFromAbove')}>
                        Again
                    </Button>
                }
            </Row>
            <Row>
                {
                    props.displayScaleUpOptions &&
                    <div>
                        (
                        {
                            scaleUpOptions.map((option, optionIndex) => {
                                if (option.value === props.scaleUp) {
                                    return (
                                        <Form.Check
                                            custom
                                            inline
                                            checked
                                            name='scaleUp'
                                            label={option.label}
                                            type='radio'
                                            key={optionIndex}
                                            id={`custom-inline-radio-${option.label.replaceAll(' ', '').toLowerCase()}`}
                                            onChange={() => {
                                                props.setScaleUp(option.value);
                                            }}
                                        />
                                    )
                                }
                                else {
                                    return (
                                        <Form.Check
                                            custom
                                            inline
                                            name='scaleUp'
                                            label={option.label}
                                            type='radio'
                                            key={optionIndex}
                                            id={`custom-inline-radio-${option.label.replaceAll(' ', '').toLowerCase()}`}
                                            onChange={() => {
                                                props.setScaleUp(option.value);
                                            }}
                                        />
                                    )
                                }
                            })
                        }
                        )
                    </div>
                }
            </Row>
            <div
                id='DisStat'
                style={{
                    width: '100%',
                    backgroundColor: 'lightgrey',
                    border: hasBlankStat ? 'solid 2px red' : 'solid 1px black',
                    padding: '10px'
                }}>
                <StatSelection
                    tabName={props.tabName}
                    selectableStatsByGroup={props.selectableStatsByGroup}
                    colGroups={
                        props.selectableStatGroups.filter(group => {
                            return !['throughX', 'dateRange'].includes(props.tabName)
                                || group.id !== 43;
                        })
                    }
                    reportType={reportType}
                    section={'Stat'}
                    stats={props.displayStats}
                    setStats={props.setDisplayStats}
                    hideFilters={props.hideFilters}
                    timePeriodNotSelectable={props.timePeriodNotSelectable}
                    getStatInfoFromColumnById={props.getStatInfoFromColumnById}
                />
            </div>
        </React.Fragment>
    )
}

export default DisplayStats;