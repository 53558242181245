import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ component: Component, ...rest }) {

    return (
        <Route {...rest} render={props => (
            <>
                <AuthenticatedTemplate>
                    <Component {...rest} />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Redirect to={{ pathname: '/home', state: { from: rest.location } }} />
                </UnauthenticatedTemplate>
            </>
        )} />
    );
}

export default PrivateRoute;