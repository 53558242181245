import { LogLevel } from '@azure/msal-browser';

export const b2cPolicies = {
    policies: {
        signUpSignIn: 'B2C_1_signin',
    },
    authorities: {
        signUpSignIn: {
            URL: 'https://mlbpaplayersappprod.b2clogin.com/mlbpaplayersappprod.onmicrosoft.com/B2C_1_signin',
        },
    },
    authorityDomain: 'mlbpaplayersappprod.b2clogin.com',
};

export const defaultRequest = {
    redirectUri: '/redirect',
    scopes: [
        'https://mlbpaplayersappprod.onmicrosoft.com/webapi/default',
    ],
}

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_CLIENTID,
        authority: b2cPolicies.authorities.signUpSignIn.URL,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: '/',
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};