import React from 'react';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import Header from './Header';
import Home from './Home';
import CompReportWrapper from './Components/CompReportWrapper';
import PlayerPage from './Components/PlayerPage/PlayerPage';
import Leaderboards from './Components/Leaderboards/Leaderboards';
import RankReport from './Components/RankReport/RankReport';
import QueryTool from './Components/QueryTool/QueryTool';
import LargestContracts from './Components/LargestContractsReport/LargestContracts';
import Awards from './Components/AwardsReport/Awards';
import RecentSignings from './Components/RecentSignings/RecentSignings';
import Blank from './Components/Blank';
import PrivateRoute from './Components/Common/PrivateRoute';

function Router({ msalInstance }) {
    return (
        <MsalProvider instance={msalInstance}>
            <BrowserRouter>
                <div style={{ height: '100%' }}>
                    <Route path='/:tab' component={Header} />
                    <Route exact path='/'>
                        <Redirect to='/home' />
                    </Route>
                    <PrivateRoute path='/home' component={Home} />
                    <PrivateRoute path='/player' component={PlayerPage} />
                    <PrivateRoute path='/leaderboards' component={Leaderboards} />
                    <PrivateRoute path='/compreport' component={CompReportWrapper} />
                    <PrivateRoute path='/query' component={QueryTool} />
                    <PrivateRoute path='/rankreport' component={RankReport} />
                    <PrivateRoute path='/largestcontractsreport' component={LargestContracts} />
                    <PrivateRoute path='/awardsreport' component={Awards} />
                    <PrivateRoute path='/recentsignings' component={RecentSignings} />
                </div>
            </BrowserRouter>
        </MsalProvider>
    )
}

export default Router;