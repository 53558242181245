import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Alert } from 'react-bootstrap';
import { defaultRequest } from './authConfig';
import { userService } from './Services/UserSvc';

function Header(props) {
    let errors = [];

    let selectedTab = props.match.params.tab;

    const { accounts, instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const handleLoginPopup = async () => {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            let res = null;
            try {
                res = await instance.loginPopup(defaultRequest);
                if (!userService.validateUserGroup(res.accessToken, 'Certified Agent'))
                    instance.logoutPopup({ mainWindowRedirectUri: '/', })
            
                let a = res;
            } catch (ex) {
                console.log(ex);
            }

        }
    };

    return (
        <React.Fragment>
            <div className='header'>
                <img
                    width='535'
                    height='150'
                    src='/images/model3.jpg'
                    alt='logo'
                    style={{ float: 'left' }}
                />
                <a href='/home'>
                    <img
                        width='143'
                        height='150'
                        src='/images/mlbpalogo.png'
                        alt='logo'
                        style={{ float: 'right' }}
                    />
                </a>
            </div>
            <br />
            <br />
            <Navbar variant='dark'>
                <Nav className='mr-auto' style={{ width: '100%' }}>
                    <Nav.Link
                        style={selectedTab === 'home' ? { color: 'white' } : null}
                        href='/home'
                    >
                        Home
                    </Nav.Link>
                    <AuthenticatedTemplate>
                        <Nav.Link
                            style={selectedTab === 'player' ? { color: 'white' } : null}
                            href='/player'
                        >
                            Player Page
                        </Nav.Link>
                        <Nav.Link
                            style={selectedTab === 'leaderboards' ? { color: 'white' } : null}
                            href='/leaderboards'
                        >
                            Leaderboards
                        </Nav.Link>
                        <Nav.Link
                            style={selectedTab === 'compreport' ? { color: 'white' } : null}
                            href='/compreport'
                        >
                            Comparison Report
                        </Nav.Link>
                        <Nav.Link
                            style={selectedTab === 'query' ? { color: 'white' } : null}
                            href='/query'
                        >
                            Query Tool
                        </Nav.Link>
                        <NavDropdown title='Reports'>
                            <NavDropdown.Item href='/rankreport'>Rank Report</NavDropdown.Item>
                            <NavDropdown.Item href='/largestcontractsreport'>Largest Contracts Report</NavDropdown.Item>
                            <NavDropdown.Item href='/awardsreport'>Awards Report</NavDropdown.Item>
                            <NavDropdown.Item href='/recentsignings'>Recent Signings</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Item
                            style={{ color: 'white', margin: 'auto 0 auto auto' }}
                            onClick={() => {
                                instance.logoutPopup({
                                    mainWindowRedirectUri: '/',
                                })}}
                        >
                            Logout {accounts[0]?.username}
                        </Nav.Item>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <Nav.Link
                            style={selectedTab === 'login' ? { color: 'white', margin: 'auto 0 auto auto' } : { margin: 'auto 0 auto auto' }}
                            onClick={handleLoginPopup}
                        >
                            Login
                        </Nav.Link>
                    </UnauthenticatedTemplate>
                </Nav>
            </Navbar>
            {errors}
        </React.Fragment>
    )
}

export default Header;