import React, { useState, useEffect } from 'react';
import AutoComplete from '../Common/AutoComplete';
import PlayerSearch from '../Common/PlayerSearch';
import PlayerBio from '../Common/PlayerBio';
import { columnManager } from '../Common/ColumnManager';
import PlayerStats from './PlayerStats';
import GameLogs from './GameLogs';
import PlayerSplits from './PlayerSplits';
import Contracts from './Contracts';
import Transactions from './Transactions';
import { GetPlayers } from '../../Services/ApiSvc';
import { getPlayers } from '../../Services/FetchSvc';
import { Navbar, Nav } from 'react-bootstrap';
import ReliefPitcherReport from './ReliefPitcherReport';
import PlayerAwards from './PlayerAwards';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { defaultRequest } from '../../authConfig';

function PlayerPage() {
    const [allPlayers, setAllPlayers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [playerId, setPlayerId] = useState(0);
    const [player, setPlayer] = useState({})
    const [tabName, setTabName] = useState('transactions');
    const { instance } = useMsal();

    useEffect(() => {
        (async function Initialize() {
            let tokenResponse = null;
            try {
                tokenResponse = await instance.acquireTokenSilent(defaultRequest);
            } catch (ex) {
                if (ex instanceof InteractionRequiredAuthError)
                    tokenResponse = await instance.acquireTokenPopup(defaultRequest);
            }
            
            let allPlayersFromAPI = await getPlayers(tokenResponse.accessToken);
            let rankReportGroups = await columnManager.getAllGroupsBySection('PlayerPage');

            setAllPlayers(allPlayersFromAPI);
            setGroups(rankReportGroups);
        })()
    }, []);

    useEffect(() => {
        if (playerId > 0) {
            let player = allPlayers.find((p) => p.id === playerId)
            setPlayer(player)
            setTabName('transactions');
        }
    }, [playerId]);

    return (
        <div className='body player-page'>
            <div className='row'>
                <div style={{ margin: '0px 0px 0px auto' }}>
                    <PlayerSearch
                        key={0}
                        players={allPlayers}
                        label={'Player Search'}
                        onSelect={(playerId, platformSeason, posFlag) => setPlayerId(playerId)}
                    />
                </div>
            </div>
            <br />
            {
                playerId > 0 &&
                <div className='pdfSection'>
                    <PlayerBio playerId={playerId} />
                    <div className='pdfChunk pdfIgnore'>
                        <br />
                        <Navbar bg='light' variant='light' className='playerNav'>
                            <Nav>
                                <Nav.Link className={tabName === 'stats' ? 'nav-selected' : null} href='#stats' onClick={() => setTabName('stats')}>
                                    {
                                        tabName === 'stats' &&
                                        <span style={{ transform: 'translateX(32px) rotate(135deg)' }}></span>
                                    }
                                    Player Stats
                                </Nav.Link>
                                <Nav.Link hidden='true' className={tabName === 'games' ? 'nav-selected' : null} href='#games' onClick={() => setTabName('games')}>
                                    {
                                        tabName === 'games' &&
                                        <span style={{ transform: 'translateX(34px) rotate(135deg)' }}></span>
                                    }
                                    Game Logs
                                </Nav.Link>
                                <Nav.Link hidden='true' className={tabName === 'splits' ? 'nav-selected' : null} href='#splits' onClick={() => setTabName('splits')}>
                                    {
                                        tabName === 'splits' &&
                                        <span style={{ transform: 'translateX(12px) rotate(135deg)' }}></span>
                                    }
                                    Splits
                                </Nav.Link>
                                <Nav.Link className={tabName === 'awards' ? 'nav-selected' : null} href='#awards' onClick={() => setTabName('awards')}>
                                    {
                                        tabName === 'awards' &&
                                        <span style={{ transform: 'translateX(12px) rotate(135deg)' }}></span>
                                    }
                                    Awards
                                </Nav.Link>
                                <Nav.Link className={tabName === 'contracts' ? 'nav-selected' : null} href='#contracts' onClick={() => setTabName('contracts')}>
                                    {
                                        tabName === 'contracts' &&
                                        <span style={{ transform: 'translateX(27px) rotate(135deg)' }}></span>
                                    }
                                    Contracts
                                </Nav.Link>
                                <Nav.Link className={tabName === 'transactions' ? 'nav-selected' : null} href='#transactions' onClick={() => setTabName('transactions')}>
                                    {
                                        tabName === 'transactions' &&
                                        <span style={{ transform: 'translateX(60px) rotate(135deg)' }}></span>
                                    }
                                    Transaction/Roster
                                </Nav.Link>
                                {
                                    player.posFlag === 'P' &&
                                    <Nav.Link className={tabName === 'reliefPitcher' ? 'nav-selected' : null} href='#reliefPitcher' onClick={() => setTabName('reliefPitcher')}>
                                        {
                                            tabName === 'reliefPitcher' &&
                                            <span style={{ transform: 'translateX(35px) rotate(135deg)' }}></span>
                                        }
                                        Relief Pitcher
                                    </Nav.Link>
                                }
                            </Nav>
                        </Navbar>
                        <br />
                    </div>
                    {
                        tabName === 'stats' &&
                        <PlayerStats player={player} />
                    }
                    {
                        tabName === 'games' &&
                        <GameLogs />
                    }
                    {
                        tabName === 'splits' &&
                        <PlayerSplits />
                    }
                    {
                        tabName === 'awards' &&
                        <PlayerAwards
                            groups={groups}
                            player={player}
                        />
                    }
                    {
                        tabName === 'contracts' &&
                        <Contracts
                            playerId={playerId}
                            groups={groups}
                        />
                    }
                    {
                        tabName === 'transactions' &&
                        <Transactions
                            playerId={playerId}
                            groups={groups}
                        />
                    }
                    {
                        tabName === 'reliefPitcher' &&
                        <ReliefPitcherReport
                            groups={groups}
                            player={player}
                        />
                    }
                    <br />
                </div>
            }
        </div>
    )
}

export default PlayerPage;