import React, { useEffect, useState } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import { GetPlayerPageAwards, GetPlayerPageAwardsLeaderboards } from '../../Services/ApiSvc';
import ExcelExporter from '../Common/ExcelExporter';
import PDFExporter from '../Common/PDFExporter';
import TabulatorTable from '../Common/TabulatorTable';

function PlayerAwards(props) {
    const [award, setAward] = useState(null);
    const [awardLeaderboards, setAwardLeaderboards] = useState(null);
    const [awardOptions, setAwardOptions] = useState(null);
    const [columnGroups, setColumnGroups] = useState(null);
    const [data, setData] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [loading, setLoading] = useState(true);
    const [omitColumns, setOmitColumns] = useState([78, 88, 60, 64, 69, 51]);
    const [showSeasonAwardModal, setShowSeasonAwardModal] = useState(false);

    useEffect(() => {
        if (props.groups.length < 1 || props.player === null) {
            return;
        }

        let colGroups = {
            playerAwards: [],
            awardLeaderboard: [],
        };

        (async () => {
            setLoading(true);

            let res = await GetPlayerPageAwards(props.player);
            if (res.exceptionMsg) {
                setErrorMsg(res.exceptionMsg);
            } else if (res.data?.length === 0) {
                setErrorMsg('No Data');
            } else {
                setErrorMsg('');

                colGroups.playerAwards = props.groups.find(group => group.label === 'PlayerAwards').columns
                    .slice();
                colGroups.awardLeaderboard = props.groups.find(group => group.label === 'PlayerAwardsLeaderboards').columns
                    .slice();
                let dataColMap = {};
                let awardCols = colGroups.awardLeaderboard.find(col => col.title === 'Award').formInputs.selectOptions
                    .reduce((acc, cur) => {
                        let retObj = Object.assign({}, acc);
                        retObj[cur.label] = { id: cur.value };
                        return retObj;
                    }, {});

                const awardNameRegex = /Platform Year AwardPlace where \(Award=(?<awardName>.+)\)/;
                res.columns.forEach(col => {
                    dataColMap[col.displayName] = col.displayNumber;
                    let awardNameMatch = col.displayName.match(awardNameRegex);
                    if (awardNameMatch) {
                        let awardName = awardNameMatch?.groups?.awardName;
                        awardCols[awardName].displayNumber = col.displayNumber;
                        awardCols[awardName].seasons = res.data.filter(row => row[col.displayNumber] > 0)
                            .map(row => row.PlatformSeason);
                    }
                })

                colGroups.playerAwards.forEach(col => {
                    col.field = dataColMap[col.formInputs.defaultTimePeriod + ' ' + col.field]?.toString() ?? col.field;
                    if (col.title === 'Awards') {
                        col.width = 500;
                        col.cellClick= function(e, cell) {
                            setAwardOptions({
                                season: cell.getRow().getData().PlatformSeason,
                                awards: awardsBySeason[cell.getRow().getData().PlatformSeason],
                            });
                            setShowSeasonAwardModal(true);
                        }
                    }
                })
                setColumnGroups(colGroups);

                let awardsBySeason = {};
                let leaderboards = {};
                Object.keys(awardCols).forEach(awardName => {
                    awardCols[awardName].seasons.forEach(season => {
                        if (!awardsBySeason[season])
                            awardsBySeason[season] = [];

                        awardsBySeason[season].push({
                            label: awardName,
                            value: awardCols[awardName].id,
                        });
                        leaderboards[season + '-' + awardCols[awardName].id] = null;
                    });
                });

                setData(res.data);
                setLoading(false);

                leaderboards = await getLeaderboards(awardsBySeason, colGroups, leaderboards);
                setAwardLeaderboards(leaderboards);
            }
        })();
    }, [])

    async function getLeaderboards(awardsBySeason, colGroups, leaderboards) {
        let leaderboardsRes = await GetPlayerPageAwardsLeaderboards(awardsBySeason, colGroups.awardLeaderboard);

        leaderboardsRes.forEach(leaderboard => {
            let season = leaderboard.data[0].PlatformSeason;
            let columnMap = leaderboard.columns.reduce((acc, cur) => {
                let retObj = Object.assign({}, acc);
                retObj[cur.displayNumber] = cur.displayName;
                return retObj;
            }, {});
            let playerData = {};
            let data = leaderboard.data.map(row => {
                let retObj = {};
                Object.keys(columnMap).forEach(displayNumber => {
                    retObj[columnMap[displayNumber]] = row[displayNumber];
                })
                retObj['Platform Year Award'] = null;
                if (row.PlayerId === props.player.id)
                    playerData = Object.assign({}, retObj);
                return retObj;
            });
            awardsBySeason[season].forEach(seasonAward => {
                let awardColumnIds = [617, 619, 618, 770]
                let tableHeaderColumns = [2, 591, 771, 770];
                let seasonAwardLeaderboard = {};
                let seasonAwardPlaceCol = `Platform Year AwardPlace where (Award=${seasonAward.label})`;
                let seasonAwardLeagueCol = `Platform Year Award League where (Award=${seasonAward.label})`;
                let seasonAwardPosCol = `Platform Year Award Position where (Award=${seasonAward.label})`;
                seasonAwardLeaderboard.data = data
                    .filter(row => row[seasonAwardPlaceCol] > 0
                        && row[seasonAwardLeagueCol] === playerData[seasonAwardLeagueCol]
                        && (!['Gold Glove', 'FBA'].includes(seasonAward.label) || row[seasonAwardPosCol] === playerData[seasonAwardPosCol])
                    )
                    .map(row => {
                        let retRow = Object.assign({}, row);
                        retRow['Platform Year Award'] = seasonAward.label;
                        return retRow;
                    });
                seasonAwardLeaderboard.columns = colGroups.awardLeaderboard
                    .filter(col => {
                        return !tableHeaderColumns.includes(col.id)
                            || (col.id === 770 && ['All-MLB TM', 'ASE'].includes(seasonAward.label))
                    })
                    .map(col => {
                        let retCol = Object.assign({}, col);
                        if (!retCol.field.includes(retCol.formInputs.defaultTimePeriod))
                            retCol.field = `${retCol.formInputs.defaultTimePeriod} ${retCol.field}`;
                        
                        if (awardColumnIds.includes(retCol.id) && !retCol.field.includes(seasonAward.label))
                            retCol.field += ` where (Award=${seasonAward.label})`;

                        switch (retCol.statPosType) {
                            case 'b':
                                retCol.field += ' as Batter';
                                break;
                            case 'p':
                                retCol.field += ' as Pitcher';
                                break;
                            case 'f':
                                retCol.field += ' as Fielder';
                                break;
                            default:
                                retCol.field += '';
                                break;
                        }

                        return retCol;
                    });
                leaderboards[`${season}-${seasonAward.value}`] = seasonAwardLeaderboard;
            })
        })

        return leaderboards;
    }

    function getSorterFunc(leaderboardTable) {
        let award = leaderboardTable.data[0]['Platform Year Award'];

        return function (a, b) {
            return a[`Platform Year AwardPlace where (Award=${award})`]
                - b[`Platform Year AwardPlace where (Award=${award})`];
        }
    }

    function getTabulatorHeader(leaderboardTable) {
        let award = leaderboardTable.data[0]['Platform Year Award'];
        let season = leaderboardTable.data[0]['Platform Year Season'];
        let league = leaderboardTable.data[0][`Platform Year Award League where (Award=${award})`];
        let header = `${season} ${league} ${award}`;
        if (['Gold Glove', 'FBA'].includes(award)) {
            let position = leaderboardTable.data[0][`Platform Year Award Position where (Award=${award})`];
            header += ` (${position})`;
        }
        return header;
    }

    function getTabulatorInitSort(leaderboardTable) {
        let award = leaderboardTable.data[0]['Platform Year Award'];
        let sortArr = [
            {
                column: `Platform Year AwardPlace where (Award=${award})`,
                dir: 'asc',
            },
        ]
        if (['FBA', 'GG'].includes(award))
            sortArr.push({
                column: 'Platform Year Primary Position',
                dir: 'asc',
            });
        return sortArr;
    }

    function updateAwardSelection(season, awardId) {
        setAward(`${season}-${awardId}`);
        setShowSeasonAwardModal(false);

        let columnsToOmit = [78, 88, 60, 64, 69, 51];
        switch (awardId) {
            case '3', '30':
                columnsToOmit = [88, 60, 64, 69, 51];
                break;
            case '4':
                columnsToOmit = [78, 64, 69, 51];
                break;
            case '20', '21':
                columnsToOmit = [78, 88, 60, 51];
                break;
            case '2':
                columnsToOmit = [78, 88, 60, 64, 69];
                break;
            default:
                columnsToOmit = [78, 88, 60, 64, 69, 51];
                break;
        }
        setOmitColumns(columnsToOmit);
    }

    return(
        <React.Fragment>
            <Row className='pdfChunk'>
                <h3>Awards</h3>
                {
                    data &&
                    <div
                        className='pdfIgnore'
                        style={{ margin: '0px 0px 0px auto' }}
                    >
                        <ExcelExporter
                            reportName={'PlayerAwards'}
                            tables={[
                                {
                                    columns: columnGroups?.playerAwards,
                                    data: data,
                                },
                                {
                                    columns: awardLeaderboards
                                        ? awardLeaderboards[award]?.columns.filter(column => !omitColumns.includes(column.id))
                                        : null,
                                    data: awardLeaderboards ? awardLeaderboards[award]?.data : null,
                                    sortFunc: awardLeaderboards && awardLeaderboards[award]?.data
                                        ? getSorterFunc(awardLeaderboards[award])
                                        : null,
                                    tableHeader: awardLeaderboards && awardLeaderboards[award]?.data
                                        ? getTabulatorHeader(awardLeaderboards[award])
                                        : null,
                                },
                            ]}
                        />
                        <PDFExporter fileName='PlayerAwards' />
                    </div>
                }
            </Row>
            {
                data && columnGroups &&
                <>
                    <div
                        className='pdfChunk'
                        style={{
                            marginLeft: '30px',
                            width: data
                                ? (columnGroups.playerAwards.reduce((acc, cur) => acc + ((cur.width) > 20
                                    ? (cur.width)
                                    : 20), 0)) + 4
                                : '100%'
                        }}
                    >
                        <TabulatorTable
                            cols={columnGroups.playerAwards}
                            data={data}
                            selectable={false}
                        />
                    </div>
                    <AwardSelectionModal
                        awardOptions={awardOptions}
                        show={showSeasonAwardModal}
                        setShow={setShowSeasonAwardModal}
                        setAward={updateAwardSelection}
                    />
                    <br />
                </>
            }
            {
                loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
            {
                errorMsg &&
                <React.Fragment>
                    <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center' }}>{errorMsg}</p>
                    <br />
                    <br />
                </React.Fragment>
            }
            {
                award && awardLeaderboards &&
                <>
                    <div
                        className='pdfChunk'
                        style={{
                            marginLeft: '30px',
                            width: data
                                ? (
                                    awardLeaderboards[award].columns
                                        .filter(column => !omitColumns.includes(column.id))
                                        .reduce(
                                            (acc, cur) => acc + ((cur.width) > 20
                                                ? (cur.width)
                                                : 20
                                            ),
                                            0
                                        )
                                ) + 4
                                : '100%'
                        }}
                    >
                        <TabulatorTable
                            cols={awardLeaderboards[award].columns.filter(column => !omitColumns.includes(column.id))}
                            data={awardLeaderboards[award].data}
                            header={getTabulatorHeader(awardLeaderboards[award])}
                            initSort={getTabulatorInitSort(awardLeaderboards[award])}
                        />
                    </div>
                </>
            }
            {
                award && !awardLeaderboards &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
        </React.Fragment>
    );
}

export default PlayerAwards;


function AwardSelectionModal(props) {
    const [awardButtons, setAwardButtons] = useState(null);
    const handleClose = () => props.setShow(false);
    

    useEffect(() => {
        if (props.awardOptions?.awards) {
            const buttons = props.awardOptions?.awards.map(award =>
                <Row style={{ paddingTop: '10px' }}>
                    <Button
                        variant="info"
                        onClick={() => props.setAward(props.awardOptions.season, award.value)}
                    >
                        {award.label}
                    </Button>
                </Row>
            )
            setAwardButtons(buttons);
        } else {
            setAwardButtons(null);
        }
    }, [props.awardOptions])

    return (
        <>
            <Modal show={props.awardOptions?.awards && props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Award Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>Select an award for the {props.awardOptions?.season} season:</Row>
                    {awardButtons}
                </Modal.Body>
            </Modal>
        </>
    );
}