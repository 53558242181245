import React, { useState, useEffect } from 'react';
import AutoComplete from './AutoComplete';
import SeasonDropDown from './SeasonDropDown';

function PlayerSearch(props) {
    function setPlayer(playerId, platformSeason) {
        if (playerId > 0) {
            let player = props.players.find((p) => {
                if (p.id === playerId) {
                    return p;
                }
            })
            props.onSelect(playerId, platformSeason ?? player.maxSeason, player.posFlag);
        }
    }

    return (
        <div className='row playerSearch'>
            <AutoComplete
                items={props.players.map(p => { return { title: `${p.name}${p.hasDupName ? ` (B:${p.dob})` : ''}`, key: p.id } })}
                label={props.label}
                value={props.players.find(player => player.id === props.selectedPlayer?.playerId)?.name}
                onSelect={(key) => setPlayer(key)}
            />
            <div style={{ padding: '0px 50px 0px 0px' }} />
            {
                props.selectedPlayer?.playerId > 0 &&
                <SeasonDropDown
                    label={'Platform Season'}
                    minSeason={props.players.find(player => player.id === props.selectedPlayer?.playerId)?.minSeason}
                    maxSeason={props.players.find(player => player.id === props.selectedPlayer?.playerId)?.maxSeason}
                    selectedSeason={props.selectedPlayer?.platformSeason ?? props.selectedPlayer?.maxSeason}
                    setSeason={(year) => setPlayer(props.selectedPlayer?.playerId, year)}
                />
            }
        </div>
    );
}

export default PlayerSearch;