import React, { useState, useEffect } from 'react';
import { Button, Navbar, Nav } from 'react-bootstrap';
import PlayerSearch from '../Common/PlayerSearch';
import { GetPlayers, GetRankReportsPitchers, GetRankReportsBatters, GetRankReportsFielders, GetQualifierThresholds } from '../../Services/ApiSvc';
import { columnManager } from '../Common/ColumnManager';
import ColumnSelector from '../Common/ColumnSelector';
import TabulatorTable from '../Common/TabulatorTable';
import PDFExporter from '../Common/PDFExporter';
import ExcelExporter from '../Common/ExcelExporter';
import RankReportForm from './RankReportForm';

function RankReport() {
    const [scrollPos, setScrollPos] = useState({ x: 0, y: 0 });
    const [allPlayers, setAllPlayers] = useState([]);
    const [columns, setColumns] = useState([]);
    const [defRankColumns, setDefRankColumns] = useState([]);
    const [groups, setGroups] = useState([]);
    const [category, setCategory] = useState('batting');
    const [selectedPlayers, setSelectedPlayers] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [playerCount, setPlayerCount] = useState(0);
    const [groupId, setGroupId] = useState(29);
    const [selectedCols, setSelectedCols] = useState([]);
    const [formOptions, setFormOptions] = useState({});
    const [qualifierThresholds, setQualifierThresholds] = useState([]);

    const handleScroll = () => {
        if (window.scrollY > -1 && window.scrollX > -1 && (window.scrollY !== scrollPos.y || window.scrollX !== scrollPos.x)) {
            setScrollPos({ x: window.scrollX, y: window.scrollY });
        }
    }

    const reScroll = () => {
        window.scrollTo(scrollPos.x, scrollPos.y);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        (async function Initialize() {
            let allQualifierThresholds = await GetQualifierThresholds()
            setQualifierThresholds(allQualifierThresholds);
            let allPlayersFromAPI = await GetPlayers();
            let allColumns = await columnManager.getAllColumns();
            let defaultRankReportCols = await columnManager.getColumnsByGroup(28);
            let selectedColumns = await columnManager.getColumnsByGroup(groupId > 0 ? groupId : 29);
            //let rankReportGroups = (await columnManager.getAllGroupsBySection('Leaderboards'))
            //    .concat(await columnManager.getAllGroupsBySection('RankReport'));
            //Once Rank Report is pointed to query tool, uncomment out the two lines above this, and remove the line between the two dividers:
            //==================================================================================================================================
            let rankReportGroups = (await columnManager.getAllGroupsBySection('RankReport'));
            //==================================================================================================================================

            setAllPlayers(allPlayersFromAPI);
            setColumns(allColumns);
            setDefRankColumns(defaultRankReportCols);
            setSelectedCols(selectedColumns);
            setGroups(rankReportGroups);
        })()
    }, [])

    useEffect(() => {
        switch (category) {
            case 'batting':
                setGroupId(29);
                break;
            case 'pitching':
                setGroupId(30);
                break;
            case 'relief':
                setGroupId(31);
                break;
            default:
                break;
        }
    }, [category]);

    useEffect(() => {
        if (groupId > 0) {
            if (groups.length < 1) {
                return;
            }
            let tempCols = groups.find(group => group.id === groupId).columns.slice();
            setSelectedCols(tempCols);
        }
    }, [groupId])

    let playerSearchHtml = [];
    for (let i = 0; i <= playerCount; i++) {
        playerSearchHtml.push(
            <div className='row playerSearch'>
                <PlayerSearch
                    key={i}
                    players={allPlayers}
                    label={'Player Search'}
                    onSelect={(playerId, platformSeason, posFlag) => setPlayer(i, playerId)}
                />
            </div>
        );
    }

    const setPlayer = (index, playerId) => {
        let playerIds = selectedPlayers.slice(0);
        playerIds[index] = { playerId: playerId };
        setSelectedPlayers(playerIds);
    }

    const updatePlayers = (i, tempPlayers) => {
        if (i >= playerCount) {
            setSelectedPlayers(tempPlayers);
        }
    }

    const getData = () => {
        let finalColumns = selectedCols.slice();
        defRankColumns.forEach(defRankColumn => {
            if (!~finalColumns.findIndex(finalCol => finalCol.id === defRankColumn.id)) {
                finalColumns.unshift(defRankColumn);
            }
        })
        setSelectedCols(finalColumns);


        let tempPlayers = selectedPlayers.slice(0);
        let percentile = formOptions.rankFormat === 'percentile' ? 1 : 0;
        for (let i = 0; i <= playerCount; i++) {
            if (tempPlayers[i].playerId > 0) {
                switch (category) {
                    case 'batting':
                        GetRankReportsBatters(Number(formOptions.season), Number(formOptions.startSeason), Number(formOptions.endSeason),
                            formOptions.startDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }),
                            formOptions.endDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }),
                            formOptions.mode, -1, percentile, formOptions.filterObj, tempPlayers[i].playerId)
                            .then((value) => {
                                tempPlayers[i].data = value;
                                updatePlayers(i, tempPlayers);
                                //setData(value);
                            });
                        break;
                    case 'pitching':
                    case 'relief':
                        GetRankReportsPitchers(Number(formOptions.season), Number(formOptions.startSeason), Number(formOptions.endSeason),
                            formOptions.startDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }),
                            formOptions.endDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }),
                            formOptions.mode, formOptions.starterSub, percentile, formOptions.filterObj, tempPlayers[i].playerId)
                            .then((value) => {
                                tempPlayers[i].data = value;
                                updatePlayers(i, tempPlayers);
                                //setData(value);
                            });
                        break;
                    case 'fielding':
                        GetRankReportsFielders(Number(formOptions.season), Number(formOptions.startSeason), Number(formOptions.endSeason),
                            formOptions.startDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }),
                            formOptions.endDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }),
                            formOptions.mode, formOptions.starterSub, percentile, formOptions.filterObj, tempPlayers[i].playerId)
                            .then((value) => {
                                tempPlayers[i].data = value;
                                updatePlayers(i, tempPlayers);
                                //setData(value);
                            });
                        break;
                    default:

                        break;
                }
            }
        }
    }

    selectedCols.forEach(col => {
        col.formatter = function (cell, formatterParams, onRendered) {
            let val = cell.getValue();
            if (val === null) {
                return '--';
            }
            if (Number.isInteger(val) && cell.getColumn().getField() !== 'Season') {
                val = val.toString();
                if (val === '0') {
                    return '0';
                }
                else if (val.length > 1 && val.substring(val.length - 2, val.length - 1) == '1') {
                    return val + 'th';
                }
                else {
                    switch (val.substring(val.length - 1)) {
                        case '1':
                            return val + 'st';
                        case '2':
                            return val + 'nd';
                        case '3':
                            return val + 'rd';
                        default:
                            return val + 'th';
                    }
                }
                return val;
            }
            return val;
        };
    });

    let tablesHtml = [];
    for (let i = 0; i < 10; i++) {
        if (selectedPlayers[i].data && selectedPlayers[i].data.length > 0) {
            tablesHtml.push(
                <div className='pdfChunk'>
                    <TabulatorTable
                        cols={selectedCols}
                        data={selectedPlayers[i].data}
                        header={selectedPlayers[i].data[0].Player}
                        initSort={[{ column: 'League', dir: 'asc' }]}
                    />
                </div>
            )
        }
    }

    return (
        <div className='body rank-report'>
            <Navbar bg='light' variant='light' className='leaderboardsNav'>
                <Nav>
                    <Nav.Link className={category === 'batting' ? 'selectedTab' : null} onClick={() => setCategory('batting')}>Batting</Nav.Link>
                    <Nav.Link className={category === 'pitching' ? 'selectedTab' : null} onClick={() => setCategory('pitching')}>Starting Pitcher</Nav.Link>
                    <Nav.Link className={category === 'relief' ? 'selectedTab' : null} onClick={() => setCategory('relief')}>Relief Pitcher</Nav.Link>
                </Nav>
            </Navbar>
            <br />

            {playerSearchHtml}
            <br />
            <Button variant='info' onClick={() => {
                if (playerCount < 9) {
                    setPlayerCount(playerCount + 1);
                }
            }}>Add Player</Button>
            <Button variant='info' onClick={() => {
                if (playerCount > 0) {
                    setPlayerCount(playerCount - 1);
                    let tempPlayers = selectedPlayers.slice(0);
                    tempPlayers[playerCount] = {};
                    setSelectedPlayers(tempPlayers);
                }
            }}>Remove Player</Button>
            <br />
            <br />

            <RankReportForm
                category={category}
                setFormOptions={setFormOptions}
                qualifierThresholds={qualifierThresholds}
            />

            <Button variant='success' onClick={() => getData()}>Submit</Button>
            <br />
            <br />

            {
                selectedPlayers[0].data &&
                <React.Fragment>
                    <ExcelExporter reportName={'RankReport'} players={selectedPlayers} columns={selectedCols} />
                    <PDFExporter scrollPos={scrollPos} columns={selectedCols} fileName={'RankReport'} />
                    <br />
                    <br />
                </React.Fragment>
            }
            <div className='pdfSection' style={{ width: selectedPlayers[0].data ? (selectedCols.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4 : '100%' }} onClick={() => reScroll()}>
                {
                    formOptions.mode === 'date' &&
                    <p className='dateRange'>
                        {formOptions.startDate.getMonth() + 1}/{formOptions.startDate.getDate()}/{formOptions.startDate.getFullYear()}
                        -
                        {formOptions.endDate.getMonth() + 1}/{formOptions.endDate.getDate()}/{formOptions.endDate.getFullYear()}
                    </p>
                }
                {tablesHtml}
            </div>

            <ColumnSelector
                columns={columns}
                selectedColumns={selectedCols}
                groups={groups}
                setColumns={(columns) => {
                    setSelectedCols(columns);
                    //getData();
                }}
            />
            <br />
        </div>
    )
}

export default RankReport;