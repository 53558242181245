import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { GetReliefPitcherReportData } from '../../Services/ApiSvc';
import DatePicker from '../Common/DatePicker';
import ExcelExporter from '../Common/ExcelExporter';
import PDFExporter from '../Common/PDFExporter';
import RadioButton from '../Common/RadioButton';
import StatFilter, { convertFilterToNote, createFilter } from '../Common/StatFilter';
import TabulatorTable from '../Common/TabulatorTable';

function ReliefPitcherReport(props) {
    const [columnGroups, setColumnGroups] = useState(null);
    const [data, setData] = useState({
        exceptionMsg: null,
        loading: false,
        rows: null,
    });
    const [excelNotes, setExcelNotes] = useState(null);

    useEffect(() => {
        if (props.groups.length < 1) {
            return;
        }

        let colGroups = {
            displayStats: [],
            filters: [],
        };
        
        props.groups.find(group => group.id === 65).columns.forEach(column => {
            if (column.formInputs.isISWFilter || column.id === 69)
                colGroups.filters.push(Object.assign({}, column));
            else
                colGroups.displayStats.push(Object.assign({}, column));
        })

        setColumnGroups(colGroups);
    }, [])

    async function getData(formOptions) {
        setData({
            exceptionMsg: null,
            loading: true,
            rows: null,
        });
        updateNotes(formOptions);
        let response = await GetReliefPitcherReportData(formOptions);
        setData({
            exceptionMsg: response.exceptionMsg,
            loading: false,
            rows: response.rows,
        });
    }

    function updateNotes(formOptions) {
        let notes = {
            formNotes: [],
        };

        if (formOptions) {
            notes.formNotes.push(`Player: ${props.player.name}`);
            switch (formOptions.mode) {
                case 'single':
                    notes.formNotes.push(`Season = ${formOptions.season}`);
                    break;
                case 'multiple':
                    notes.formNotes.push(`From: ${formOptions.startSeason}`);
                    notes.formNotes.push(`To: ${formOptions.endSeason}`);
                    break;
                case 'date':
                    notes.formNotes.push(`From: ${formOptions.startDate}`);
                    notes.formNotes.push(`To: ${formOptions.endDate}`);
                    break;
            }
            formOptions.filters.forEach(filter => {
                if (filter.value) {
                    let filterNote = convertFilterToNote(filter);
                    if (filterNote)
                        notes.formNotes.push(filterNote);
                }
            })

            notes.formNotes.push('Display Stat: ' +
                columnGroups.displayStats.find(stat => stat.id === formOptions.displayStatId).formInputs.formLabel
            );
        }

        setExcelNotes(notes);
    }

    let columns = [
        { title: '', field: 'label', width: 200 },
        { title: 'All', field: 'All', width: 50 },
        { title: '1', field: '1', width: 50 },
        { title: '2', field: '2', width: 50 },
        { title: '3', field: '3', width: 50 },
        { title: '4', field: '4', width: 50 },
        { title: '5', field: '5', width: 50 },
        { title: '6', field: '6', width: 50 },
        { title: '7', field: '7', width: 50 },
        { title: '8', field: '8', width: 50 },
        { title: '9', field: '9', width: 50 },
        { title: '10+', field: '10', width: 50 }
    ]

    return(
        <React.Fragment>
            <div className='pdfChunk'>
                <h3>Relief Pitcher Apperance Matrix</h3>
                <ReliefPitcherReportForm
                    columnGroups={columnGroups}
                    player={props.player}
                    submit={getData}
                    exporters={
                        data.rows &&
                        <React.Fragment>
                            <ExcelExporter
                                columns={columns}
                                data={data.rows}
                                notes={excelNotes}
                                reportName={'ReliefPitcher'}
                            />
                            <PDFExporter fileName='ReliefPitcherReport' />
                        </React.Fragment>
                    }
                />
            </div>
            {
                data?.loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
            {
                data?.rows &&
                <div className='pdfChunk'>
                    <Row style={{ width: '850px' }}>
                        <div style={{
                            marginTop: '50px',
                            height: '293px',
                            width: '80px',
                            backgroundColor: '#e6e6e6',
                            borderLeft: '2px solid black',
                            borderTop: '2px solid black',
                            borderBottom: '2px solid black',
                            verticalAlign: 'middle',
                            padding: '10% 5px'
                        }}>
                            Run Diff. at entry
                        </div>
                        <div style={{ width: '754px' }}>
                            <div style={{
                                marginLeft: '200px',
                                textAlign: 'center',
                                backgroundColor: '#e6e6e6',
                                borderLeft: '2px solid black',
                                borderTop: '2px solid black',
                                borderRight: '2px solid black'
                            }}>
                                Inning of Entry
                            </div>
                            <TabulatorTable
                                cols={columns}
                                data={data.rows}
                            />
                        </div>
                    </Row>
                    <br />
                </div>
            }
            {
                data?.exceptionMsg &&
                <React.Fragment>
                    <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center' }}>{data.exceptionMsg}</p>
                    <br />
                    <br />
                </React.Fragment>
            }
        </React.Fragment>
    );
}

export default ReliefPitcherReport;


function ReliefPitcherReportForm(props) {
    let initDate = (new Date(`12/31/${props.player.maxSeason}`)) < new Date()
        ? new Date(`12/31/${props.player.maxSeason}`)
        : new Date();
    const [mode, setMode] = useState('single');
    const [season, setSeason] = useState(props.player.maxSeason.toString());
    const [startSeason, setStartSeason] = useState(props.player.maxSeason.toString());
    const [endSeason, setEndSeason] = useState(props.player.maxSeason.toString());
    const [startDate, setStartDate] = useState(initDate);
    const [endDate, setEndDate] = useState(initDate);
    const [filters, setFilters] = useState([]);
    const [displayStatId, setDisplayStatId] = useState(736) // Pitcher First Entered Game
    

    let seasonOptions = [];
    for (let i = props.player.maxSeason; i >= props.player.minSeason; i--) {
        seasonOptions.push(
            <option key={i} value={i}>{i}</option>
        )
    };

    useEffect(() => {
        let myFilters = [];

        // Currently, we don't allow filtering on inning or run differential
        props.columnGroups?.filters
            .filter(column => ![604, 614].includes(column.id))
            .forEach((column, filterIndex) => {
                let filter = Object.assign({}, createFilter(filterIndex, column));

                myFilters.push(filter);
            });

        setFilters(myFilters);
    }, [props.columnGroups]);

    function setFilter(filterIndex, type, value) {
        let newFilters = filters.slice();
        let filterToUpdate = newFilters.find(filter => filter.index === filterIndex)
        filterToUpdate[type] = value;
        setFilters(newFilters);
    }

    function submitForm() {
        let formOptions = {
            displayStatId: displayStatId,
            endDate: endDate,
            endSeason: endSeason,
            filters: filters,
            mode: mode,
            playerId: props.player.id,
            season: season,
            startDate: startDate,
            startSeason: startSeason,
        };

        props.submit(formOptions);
    }

    return (
        <React.Fragment>
            <div className='row'>
                <b className='label'>Mode: </b>
                <Form>
                    <div key={'custom-inline-radio'}>
                        <Form.Check
                            custom
                            inline
                            name='mode'
                            label='Single Season'
                            type='radio'
                            id='custom-inline-radio-single'
                            defaultChecked
                            onChange={(event) => {
                                if (event.target.value) {
                                    setMode('single')
                                }
                            }}
                        />
                        <Form.Check
                            custom
                            inline
                            name='mode'
                            label='Multiple Seasons'
                            type='radio'
                            id='custom-inline-radio-multiple'
                            onChange={(event) => {
                                if (event.target.value) {
                                    setMode('multiple')
                                }
                            }}
                        />
                        <Form.Check
                            custom
                            inline
                            name='mode'
                            label='Date Range'
                            type='radio'
                            id='custom-inline-radio-date'
                            onChange={(event) => {
                                if (event.target.value) {
                                    setMode('date')
                                }
                            }}
                        />
                    </div>
                </Form>
            </div>
            <br />
            <div className='row'>
                {
                    mode === 'single' &&
                    <React.Fragment>
                        <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Season: </Form.Label>
                        <Form.Control style={{ width: '100px' }} as='select'
                            defaultValue={season} onChange={(event) => setSeason(event.target.value)}>
                            {seasonOptions}
                        </Form.Control>
                    </React.Fragment>
                }
                {
                    mode === 'multiple' &&
                    <React.Fragment>
                        <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Start Season: </Form.Label>
                        <Form.Control style={{ width: '100px' }} as='select'
                            defaultValue={startSeason} onChange={(event) => setStartSeason(event.target.value)}>
                            {seasonOptions}
                        </Form.Control>
                        <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>End Season: </Form.Label>
                        <Form.Control style={{ width: '100px' }} as='select'
                            defaultValue={endSeason} onChange={(event) => setEndSeason(event.target.value)}>
                            {seasonOptions}
                        </Form.Control>
                    </React.Fragment>
                }
                {
                    mode === 'date' &&
                    <React.Fragment>
                        <b className='label' style={{ paddingRight: '25px' }}>From: </b>
                        <DatePicker
                            maxDate={endDate}
                            minDate={new Date(`1/1/${props.player.minSeason}`)}
                            setDate={(date) => setStartDate(date)}
                            startDate={startDate}
                        />
                        <b classname='label' style={{ padding: '0px 25px' }}>To: </b>
                        <DatePicker
                            endDate={endDate}
                            maxDate={new Date(`12/31/${props.player.maxSeason}`)}
                            minDate={startDate}
                            setDate={(date) => setEndDate(date)}
                        />
                    </React.Fragment>
                }
            </div>
            <br />
            <h5>Advanced Filters</h5>
            <div className='advanced-filter'>
                {
                    filters.map(filter =>
                        <StatFilter
                            filter={filter}
                            setFilter={setFilter}
                        />
                    )
                }
            </div>
            <br />
            <Row>
                <Col xs={12} lg={1}>
                    <b>Display Stat</b>
                </Col>
                {
                    props.columnGroups?.displayStats.map((displayStat, index) => {
                        return <Col xs={6} lg={2}>
                            <RadioButton
                                checked={displayStat.id === displayStatId}
                                disabled={false}
                                key={index}
                                name='displayStat'
                                label={displayStat.formInputs.formLabel}
                                onClick={() => setDisplayStatId(displayStat.id)}
                            />
                        </Col>
                    })
                }
            </Row>
            <br />
            <Row className='pdfIgnore'>
                <Button variant='success'
                    onClick={submitForm}
                >
                    Submit
                </Button>
                <div style={{ margin: '0px 0px 0px auto' }}>
                    {props.exporters}
                </div>
            </Row>
        </React.Fragment>
    )
}