import React, { useState, useEffect } from 'react';
import { GetPlayerGlobalInfo } from '../../Services/ApiSvc';
let moment = require('moment');

function PlayerBio(props) {
    const [player, setPlayer] = useState(null);

    useEffect(() => {
        if (props.playerId > 0) {
            GetPlayerGlobalInfo(props.playerId)
                .then((value) => {
                    setPlayer(value);
                });
        }
    }, [props.playerId]);

    return (
        <React.Fragment>
            {
                player &&
                <div className='row playerBio pdfChunk'>
                    {/*
                    <div className='col-md-2'>
                        <img src={'./images/PlayerPic.png'} />
                    </div>
                    */}
                    <div className='col-md-12'>

                        <div className='row'>
                            <div className='col-md-4'>
                                <h4>{player.player}</h4>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <p>Current MLS: {player.mls}</p>
                                <p>Roster Status: {player.playerStatus}</p>
                                <p>Reserve Status: {player.reserve}</p>
                                <p>Contract: {player.playerContract}</p>
                            </div>
                            <div className='col-md-6'>
                                <p>Born: {moment(player.dob).format('MMMM D, YYYY')} ({player.age})</p>
                                <p>School: {player.college}</p>
                                <p>Bats: {player.bats} Throws: {player.throws}</p>
                                <p>{player.height}, {player.weight}</p>
                            </div>
                            {/*
                            <div className='col-md-4'>
                                <p>Agent: {player.agent}</p>
                                <p>Options Remaining: {player.options}</p>
                                <p>4th Option Eligible: {player.optElig}</p>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default PlayerBio;