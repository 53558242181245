//import config from 'config';

export const userService = {
    login,
    logout,
    getAll,
    validateUserGroup,
};



function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    let response = fetch(`/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                user.authdata = window.btoa(username + ':' + password);
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
    return response;
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    let loginUrl = `${process.env.REACT_APP_BASE_URL}/login`;
    if (window.location.href !== loginUrl) {
        window.location.replace(loginUrl);
    }
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users`, requestOptions).then(handleResponse);
}

async function validateUserGroup(token, groupName) {
    let fetchOptions = {
        headers: new Headers(),
    }
    fetchOptions.headers.append('X-MLBPA-AUTH', 'QU5EUk9JRC1VU0VSOkFORFJPSUTigJNQQVNTV09SRA==');
    fetchOptions.headers.append('DeviceId', '12aa3ab7-e0b9-4783-b144-46575a100da8');
    fetchOptions.headers.append('Authorization', `Bearer ${token}`);
    fetchOptions.headers.append('Access-Control-Allow-Origin', 'https://api.mlbpaplayers.org');

    try {
        let res = await fetch('https://api.mlbpaplayers.org/v16/api/Account/getazureuserdetail_wix', fetchOptions);
        let json = await res.json();

        return json.responseData?.groupNames?.count < 1
            && json.responseData.groupNames.includes(groupName);
    } catch (ex) {
        console.error(ex);
    }
    return false;
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function authHeader() {
    // return authorization header with basic auth credentials
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.authdata) {
        return { 'Authorization': 'Basic ' + user.authdata };
    } else {
        return {};
    }
}

function getUser() {
    //return {
    //    username: 's_biscon',
    //    authdata: 'c19iaXNjb246SyRqPWZQeDkyUA=='
    //} // Comment when publishing

    let userCookie = JSON.parse(localStorage.getItem('user'));
    let authData = window.atob(userCookie.authdata);
    let username = authData.substring(0, authData.indexOf(':'));
    if (userCookie && userCookie.username === username) {
        return userCookie;
    }
    else {
        logout();
    }
}

export function getReports(pageName) {
    const user = getUser();
    const jsonBody = JSON.stringify({ pageName });
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Basic ' + user.authdata, 'Content-Type': 'application/json' },
        body: jsonBody
    }

    let response = fetch('/users/getreports', requestOptions)
        .then(handleResponse)
        .then((data) => {
            const reports = data.data.map((r) => {
                return {
                    reportName: r.reportName,
                    reportQuery: r.reportQuery,
                    dateSaved: r.dateSaved
                }
            });
            return reports;
        });
    return response;
}

export function saveReport(pageName, reportName, reportQuery) {
    const user = getUser();
    const username = user.username;
    const jsonBody = JSON.stringify({ username, pageName, reportName, reportQuery });
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Basic ' + user.authdata, 'Content-Type': 'application/json' },
        body: jsonBody
    };

    let response = fetch('/users/savereport', requestOptions) //?user=' + username + '&reportName=' + reportName
        .then(handleResponse)
        .then(success => {
            if (success) {
                return 'success';
            }
        })
        .catch(message => {
            return Promise.reject(message);
            //alert('There was a problem. Error message: "' + rejection + '".\nAn error has been logged and the IT staff has been notified.');
        });
    return response;
}

export function deleteReport(pageName, reportName) {
    const user = getUser();
    const username = user.username;
    const jsonBody = JSON.stringify({ username, pageName, reportName });
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Basic ' + user.authdata, 'Content-Type': 'application/json' },
        body: jsonBody
    };

    let response = fetch('/users/deletereport', requestOptions) //?user=' + username + '&reportName=' + reportName
        .then(handleResponse)
        .then(success => {
            if (success) {
                return 'success';
            }
        })
        .catch(message => {
            return Promise.reject(message);
            //alert('There was a problem. Error message: "' + rejection + '".\nAn error has been logged and the IT staff has been notified.');
        });
    return response;
}