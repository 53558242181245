import React from 'react';
import { Dropdown, Form, Col, Row, Button } from 'react-bootstrap';

function MultiSelect(props) {
    function setItems(e) {
        let itemVal = e.currentTarget.value;
        let index = props.selectedItems.findIndex(item => item === itemVal);
        let items = props.selectedItems.slice();

        // Remove items not in the list since the list can change.
        let removeItems = [];
        items.forEach(item => {
            let listItemIndex = props.list.findIndex(listItem => listItem.value === item)
            if (!~listItemIndex) {
                removeItems.unshift(listItemIndex);
            }
        })
        removeItems.forEach(itemIndex => items.splice(itemIndex, 1));

        if (e.currentTarget.checked && !~index) {
            items.push(itemVal);
            props.setSelectedItems(items);
        }
        else if (!e.currentTarget.checked && !!~index) {
            items.splice(index, 1);
            props.setSelectedItems(items);
        }
    }

    function selectAllItems() {
        props.setSelectedItems(props.list.map(item => item.value));
    }

    function deselectAllItems() {
        props.setSelectedItems([]);
    }

    let hasSelectedItems = props.selectedItems?.length > 0

    return (
        <Dropdown className='multiSelect'>
            <Dropdown.Toggle
                variant='secondary'
                style={{ border: hasSelectedItems ? '' : 'solid 2px #dc3545' }}
            >
                Select {props.label}(s)
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Button variant="outline-primary" onClick={() => selectAllItems()}>Select All</Button>
                <Button variant="outline-primary" onClick={() => deselectAllItems()}>Deselect All</Button>
                {
                    props.list.map(item => <Form.Check
                            checked={props.selectedItems.includes(item.value)}
                            type='checkbox'
                            key={item.value}
                            onChange={(e) => setItems(e)}
                            label={item.label}
                            value={item.value}
                        />
                    )
                }
            </Dropdown.Menu>
            {
                !hasSelectedItems &&
                <span style={{ color: '#dc3545' }}>
                    Please select at least one {props.label}
                </span>
            }
            {
                hasSelectedItems &&
                <span style={{ color: '#006600' }}>
                    {selectionList(props.list, props.selectedItems)}
                </span>
            }
        </Dropdown>
    )
}

function selectionList(fullList, selectedValues) {
    let selectedLabels = [];
    fullList.map(item => {
        if (selectedValues.includes(item.value)) {
            selectedLabels.push(item.label);
        }
    })
    let selectedLabelsString = selectedLabels.toString().replace(/,/g, ", ");
    return (
        <React.Fragment>
            {selectedLabelsString}
        </React.Fragment>
    );
}

export default MultiSelect;