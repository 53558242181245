import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import PlayerSearch from '../Common/PlayerSearch';
import { GetPlayers } from '../../Services/ApiSvc';

function AwardsForm(props) {
    let curSeason = new Date().getMonth() < 3 ? new Date().getFullYear() - 1 : new Date().getFullYear();
    const [startSeason, setStartSeason] = useState(curSeason.toString());
    const [endSeason, setEndSeason] = useState(curSeason.toString());
    const [allPlayers, setAllPlayers] = useState([]);
    const [playerId, setPlayerId] = useState(0);
    const [player, setPlayer] = useState({})
    const [award, setAward] = useState('0');
    const [pos, setPos] = useState('0');

    useEffect(() => {
        (async function Initialize() {
            let allPlayersFromAPI = await GetPlayers();
            setAllPlayers(allPlayersFromAPI);
        })()
    }, []);

    useEffect(() => {
        if (playerId > 0) {
            let player = allPlayers.find((p) => p.id === playerId)
            setPlayer({
                playerId: playerId,
                platformSeason: player.maxSeason,
                posFlag: player.posFlag
            })
        }
    }, [playerId]);

    useEffect(() => {
        // Initialize options on rank report page
        let formOptions = {
            startSeason: startSeason,
            endSeason: endSeason,
            award: award,
            pos: pos,
            player: player,
        }
        props.setFormOptions(formOptions)
    }, [])

    useEffect(() => {
        let formOptions = {
            startSeason: startSeason,
            endSeason: endSeason,
            award: award,
            pos: pos,
            player: player,
        }
        props.setFormOptions(formOptions)
    }, [
        startSeason,
        endSeason,
        award,
        pos,
        player,
    ])

    let seasonOptions = []
    for (let i = curSeason; i >= 2002; i--) {
        seasonOptions.push(
            <option key={i} value={i}>{i}</option>
        )
    }

    return (
        <React.Fragment>
            <div className='row'>
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Start Season: </Form.Label>
                <Form.Control style={{ width: '100px' }} as='select'
                    defaultValue={startSeason} onChange={(event) => setStartSeason(event.target.value)}>{seasonOptions}
                </Form.Control>
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>End Season: </Form.Label>
                <Form.Control style={{ width: '100px' }} as='select'
                    defaultValue={endSeason} onChange={(event) => setEndSeason(event.target.value)}>{seasonOptions}
                </Form.Control>
            </div>
            <br />
            <div className='row'>
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Player: </Form.Label>
                    <PlayerSearch
                        key={0}
                        players={allPlayers}
                        onSelect={(playerId, platformSeason, posFlag) => setPlayerId(playerId)}
                    />
            </div>
            <br />
            <div className='row'>
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Award: </Form.Label>
                <Form.Control style={{ width: '250px' }} as='select' defaultValue={award} onChange={(event) => setAward(event.target.value)}>
                    <option key='0' value='0'>All</option>
                    <option key='1' value='1'>MVP</option>
                    <option key='2' value='2'>Cy Young</option>
                    <option key='3' value='3'>Gold Glove</option>
                    <option key='4' value='4'>Silver Slugger</option>
                    <option key='5' value='5'>ROY</option>
                    <option key='6' value='6'>LCS MVP</option>
                    <option key='7' value='7'>WS MVP</option>
                    <option key='8' value='8'>AS MVP</option>
                    <option key='9' value='9'>CPOY</option>
                    <option key='12' value='12'>SNPOY</option>
                    <option key='15' value='15'>RRM</option>
                    <option key='18' value='18'>AS</option>
                    <option key='19' value='19'>ASE</option>
                    <option key='20' value='20'>FOY</option>
                    <option key='21' value='21'>Hoffman/Rivera</option>
                    <option key='22' value='22'>All-MLB TM</option>
                    <option key='30' value='30'>FBA</option>
                </Form.Control>
            </div>
            <br />

            {
                award && (award.includes(3) || award.includes(4) || award.includes(19) || award.includes(22) || award.includes(30)) &&
                <React.Fragment>
                        <div className='row'>
                            <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Position: </Form.Label>
                            <Form.Control style={{ width: '250px' }} as='select' defaultValue={pos} onChange={(event) => setPos(event.target.value)}>
                                <option key='0' value='0'>All</option>
                                <option key='1' value='1'>P</option>
                                <option key='2' value='2'>C</option>
                                <option key='3' value='3'>1B</option>
                                <option key='4' value='4'>2B</option>
                                <option key='5' value='5'>3B</option>
                                <option key='6' value='6'>SS</option>
                                <option key='7' value='7'>LF</option>
                                <option key='8' value='8'>CF</option>
                                <option key='9' value='9'>RF</option>
                                <option key='10' value='10'>OF</option>
                            </Form.Control>
                        </div>
                </React.Fragment>
            }
            <br />
        </React.Fragment>
    )
}

export default AwardsForm