import React, { useEffect } from 'react';
import { render } from 'react-dom';
import { Form, Badge, Row, Col } from 'react-bootstrap';
import { dropElement, DropBlock, DraggableFilter, LogOp } from './DragAndDrop';
import AutoComplete from '../Common/AutoComplete';
import { customDateString, getValueLabelFromFormInputs } from '../Common/CommonFunctions';
import OperatorDropdown from '../Common/OperatorDropdown';
import MultiSelect from '../Common/MultiSelect';

function StatSelectionForm(props) {
    function getStatNameFromColumnsById(id) {
        let tempCol = props.getStatInfoFromColumnById(id);
        return tempCol
            ? tempCol.title
            : '';
    }

    function getFormInputsFromColumnsById(id) {
        let tempCol = props.getStatInfoFromColumnById(id);
        return tempCol
            ? tempCol.formInputs
            : {};
    }

    function getPosTypeFromColumnsById(id) {
        let tempCol = props.getStatInfoFromColumnById(id);
        return tempCol
            ? tempCol.posType
            : {};
    }

    /**
     * returns an array containing all indices of a string(searchString) in another string(stringToSearch).
     * @param {any} searchString
     * @param {any} stringToSearch
     */
    function getIndicesOfString(searchString, stringToSearch) {
        let curIndex = -1;
        let indices = [];
        do {
            curIndex = stringToSearch.indexOf(searchString, curIndex + 1);
            if (!!~curIndex) {
                indices.push(curIndex);
            }
        } while (!!~curIndex)
        return indices;
    }

    /**
     * Matches the index of an opening parenthesis with it's closing parenthesis counterpart. Returns an object
     * with the openIndex property, closeIndex property and nestedParens array which holds and identical object
     * if there were nested parenthesis between the open and close index.
     * @param {any} filterString
     */
    function matchParens(filterString) {
        let openParenIndices = getIndicesOfString('(', filterString);
        let closeParenIndices = getIndicesOfString(')', filterString);

        let matchingParens = [];
        let matchedOpenParenIndices = [];
        closeParenIndices.forEach((closeParenIndex, loopIndex) => {
            let matchingParen = Math.max(...(
                openParenIndices.filter(openParenIndex => {
                    return openParenIndex < closeParenIndex
                        && !matchedOpenParenIndices.includes(openParenIndex)
                })
            ));

            matchedOpenParenIndices.push(matchingParen);
            matchingParens.push({
                openIndex: matchingParen,
                closeIndex: closeParenIndex,
                nestedParens: [],
            });
        });

        matchingParens.sort((a, b) => a.openIndex - b.openIndex)

        for (let i = matchingParens.length - 1; i > 0; i--) {
            for (let j = i - 1; j > -1; j--) {
                if (matchingParens[i].openIndex < matchingParens[j].closeIndex) {
                    matchingParens[j].nestedParens.push(matchingParens[i]);
                    matchingParens.splice(i, 1);
                }
            }
        }

        return matchingParens;
    }

    /**
     * Based on the open/close parenthesis index, pulls out the useful information of the filter in the filterString.
     * The returned object will have filters and nested filters to be used when generating the html for individual filters.
     * @param {any} parenIndices
     * @param {any} filterString
     */
    function splitFilters(parenIndices, filterString) {
        let curFilters = {
            filters: [],
            nestedFilters: [],
        };

        if (parenIndices.nestedParens.length > 0) {
            parenIndices.nestedParens.forEach(nestedParens => {
                curFilters.nestedFilters.push(splitFilters(nestedParens, filterString));
            })
        }

        let filter = filterString.slice(parenIndices.openIndex + 1, parenIndices.closeIndex)
        if (['&', '|'].includes(filterString.charAt(parenIndices.openIndex - 1))) {
            filter = filterString.charAt(parenIndices.openIndex - 1) + filter;
        }
        let nestedFilterRegex = /(([&\|]\(|(\()).+\))/;

        while (nestedFilterRegex.test(filter)) {
            filter = filter.replace(nestedFilterRegex, '');
        }

        filter = filter
            .replaceAll('&', ';AND;')
            .replaceAll('|', ';OR;')
            .trim();

        filter = filter.charAt(0) === ';' ? filter.substring(1) : filter;

        curFilters.filters.push(filter);

        return curFilters;
    }

    /**
     * Generates the html to be used when appending filters into each dropzone.
     * @param {any} filters
     * @param {any} filterType Either IGW or ISW. Used for styling and to provide uniqueness.
     * @param {any} statIndex Clarifies what stat in the list of stats the filters are associated with.
     * @param {any} blockDepthIndex
     */
    function generateFilterHtml(filters, filterType, statIndex, blockDepthIndex) {
        let returnHtml = [];

        let filterEles = [];
        let blockId = ''
        blockDepthIndex.forEach(blockIndex => {
            blockId += `-block-${blockIndex}`;
        })
        let filterId = 0;

        filters.filters.forEach((filter, filtersIndex) => {
            filter.split(';').forEach((text, textIndex) => {
                if (filtersIndex === 0 && textIndex === 0 && ['AND', 'OR'].includes(text)) {
                    returnHtml.push(
                        <LogOp
                            operator={text}
                            id={`${filterType}-dropZone-${statIndex}${blockId}-logOp`}
                        />
                    )
                } else if (['AND', 'OR'].includes(text)) {
                    filterEles.push(
                        <LogOp
                            operator={text}
                            id={`${filterType}-criteria-${statIndex}${blockId}-filter-${++filterId}-logOp`}
                        />
                    )
                } else if (['ISW', 'IGW'].includes(filterType)) {
                    let filterRegex = /(?<statId>[^>=< \n]+)(?<operator><>|>=|>|=|<=|<)(?<value>[^>=< \n]+)/;
                    let regex = filterRegex.exec(text);
                    let statId = regex.groups.statId;
                    let operator = regex.groups.operator;
                    let value = regex.groups.value;
                    let stat = getStatNameFromColumnsById(statId);
                    let valueLabel = getValueLabelFromFormInputs(
                        props.getStatInfoFromColumnById(statId).formInputs,
                        value
                    );
                    let granularity = getFormInputsFromColumnsById(statId).granularity;
                    filterEles.push(
                        <DraggableFilter
                            className={filterType}
                            statId={statId}
                            filterText={stat + operator + valueLabel}
                            filter={statId + operator + value}
                            id={`${filterType}-criteria-${statIndex}${blockId}-filter-${filterId}`}
                            granularity={granularity}
                        />
                    )
                } else {
                    filterEles.push(
                        <DraggableFilter
                            className={filterType}
                            filterText={text}
                            id={`${filterType}-criteria-${statIndex}${blockId}-filter-${filterId}`}
                        />
                    )
                }
            })
        })

        if (filters.nestedFilters.length > 0) {
            blockDepthIndex.push(0);
            filters.nestedFilters.forEach(nestedFilter => {
                filterEles.push(generateFilterHtml(nestedFilter, filterType, statIndex, blockDepthIndex));
            })
        }

        returnHtml.push(
            <DropBlock
                id={`${filterType}-dropZone-${statIndex}${blockId}`}
                className={filterType}
            >
                {filterEles}
            </DropBlock>
        );

        return returnHtml;
    }

    /**
     * Converts the string representation of filters into the html to be used when appending filters to dropzones.
     * @param {any} filterString
     * @param {any} filterType
     * @param {any} index
     */
    function loadFilters(filterString, filterType, index) {
        let dropZones = [];

        if (!filterString) return dropZones;
        let matchingParens = matchParens(filterString);

        let filters = []
        matchingParens.forEach(matchingParen => {
            filters.push(splitFilters(matchingParen, filterString));
        })

        filters.forEach((filter, blockIndex) => {
            dropZones.push(generateFilterHtml(filter, filterType, index, [blockIndex]));
        });

        return dropZones;
    }

    function movePokeTrainer(e, direction) {
        let pokeTrainer = document.getElementById('pokeTrainer');
        if (pokeTrainer) pokeTrainer.remove();
        let location = e.currentTarget.getBoundingClientRect();
        let ele = document.createDocumentFragment();
        render(
            <PokeTrainer
                left={location.left}
                top={location.top}
                direction={direction}
            />,
            ele
        )
        document.getElementsByTagName('Body')[0].prepend(ele);
    }

    function PokeTrainer(props) {
        function removePokeTrainer() {
            clearInterval(moveInterval);
            let pokeTrainer = document.getElementById('pokeTrainer');
            if (pokeTrainer) pokeTrainer.remove();
        }

        function movePokeTrainer() {
            let pokeTrainer = document.getElementById('pokeTrainer');
            pokeTrainer.style.top = props.direction === 'up'
                ? parseFloat(pokeTrainer.style.top) - 5 + 'px'
                : parseFloat(pokeTrainer.style.top) + 5 + 'px';

            switch (pokeTrainer.className) {
                case 'front':
                    pokeTrainer.className = 'right';
                    pokeTrainer.src = './images/PokemonTrainerRightSideTransparent.png';
                    break;
                case 'right':
                    pokeTrainer.className = 'back';
                    pokeTrainer.src = './images/PokemonTrainerBackTransparent.png';
                    break;
                case 'back':
                    pokeTrainer.className = 'left';
                    pokeTrainer.src = './images/PokemonTrainerLeftSideTransparent.png';
                    break;
                case 'left':
                    pokeTrainer.className = 'front';
                    pokeTrainer.src = './images/PokemonTrainerFrontTransparent.png';
                    break;
            }
        }

        let moveInterval = setInterval(movePokeTrainer, 150, );
        setTimeout(removePokeTrainer, 5000);

        return (
            <img
                id='pokeTrainer'
                className='front'
                src='./images/PokemonTrainerFrontTransparent.png'
                style={{ position: 'fixed', left: `${props.left}px`, top: `${props.top}px`, zIndex: '999' }}
            />
        )
    }

    let displayTimePeriod = !props.timePeriodNotSelectable
        || (props.section === 'Criteria' && ['throughX', 'dateRange'].includes(props.tabName));

    let curStat = props.curStat;
    let index = props.index;

    let statValue = curStat?.stat
        ? getStatNameFromColumnsById(curStat.stat)
        : '';
    let statFormInputs = curStat?.stat
        ? getFormInputsFromColumnsById(curStat.stat)
        : {};
    let timePeriods = curStat?.stat && props.getTimePeriodsFromFormInputs
        ? props.getTimePeriodsFromFormInputs(statFormInputs)
        : [];
    let posType = curStat?.stat
        ? getPosTypeFromColumnsById(curStat.stat)
        : '';
    let statGranularity = curStat?.stat
        ? statFormInputs?.granularity
        : '';
    let allowableFilters = curStat?.stat
        ? statFormInputs?.uniqueFilters
        : '';

    let styling = props.section !== 'Filter' && index % 2 === 1 ? { backgroundColor: '#e6e6e6' } : {};

    let sectionId = props.section === 'Stat' ? 'DisStat' : props.section === 'Criteria' ? 'NumCrit' : '';

    let igwFilters = props.section !== 'Filter' && curStat?.igwFilter ? loadFilters(curStat.igwFilter, 'IGW', curStat.index) : '';
    let iswFilters = props.section !== 'Filter' && curStat?.iswFilter ? loadFilters(curStat.iswFilter, 'ISW', curStat.index) : '';

    let hasFilters = props.section !== 'Filter' && (
        document.getElementById(sectionId)?.getElementsByClassName(`ISW dropZone-${index}`)[0]?.getElementsByTagName('span').length > 0 ||
        document.getElementById(sectionId)?.getElementsByClassName(`IGW dropZone-${index}`)[0]?.getElementsByTagName('span').length > 0
    )

    let formInputs = getFormInputsFromColumnsById(curStat.stat);

    return (
        <div key={curStat.index + 1} className='stat-filter'>
            <Row key={index + 2 + ''} style={styling}>
                <Col xs={1} className='d-lg-none'>{props.section !== 'Filter' ? 'Stat: ' : 'Split: '}</Col>
                <Col xs={9} lg={3} xl={2}>
                    <AutoComplete
                        value={statValue}
                        items={props.selectableStatsByGroup}
                        groups={props.colGroups}
                        onSelect={
                            (colKey) => {
                                props.setStat(curStat.index, 'stat', colKey)
//                                if (formInputs.inputType === 'DateRange') {
//                                    props.setStat(curStat.index, 'operator', '=')
//                                    props.setStat(curStat.index, 'timePeriod', '')
//                                }
                            }
                        }
                        dropUp={true}
                        sortItemsAlphabetical={true}
                        addOn={posType}
                        placeholder={props.placeholder}
                    />
                </Col>
                {
                    ['Player Criteria', 'Criteria', 'Filter'].includes(props.section) &&
                    <NumCritInputs
                        setStat={props.setStat}
                        statFormInputs={statFormInputs}
                        curStat={curStat}
                    />
                }
                {
                    curStat && curStat.stat && displayTimePeriod &&
                    <React.Fragment>
                        <Col xs={3} className='d-lg-none'>Time Period: </Col>
                        <Col xs={9} lg={3} xl={2}>
                            {
                                !props.timePeriodNotSelectable && timePeriods.length > 0 && // formInputs.inputType === 'DateRange' &&
                                <Form.Control as='select' onChange={(event) => props.setStat(curStat.index, 'timePeriod', event.target.value)}>
                                    {
                                        timePeriods.map((timePeriod, mapIndex) => {
                                            return (
                                                <option
                                                    selected={curStat?.timePeriod === timePeriod ? 'selected' : false}
                                                    key={mapIndex}
                                                    value={timePeriod}
                                                >
                                                    {timePeriod}
                                                </option>
                                            )
                                        })
                                    }
                                </Form.Control>
                            }
                            {
                                props.tabName === 'throughX' &&
                                <React.Fragment>Career Through X</React.Fragment>
                            }
                            {
                                props.tabName === 'dateRange' &&
                                <React.Fragment>In Date Range</React.Fragment>
                            }
                        </Col>
                    </React.Fragment>
                }
                {
                    props.section !== 'Filter' &&
                    <Col xs={1}>
                        <Badge
                            style={{ cursor: 'pointer' }}
                            tabIndex='0'
                            variant='danger'
                            onClick={() => props.removeStat(index)}
                        >
                            X
                    </Badge>
                    </Col>
                }
                {
                    props.section === 'Stat' &&
                    <Col xs={1}>
                        {
                            index > 0 &&
                            <Badge
                                tabIndex='0'
                                variant='light'
                                type='button'
                                onClick={(e) => {
                                    props.reorderStat(index, 'up');
                                    if (e.ctrlKey && e.shiftKey) {
                                        movePokeTrainer(e, 'up');
                                    }
                                }}
                            >
                                <i className='arrow-up' />
                            </Badge>
                        }
                        {
                            index < props.stats.length - 1 &&
                            <Badge
                                tabIndex='0'
                                variant='light'
                                type='button'
                                onClick={(e) => {
                                    props.reorderStat(index, 'down');
                                    if (e.ctrlKey && e.shiftKey) {
                                        movePokeTrainer(e, 'down');
                                    }
                                }}
                            >
                                <i className='arrow-down' />
                            </Badge>
                        }
                    </Col>
                }
            </Row>
            {
                curStat && curStat.stat &&
                <Row
                    style={styling}
                    hidden={props.hideFilters && !hasFilters}
                >
                    {
                        ((props.section === 'Criteria' && statFormInputs.numCritISWFilterable) ||
                            (props.section === 'Stat' && statFormInputs.disStatISWFilterable)) &&
                        <React.Fragment>
                            <Col style={{ paddingLeft: '60px' }} xs={3} lg={1}>
                                Where
                                </Col>
                            <Col xs={9} lg={4}>
                                <div
                                    id={`${sectionId}-ISW-dropZone-${index}`}
                                    className={`ISW dropZone dropZone-${index}`}
                                    granularity={statGranularity}
                                    allowableFilters={allowableFilters}
                                    onDragOver={(ev) => {
                                        ev.preventDefault();
                                        ev.dataTransfer.dropEffect = 'move';
                                    }}
                                    onDrop={(ev) => dropElement(ev)}
                                >
                                    {iswFilters}
                                </div>
                            </Col>
                        </React.Fragment>
                    }
                    {
                        ((props.section === 'Criteria' && statFormInputs.numCritIGWFilterable) ||
                            (props.section === 'Stat' && statFormInputs.disStatIGWFilterable)) &&
                        <React.Fragment>
                            <Col style={{ paddingLeft: '60px' }} xs={3} lg={1}>
                                In Games Where
                                </Col>
                            <Col xs={9} lg={4}>
                                <div
                                    id={`${sectionId}-IGW-dropZone-${index}`}
                                    className={`IGW dropZone dropZone-${index}`}
                                    granularity={statGranularity}
                                    allowableFilters={allowableFilters}
                                    onDragOver={(ev) => {
                                        ev.preventDefault();
                                        ev.dataTransfer.dropEffect = 'move';
                                    }}
                                    onDrop={(ev) => dropElement(ev)}
                                >
                                    {igwFilters}
                                </div>
                            </Col>
                        </React.Fragment>
                    }
                </Row>
            }
        </div>
    )
}

export default StatSelectionForm;


function ConditionalPrefix(props) {
    if (props.statFormInputs.asCurrency) {
        return '$';
    } else {
        return (
            <React.Fragment>&nbsp;&nbsp;</React.Fragment>
        )
    }
}

function HandleCurrency(event, props) {                             // event handler "wrapper" for currency input
    let value = event.target.value
    value = value.replace(/\D/g, '')                                // remove any non-numeric characters
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')             // insert thousands separators
    event.target.value = value
    props.setStat(props.curStat.index, 'value', event.target.value) // intended event handler
}

function NumCritInputs(props) {
    let operatorHtml = []
    if (props.curStat && props.curStat.stat && props.statFormInputs.inputType !== 'DateRange' && ['Number', 'Date'].includes(props.statFormInputs.inputType)) {
        operatorHtml.push(
            <OperatorDropdown
                curOperator={props.curStat?.operator}
                curStatIndex={props.curStat.index}
                setOperator={props.setStat}
            />
        )
    } else {
        operatorHtml.push(
            <Col xs={3} lg={2} />
        )
    }

    return (
        <React.Fragment>
            {operatorHtml}
            {
                props.curStat && props.curStat.stat && props.statFormInputs.inputType === 'Number' && props.statFormInputs.asCurrency === false &&
                <React.Fragment>
                    <Col xs={2} className='d-lg-none'>Value: </Col>
                    <ConditionalPrefix statFormInputs={props.statFormInputs} />
                    <Col xs={4} lg={2}>
                        <Form.Control
                            as='input'
                            type='number'
                            step='1'
                            value={props.curStat ? props.curStat.value : 0}
                            onChange={(event) => props.setStat(props.curStat.index, 'value', event.target.value)}
                            />
                    </Col>
                </React.Fragment>
            }
            {
                props.curStat && props.curStat.stat && props.statFormInputs.asCurrency === true &&
                <React.Fragment>
                    <Col xs={2} className='d-lg-none'>Value: </Col>
                    <ConditionalPrefix statFormInputs={props.statFormInputs} />
                    <Col xs={4} lg={2}>
                        <Form.Control
                            as='input'
                            type='text'
                            step='1'
                            value={props.curStat ? props.curStat.value : 0}
                            onChange={(event) => HandleCurrency(event, props)}
                        />
                    </Col>
                </React.Fragment>
            }
            {
                props.curStat && props.curStat.stat && props.statFormInputs.inputType === 'DateRange' &&
                <React.Fragment>
                    <Col xs={2} className='d-lg-none'>Value: </Col>
                    <ConditionalPrefix statFormInputs={props.statFormInputs} />
                    <Col xs={4} lg={2}>
                        <span style={{display: 'inline-block', width: '50px'}}>From:</span>
                        <input
                            value={props.curStat.value.start}
                            type='date'
                            onChange={(e) => {
                                if (e.target.value) {
                                    props.setStat(props.curStat.index, 'value', { start: e.target.value, end: e.target.value })
                                }
                            }}
                        />
                        <br />
                        <span style={{ display: 'inline-block', width: '50px' }}>To:</span>
                        <input
                            value={props.curStat.value.end}
                            type='date'
                            onChange={(e) => {
                                if (e.target.value) {
                                    props.setStat(props.curStat.index, 'value', { start: props.curStat.value.start, end: e.target.value })
                                }
                            }}
                        />
                    </Col>
                </React.Fragment>
            }
            {
                props.curStat && props.curStat.stat && props.statFormInputs.inputType === 'Date' && props.curStat.stat && props.statFormInputs.inputType !== 'DateRange' &&
                <React.Fragment>
                    <Col xs={2} className='d-lg-none'>Value: </Col>
                    <ConditionalPrefix statFormInputs={props.statFormInputs} />
                    <Col xs={4} lg={2}>
                        <input
                            value={props.curStat && props.curStat.value
                                ? customDateString(new Date(props.curStat.value + 'T00:00:00'))
                                : customDateString(new Date())
                            }
                            type='date'
                            onChange={(e) => {
                                if (e.target.value) {
                                    props.setStat(props.curStat.index, 'value', e.target.value)
                                }
                            }}
                        />
                    </Col>
                </React.Fragment>
            }
            {
                props.curStat && props.curStat.stat && props.statFormInputs.inputType === 'DateNoYear' &&
                <React.Fragment>
                    <Col xs={2} className='d-lg-none'>Value: </Col>
                    <ConditionalPrefix statFormInputs={props.statFormInputs} />
                    <Col xs={4} lg={2}>
                        <input
                            value={props.curStat && props.curStat.value
                                ? customDateString(new Date(`${(new Date()).getFullYear()}-${props.curStat.value}T00:00:00`))
                                : customDateString(new Date())
                            }
                            type='date'
                            onChange={(e) => {
                                if (e.target.value) {
                                    let date = e.target.value;

                                    props.setStat(props.curStat.index, 'value', date.substring(date.indexOf('-') + 1));
                                }
                            }}
                        />
                    </Col>
                </React.Fragment>
            }
            {
                props.curStat && props.curStat.stat && props.statFormInputs.inputType === 'Select' &&
                <React.Fragment>
                    <Col xs={2} className='d-lg-none'>Value: </Col>
                    <ConditionalPrefix statFormInputs={props.statFormInputs} />
                    <Col xs={4} lg={2}>
                        <Form.Control
                            as='select'
                            onChange={(event) => props.setStat(props.curStat.index, 'value', event.target.value)}
                        >
                            {
                                props.statFormInputs.selectOptions.map((curSelOp, curIndex) =>
                                    <option
                                        selected={props.curStat?.value === curSelOp.value ? 'selected' : false}
                                        key={curIndex}
                                        value={curSelOp.value}
                                    >
                                        {curSelOp.label}
                                    </option>
                                )
                            }
                        </Form.Control>
                    </Col>
                </React.Fragment>
            }
            {
                props.curStat && props.curStat.stat && props.statFormInputs.inputType === 'MultiSelect' &&
                <React.Fragment>
                    <Col xs={2} className='d-lg-none'>Value: </Col>
                    <ConditionalPrefix statFormInputs={props.statFormInputs} />
                    <Col xs={4} lg={2}>
                        <MultiSelect
                            list={props.statFormInputs.selectOptions}
                            label={'value'}
                            selectedItems={props.curStat?.value?.split(',') || []}
                            setSelectedItems={(selectedValues) => {
                                props.setStat(props.curStat.index, 'value', selectedValues.length > 0 ? selectedValues.join(',') : null)
                            }}
                        />
                    </Col>
                </React.Fragment>
            }
        </React.Fragment>
    )
}