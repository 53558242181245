import React, { useState, useEffect } from 'react';
import { columnManager } from '../Common/ColumnManager';
import { GetCompReportPitcherData, GetCompReportBatterData } from '../../Services/ApiSvc';
import TabulatorTable from '../Common/TabulatorTable';
import ExcelExporter from '../Common/ExcelExporter';

function PlayerStats(props) {
    const [standardCols, setStandardCols] = useState([]);
    const [statcastCols, setStatcastCols] = useState([]);
    const [player, setPlayer] = useState(null);
    const [scrollPos, setScrollPos] = useState({ x: 0, y: 0 });

    const handleScroll = () => {
        if (window.scrollY > -1 && window.scrollX > -1 && (window.scrollY !== scrollPos.y || window.scrollX !== scrollPos.x)) {
            setScrollPos({ x: window.scrollX, y: window.scrollY });
        }
    }

    const reScroll = () => {
        window.scrollTo(scrollPos.x, scrollPos.y);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [])

    useEffect(() => {
        setPlayer(null);
        let tempPlayer = {};
        if (props.player.id > 0) {
            if (props.player.posFlag === 'B' || (props.player.id === 19755)) {
                (async function Initialize() {
                    let standardColumns = await columnManager.getColumnsByGroup(1);
                    let statcastColumns = await columnManager.getColumnsByGroup(5);

                    setStandardCols(standardColumns);
                    setStatcastCols(statcastColumns);

                    let batterData = await GetCompReportBatterData(props.player.id, props.player.maxSeason);
                    tempPlayer.data = batterData.filter((data) => {
                        return data.Pos === 0;
                    });
                    updatePlayer(tempPlayer);
                })();

            }
            else if (props.player.posFlag === 'P') {
                (async function Initialize() {
                    let standardColumns = await columnManager.getColumnsByGroup(3);
                    let statcastColumns = await columnManager.getColumnsByGroup(6);

                    setStandardCols(standardColumns);
                    setStatcastCols(statcastColumns);

                    let pitcherData = await GetCompReportPitcherData(props.player.id, props.player.maxSeason);
                    tempPlayer.data = pitcherData.filter((data) => {
                        return data.Starter === -1;
                    });
                    updatePlayer(tempPlayer);
                })();
            }
        }
    }, [props.player])

    function updatePlayer(playerData) {
        let platData = { platform: [], platformMinusOne: [], platformMinusTwo: [] };
        let totalData = { twoYear: [], threeYear: [], platform: [] };

        if (playerData.data && playerData.data.length > 0) {
            for (var j = playerData.data.length - 1; j >= 0; j--) {
                switch (playerData.data[j].TotalCategory) {
                    case '2Year':
                        totalData.twoYear.push(playerData.data[j]);
                        playerData.data.splice(j, 1);
                        break;
                    case '3Year':
                        totalData.threeYear.push(playerData.data[j]);
                        playerData.data.splice(j, 1);
                        break;
                    case 'platform':
                        totalData.platform.push(playerData.data[j]);
                        playerData.data.splice(j, 1);
                        break;
                    default:
                        break;
                }
            }
        }
        playerData.totalsData = totalData;

        let platSeason = 0;
        playerData.data.forEach(season => {
            if (season.Season > platSeason && season.Season <= props.player.maxSeason) {
                platSeason = season.Season;
            }
        });
        playerData.platformIndex = playerData.data.findIndex(d => {
            return d.Season === platSeason;
        });

        platData.platform.push(playerData.data[playerData.platformIndex])
        if (playerData.platformIndex > 0) {
            platData.platformMinusOne.push(playerData.data[playerData.platformIndex - 1]);
        }
        if (playerData.platformIndex > 1) {
            platData.platformMinusTwo.push(playerData.data[playerData.platformIndex - 2]);
        }
        playerData.platformData = platData;
        setPlayer(playerData);
    }

    return (
        <div className='player-stats'>
            {
                player && player.data &&
                <React.Fragment>

                </React.Fragment>
            }
            {
                player && player.data && player.data.length > 0 &&
                <div className='pdfSection'>
                    <ExcelExporter
                        reportName={'PlayerStats'}
                        players={[player]}
                        data={player.data}
                        columns={standardCols}
                    />
                    <span style={{ backgroundColor: '#FED8B1' }}>Click + sign on 2020 and Career rows to show 2020 statistics on a 162 game basis</span>
                    <div style={{ width: player.data ? (standardCols.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4 : '100%' }} onClick={() => reScroll()}>
                        <TabulatorTable
                            cols={standardCols}
                            data={player.data}
                            header={player.data[0].Player}
                            initSort={[{ column: 'Season', dir: 'asc' }]}
                            dataTreeElementColumn='Team'
                        />
                    </div>
                    <ExcelExporter
                        reportName={'PlayerStats'}
                        players={[player]}
                        data={player.data}
                        columns={statcastCols}
                    />
                    <div style={{ width: player.data ? (statcastCols.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4 : '100%' }} onClick={() => reScroll()}>
                        <TabulatorTable
                            cols={statcastCols}
                            data={player.data}
                            header={player.data[0].Player}
                            initSort={[{ column: 'Season', dir: 'asc' }]}
                            dataTreeElementColumn='Team'
                        />
                    </div>
                </div>

            }
            <br />
        </div>
    );
}

export default PlayerStats;