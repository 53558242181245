import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Row, Col, Button, Form, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import { FilterCreation, dropElement } from './DragAndDrop';
import { columnManager } from '../Common/ColumnManager';
import PlayerCriteria from './PlayerCriteria';
import NumericalCriteria from './NumericalCriteria';
import DisplayStats from './DisplayStats';
import ExcelExporter from '../Common/ExcelExporter';
import PDFExporter from '../Common/PDFExporter';
import { GetQueryToolPlayerData, GetQueryToolContractsData, GetPlayers } from '../../Services/ApiSvc';
import TabulatorTable from '../Common/TabulatorTable';
import MultiSelect from '../Common/MultiSelect';
import IntRangeDropdown from '../Common/IntRangeDropdown';
import SeasonDropDown from '../Common/SeasonDropDown';
import SaveLoadButtons from '../Common/SaveLoadButtons';
import PlayerSearch from '../Common/PlayerSearch';
import { customDateString, getValueLabelFromFormInputs } from '../Common/CommonFunctions';
import useStateWithCallbackLazy from '../../Hooks/useStateWithCallbackLazy';

function QueryTool() {
    const [allColumns, setAllColumns] = useState([]);
    const [allPlayers, setAllPlayers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [defaultGroups, setDefaultGroups] = useState([]);
    const [selectableStatsByGroup, setSelectableStatsByGroup] = useState([]);
    const [selectableStatGroups, setSelectableStatGroups] = useState([]);

    // Form inputs from tab unique section
    const [header, setHeader] = useState('');
    const [tabName, setTabName] = useState('season');
    const [consecutiveYears, setConsecutiveYears] = useState(0);
    const [consMinSeason, setConsMinSeason] = useState(2002);
    const [consMaxSeason, setConsMaxSeason] = useState((new Date()).getFullYear());
    const [throughX, setThroughX] = useState({ category: 'Any' });
    const [dateRanges, setDateRanges] = useState([{ startDate: new Date(2002, 1, 1), endDate: new Date() }]);
    const [playerSeasons, setPlayerSeasons] = useState([{ playerId: 0, platformSeason: 0 }]);
    const [timePeriodNotSelectable, setTimePeriodNotSelectable] = useState(false);

    //const [playerType, setPlayerType] = useState('Hitter');
    const [hideFilters, setHideFilters] = useState(true);

    // Form inputs
    const [selectedSeasons, setSelectedSeasons] = useState([]);
    const [playerCriteria, setPlayerCriteria] = useState([]);
    const [numericalCriteria, setNumericalCriteria] = useStateWithCallbackLazy([]);
    const [displayStats, setDisplayStats] = useStateWithCallbackLazy([]);
    const [scaleUpFilters, setScaleUpFilters] = useState(false);
    const [scaleUpDisplayStats, setScaleUpDisplayStats] = useState(0);
    const [displayScaleUpOptions, setDisplayScaleUpOptions] = useState(false);
    const [usingScaleUp, setUsingScaleUp] = useState(false);
    const [cols, setCols] = useState([]);
    const [notes, setNotes] = useState({
        timePeriodNotes: [],
        playerSeasonNotes: [],
        playerCriteriaNotes: [],
        numericalCriteriaNotes: [],
        displayStatNotes: [],
        consecutiveYearsNote: '',
        throughXStatNotes: '',
        scaleUpNotes: ''
    });

    // data handling
    const [data, setData] = useState([]);
    const [dataRowCount, setDataRowCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {

        (async () => {
            function adjustStatIds(statId) {
                let retStatId = statId > 10000 ? statId - 10000 : statId;
                // 73 and 74 are "next year" stats which are pointless in QueryTool because of time periods.
                return retStatId === 73
                    ? 730
                    : retStatId === 74
                        ? 731
                        : retStatId;
            }

            let allColumnsFromAPI = await columnManager.getAllColumns();
            let colGroupsFromAPI = await columnManager.getAllGroupsBySection('QueryTool');
            let defaultGroupsFromAPI = [];
            defaultGroupsFromAPI.push((await columnManager.getColIdsFromGroup(1))
                .map(adjustStatIds));
            defaultGroupsFromAPI.push((await columnManager.getColIdsFromGroup(2))
                .map(adjustStatIds));
            defaultGroupsFromAPI.push((await columnManager.getColIdsFromGroup(3))
                .map(adjustStatIds));
            defaultGroupsFromAPI.push((await columnManager.getColIdsFromGroup(4))
                .map(adjustStatIds));
            defaultGroupsFromAPI.push((await columnManager.getColIdsFromGroup(48))
                .map(adjustStatIds));
            let allPlayersFromAPI = await GetPlayers();

            setAllColumns(allColumnsFromAPI);
            setGroups(colGroupsFromAPI);
            setDefaultGroups(defaultGroupsFromAPI);
            setAllPlayers(allPlayersFromAPI);
        })();
        
    }, [])

    useEffect(() => {
        if (groups.length < 1) {
            return;
        }
        let columnsForAutoComplete = groups.map(group => {
            return group.columns.map(col => {
                let posType;
                switch (col.statPosType) {
                    case 'b':
                        posType = 'Batting';
                        break;
                    case 'p':
                        posType = 'Pitching';
                        break;
                    case 'f':
                        posType = 'Fielding';
                        break;
                    default:
                        posType = '';
                        break;
                }
                return {
                    key: col.id,
                    title: col.title,
                    groupId: group.id,
                    formInputs: col.formInputs,
                    posType: posType,
                };
            })
        })
        setSelectableStatGroups(groups);
        setSelectableStatsByGroup(columnsForAutoComplete);
    }, [groups])

    function getStatInfoFromColumnById(id) {
        return selectableStatsByGroup.flat().find(c => c.key == id);
    }

    useEffect(() => {
        if (consMaxSeason - consMinSeason + 1 < consecutiveYears) {
            setConsecutiveYears(consMaxSeason - consMinSeason + 1 + '');
        }
    }, [consMinSeason, consMaxSeason])

    function reset() {
        setConsMinSeason(2002);
        setConsMaxSeason((new Date()).getFullYear());
        setDateRanges([{ startDate: new Date(2002, 1, 1), endDate: new Date() }]);
        setPlayerSeasons([{ playerId: 0, platformSeason: 0 }]);
        setSelectedSeasons([]);
        setPlayerCriteria([]);
        setNumericalCriteria([]);
        setCols([]);
        setNotes({
            timePeriodNotes: [],
            playerSeasonNotes: [],
            playerCriteriaNotes: [],
            numericalCriteriaNotes: [],
            displayStatNotes: [],
            consecutiveYearsNote: '',
            throughXStatNotes: '',
            scaleUpNotes: ''
        });
        setData([]);

        if (['throughX', 'dateRange'].includes(tabName)) {
            setDisplayStats(createDefaultStatList([1]));
        }
        else if (['contracts'].includes(tabName)) {
            setDisplayStats(createDefaultStatList([1, 554, 555, 733, 734]));
        }
        else {
            setDisplayStats(createDefaultStatList([1, 2]));
        }

        let reportButtons = document.getElementById('reportForm').getElementsByClassName('custom-control-input');
        for (let i = 0; i < reportButtons.length; i++) {
            if (reportButtons[i].checked) {
                reportButtons[i].checked = false;
            }
        }
        let rbn = document.getElementById('custom-inline-radio-custom');
        rbn.click();
    }

    function createDefaultStatList(statIds) {
        return statIds.map((statId, index) => {
            let statObj = {
                stat: statIds[index],
                index: index,
                timePeriod: ['throughX', 'dateRange', 'contracts'].includes(tabName) ? 'Career To Date' : 'Platform Year'
            }
            return statObj;
        });
    }

    function convertCriteriaToNote(criteria) {
        let statInfo = getStatInfoFromColumnById(criteria.stat);
        let criteriaValues = [];
        if (statInfo.formInputs.asCurrency === true) {
            criteriaValues.push('$' + criteria.value)
        } else if (statInfo.formInputs.inputType === 'DateRange') {
            criteria.operator = ''
            criteria.note = null
            criteriaValues.push('From: ' + criteria.value.start + ' To: ' + criteria.value.end)
        } else {
            criteria.value.split(',').forEach(critVal => {
                criteriaValues.push(statInfo?.formInputs?.selectOptions?.length > 0
                    ? statInfo.formInputs.selectOptions.find(selOpt => selOpt.value === critVal).label
                    : criteria.value);
            })
        }
        let noteObj = criteria.note;
        if (['dateRange'].includes(tabName)) {
            noteObj = noteObj.replace('Career To Date', 'Date Range')
        }
        return `${statInfo?.title} ${criteria.operator} ${criteriaValues.join(',')} ${noteObj ? 'in ' + noteObj : ''}`;
    }

    function getNotesFromForm(params) {
        let noteObj = {};
        if (['season', 'consecutive', 'throughX', 'dateRange'].includes(tabName)) {
            if (tabName === 'consecutive') {
                noteObj.timePeriodNotes = [];
                for (let i = consMinSeason; i <= consMaxSeason; i++) {
                    noteObj.timePeriodNotes.push(i);
                }
            } else if (tabName === 'dateRange') {
                noteObj.timePeriodNotes = dateRanges.map(dateRange => {
                    return new Intl.DateTimeFormat('en-US').format(dateRange.startDate) + '-' + new Intl.DateTimeFormat('en-US').format(dateRange.endDate);
                });
            } else {
                noteObj.timePeriodNotes = selectedSeasons;
            }
            noteObj.playerCriteriaNotes = playerCriteria.map(convertCriteriaToNote);
        }
        if (['players'].includes(tabName)) {
            noteObj.playerSeasonNotes = playerSeasons.map(playerSeason => {
                return `${allPlayers.find(player => player.id === playerSeason.playerId)?.name} 
                    in platform season: ${playerSeason.platformSeason}`
            });
        } else {
            noteObj.numericalCriteriaNotes = params.numCritNotes;
        }
        if (['consecutive', 'throughX'].includes(tabName)) {
            noteObj.consecutiveYearsNote = tabName === 'consecutive'
                ? `In ${consecutiveYears} consecutive years`
                : '';

            let throughXCategory = throughX.category;
            let throughXSubCategory = throughX.value?.split('-')[0];
            let throughXValue = throughX.value?.split('-')[1];
            let throughXCategoryNote = throughXValue ? throughXSubCategory : throughXCategory;
            let throughXValueNote = throughXSubCategory === 'MLS' ? throughXValue.substring(0, 1) + '+' : throughXValue;
            noteObj.throughXStatNote = 'Through ';
            if (throughX.category === 'Any') {
                noteObj.throughXStatNote += 'any season';
            } else if (throughX.category === 'Stat') {
                noteObj.throughXStatNote += `the players first ${throughXValue} ${throughXCategoryNote}`;
            } else {
                noteObj.throughXStatNote += `the players ${throughXCategoryNote} ${throughXValueNote} season`;
            }
        }
        let noteArr = params.displayStatNotes;
        if (['dateRange'].includes(tabName)) {
            noteArr = noteArr.map(function (item, index) {
                return item.replace('Career To Date', 'Date Range')
            });
        }
        noteObj.displayStatNotes = noteArr;
        setUsingScaleUp(scaleUpFilters || scaleUpDisplayStats);
        noteObj.scaleUpNotes = `Query filters based on ${scaleUpFilters ? '' : 'not '}scaled up data. ` +
            `Results display ${scaleUpDisplayStats ? '' : 'not '}scaled up data${scaleUpDisplayStats === 2 ? ' nested under not scaled up data' : ''}.`;

        return noteObj;
    }

    function getData() {
        setLoading(true);
        setErrorMsg(null);
        setData([]);
        setDataRowCount(0);
        let start = new Date();

        let params = transformInputsToParams();
        setCols(params.columns);
        setNotes(getNotesFromForm(params));

        (async () => {
            console.log(`Starting call to get data at: ${Date.now() - start}`);
            try {
                let res;
                if (tabName === 'contracts') {
                    res = await GetQueryToolContractsData(params.numericalCriteria, params.displayStats);
                } else {
                    res = await GetQueryToolPlayerData(params.seasons, params.playerCriteria, params.numericalCriteria, params.displayStats,
                        params.consecutiveYears, params.throughX, params.dateRanges, params.playerSeasons,
                        params.scaleUpFilters, params.scaleUpDisplayStats);
                }
                console.log(`finished getting data at: ${Date.now() - start}`);

                if (res.exceptionMsg) {
                    setErrorMsg(res.exceptionMsg);
                } else if (res.data.length === 0) {
                    setErrorMsg('No Data');
                } else {
                    setErrorMsg('');
                    params.columns.forEach(column => {
                        column.field = res.columns.find(resCol => resCol.displayName === column.field).displayNumber.toString();
                    })
                    let rowNumCol = await columnManager.getSingleColumnById(10000); // get row number column config
                    params.columns.unshift(rowNumCol);  // insert row# column as first column
                    setCols(params.columns);

                    setData(res.data);
                    setDataRowCount(res.data.length);
                }
                
                setLoading(false);
                
                console.log(`finished setting data at: ${Date.now() - start}`);
            } catch (err) {
                setErrorMsg('Something went wrong getting the data. Check to make sure that you have all filters selected and try again. If you continue to get this message please contact support.')
                setLoading(false);
            }
        })();
    }

    /**
     * Parses the json from the API and sets the front end based on the loaded report.
     * @param {any} reportJson The report loaded as json from the API.
     */
    function loadReportFromJson(reportJson) {
        let report = JSON.parse(reportJson);
        setTabName(report.tabName ?? 'season');

        function isArbCategory(category) {
            return ['MLS', 'Arb'].includes(category);
        }

        let throughXCategory = report.throughX
            ? isArbCategory(report.throughX.split('-')[0])
                ? 'Arb'
                : report.throughX.split('-')[0] === 'Age'
                    ? 'Age'
                    : 'Stat'
            : 'Any';

        switch (report.tabName) {
            case 'consecutive':
                setConsMinSeason(Math.min(...report.seasons));
                setConsMaxSeason(Math.max(...report.seasons));
                setConsecutiveYears(report.consecutiveYears);
                setThroughX({ category: throughXCategory, value: report.throughX })
                break;
            case 'throughX':
                setSelectedSeasons(report.seasons);
                setThroughX({ category: throughXCategory, value: report.throughX })
                break;
            case 'dateRange':
                let tempDateRanges = [];
                report.dateRange.split('&&').forEach(dateRange => {
                    tempDateRanges.push({
                        startDate: new Date(dateRange.split('-')[0]),
                        endDate: new Date(dateRange.split('-')[1]),
                    });
                })
                setDateRanges(tempDateRanges);
                break;
            default:
                setSelectedSeasons(report.seasons);
                break;
        }

        setPlayerCriteria(report.playerCriteria);
        setNumericalCriteria(report.numericalCriteria);
        setDisplayStats(report.displayStats);
        setScaleUpFilters(report.scaleUpFilters);
        setScaleUpDisplayStats(report.scaleUpDisplayStats);
    }

    /**
     * Uses transformInputsToObj to get an object containing the inputs from the front end,
     * then modifies the object to be used for saving.
     */
    function transformInputsToReportQuery() {
        let reportQueryObj = transformInputsToObj();

        reportQueryObj.tabName = tabName;

        reportQueryObj.displayStats = reportQueryObj.displayStats.disStats
            .map(curDisStat => {
                curDisStat.iswFilter = curDisStat.iswFilter?.replaceAll('ISW ', '');
                curDisStat.igwFilter = curDisStat.igwFilter?.replaceAll('IGW ', '');
                return curDisStat;
            });
        return reportQueryObj;
    }

    function reformatDateStringNumCrit(dateStr) {
        if (!dateStr) {
            return ''
        }

        let parts = []
        parts = dateStr.split('-')
        return parts[1] + '/' + parts[2] + '/' + parts[0]
    }

    /**
     * Uses transformInputsToObj to get an object containing the inputs from the front end,
     * then modifies the object to be used making the API call.
     */
    function transformInputsToParams() {
        let paramObj = transformInputsToObj();

        paramObj.seasons = paramObj.seasons?.join(',');
        paramObj.playerCriteria = paramObj.playerCriteria
            .map(playerCrit => playerCrit.stat + playerCrit.operator + playerCrit.value)
            .join('&&');
        paramObj.numCritNotes = paramObj.numericalCriteria.map(convertCriteriaToNote);
        paramObj.numericalCriteria = paramObj.numericalCriteria
            .map(curNumCrit => {
                let param = ''
                let statInfo = getStatInfoFromColumnById(curNumCrit.stat);
                if (statInfo.formInputs.inputType === 'DateRange') {
                    param =
                        curNumCrit.stat + '=1 in ' +
                        reformatDateStringNumCrit(curNumCrit.value.start) + '-' +
                        reformatDateStringNumCrit(curNumCrit.value.end);
                } else if (statInfo.formInputs.asCurrency === true) {
                    param = curNumCrit.stat + curNumCrit.operator + curNumCrit.value.replaceAll(',', '').replaceAll('$', '');    // replace method removes non-numerics, specifically commas from currency;
                } else {
                    param = curNumCrit.stat + curNumCrit.operator + curNumCrit.value;
                }
                if (tabName !== 'contracts') {
                    param += curNumCrit.timePeriod ? ` in ${curNumCrit.timePeriod}` : '';
                    param += curNumCrit.iswFilter ? ` ${curNumCrit.iswFilter}` : '';
                    param += curNumCrit.igwFilter ? ` ${curNumCrit.igwFilter}` : '';
                }
                return param;
            })
            .join('&&');
        paramObj.displayStatNotes = paramObj.displayStats.notes;
        paramObj.columns = paramObj.displayStats.columns;
        paramObj.displayStats = paramObj.displayStats.paramInputs
            .filter(disStat => !disStat.includes('null'))
            .join('&&');

        return paramObj;
    }

    /**
     * Generates an object that holds all inputs from the form to be used for saving or passing to the API. 
     */
    function transformInputsToObj() {
        let inputObj = {};

        if (tabName === 'consecutive') {
            let consSeasonsArr = [];
            for (let i = consMinSeason; i <= consMaxSeason; i++) {
                consSeasonsArr.push(i + '');
            }
            inputObj.seasons = consSeasonsArr;
            inputObj.consecutiveYears = Number(consecutiveYears);
        } else if (tabName === 'dateRange') {
            inputObj.dateRanges = dateRanges
                .map(dateRange => {
                    return dateRange.startDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' })
                        + '-'
                        + dateRange.endDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' });
                })
                .join('&&');
        } else if (tabName === 'players') {
            inputObj.playerSeasons = playerSeasons
                .map(playerSeason => {
                    return playerSeason.playerId + '-' + playerSeason.platformSeason;
                })
                .join('&&');
        } else {
            inputObj.seasons = selectedSeasons;
        }

        if (['consecutive', 'throughX'].includes(tabName)) {

            inputObj.throughX = throughX.value;
        }

        inputObj.playerCriteria = playerCriteria
            .filter(crit => crit.stat !== '' && crit.stat != null);
        inputObj.numericalCriteria = createNumCritInputArr();
        inputObj.displayStats = createDisStatInputObj();
        inputObj.scaleUpFilters = scaleUpFilters;
        inputObj.scaleUpDisplayStats = scaleUpDisplayStats;

        return inputObj;
    }

    /**
     * Generates an array of numerical criteria objects that hold general information about each numerical criteria row
     */
    function createNumCritInputArr() {
        let numCritInputArr = [];

        let numCritDropZones = document.getElementById('NumCrit')?.getElementsByClassName('dropZone');
        if (numCritDropZones) {
            let numCritFilters = Array.prototype.filter.call(
                numCritDropZones,
                (dropZone) => !dropZone.className.includes('block') && !dropZone.className.includes('all')
            );

            numericalCriteria.forEach((curNumCrit, index) => {
                if (curNumCrit.stat === '' || curNumCrit.stat == null) {
                    return;
                }
                let numCritObj = Object.assign({}, curNumCrit);

                let statInfo = getStatInfoFromColumnById(numCritObj.stat);
                if (statInfo.formInputs.inputType === 'DateRange') {
                    numCritObj.operator = '='
                    numCritObj.timePeriod = ''
                }

                let iswFilters = numCritFilters.find(numCritFilter =>
                    numCritFilter.classList.contains('ISW')
                    && numCritFilter.classList.contains('dropZone-' + index))

                let igwFilters = numCritFilters.find(numCritFilter =>
                    numCritFilter.classList.contains('IGW')
                    && numCritFilter.classList.contains('dropZone-' + index))

                numCritObj.iswFilter = parseFiltersFromHtml(iswFilters, 'Id');
                numCritObj.igwFilter = parseFiltersFromHtml(igwFilters, 'Id');

                let filterNote = (parseFiltersFromHtml(iswFilters, 'Title')
                    + ' ' + parseFiltersFromHtml(igwFilters, 'Title')).trim();

                let curNote = tabName !== 'contracts' ? curNumCrit.timePeriod ?? '' : '';

                if (filterNote) {
                    curNote += ` ${filterNote
                        .replaceAll('&', ' AND ')
                        .replaceAll('|', ' OR ')
                        .replaceAll('IGW ', 'in games where ')
                        .replaceAll('ISW ', 'where ')}`;
                }
                numCritObj.note = curNote.trim();

                numCritInputArr.push(numCritObj);
            });
        }
        return numCritInputArr;
    }

    /**
     * Generates an object that holds information based on the display stats section.
     * Object properties include:
     * disStats: Array of objects for each display stat row
     * paramInputs: Array of parameters that can be joined to make up the full parameter of display stats
     * notes: Array of notes to be displayed describing each type of stat
     * columns: Array of tabulator columns to be used in tabulator for displaying the stats
     */
    function createDisStatInputObj() {
        let disStatDropZones = document.getElementById('DisStat').getElementsByClassName('dropZone');
        let disStatFilters = Array.prototype.filter.call(
            disStatDropZones,
            (dropZone) => !dropZone.className.includes('block') && !dropZone.className.includes('all')
        );

        let noFilterStats = [1, 2];
        let notes = [];
        let paramInputs = [];
        let columns = [];
        let disStats = [];
        displayStats.forEach((curDisStat, index) => {
            if (curDisStat.stat === '' || curDisStat.stat == null) {
                return;
            }
            let column = Object.assign({}, allColumns.find(col => col.id === curDisStat.stat));
            let disStatObj = Object.assign({}, curDisStat);
            let paramInput = curDisStat.stat + '';
            let curNote = '';

            // Display stats code commented out as business rules not finilized but Bob wants to keep code for possiblr future use
            //let statInfo = getStatInfoFromColumnById(curDisStat.stat);
            //if (statInfo.formInputs.inputType === 'DateRange') {
            //    curDisStat.timePeriod = 'From: ' + curDisStat.timePeriod.start + 'To: ' + curDisStat.timePeriod.end
            //}

            if (!noFilterStats.includes(curDisStat.Stat)) {
                let iswFilters = disStatFilters.find(disStatFilter =>
                    disStatFilter.classList.contains('ISW')
                    && disStatFilter.classList.contains('dropZone-' + index))

                let igwFilters = disStatFilters.find(disStatFilter =>
                    disStatFilter.classList.contains('IGW')
                    && disStatFilter.classList.contains('dropZone-' + index))

                disStatObj.iswFilter = parseFiltersFromHtml(iswFilters, 'Id');
                disStatObj.igwFilter = parseFiltersFromHtml(igwFilters, 'Id');
                let filterNote = (parseFiltersFromHtml(iswFilters, 'Title')
                    + ' ' + parseFiltersFromHtml(igwFilters, 'Title')).trim();
                let filterField = (parseFiltersFromHtml(iswFilters, 'Field')
                    + ' ' + parseFiltersFromHtml(igwFilters, 'Field')).trim();

                if (curDisStat.timePeriod && tabName !== 'contracts') {
                    if (tabName === 'dateRange') {
                        curNote = 'Date Range';
                    }
                    else {
                        curNote = curDisStat.timePeriod;
                    }
                    paramInput += ` in ${curDisStat.timePeriod}`;
                    column.field = `${curDisStat.timePeriod} ${column.field}`;
                }

                switch (column.statPosType) {
                    case 'b':
                        column.field += ' as Batter';
                        break;
                    case 'p':
                        column.field += ' as Pitcher';
                        break;
                    case 'f':
                        column.field += ' as Fielder';
                        break;
                    default:
                        column.field += '';
                        break;
                }

                if (filterNote) {
                    paramInput += ` ${disStatObj.iswFilter} ${disStatObj.igwFilter}`
                    curNote += ` ${filterNote
                        .replaceAll('&', ' AND ')
                        .replaceAll('|', ' OR ')
                        .replaceAll('IGW ', 'in games where ')
                        .replaceAll('ISW ', 'where ')}`;
                    column.field += ` ${filterField
                        .replaceAll('&', ' and ')
                        .replaceAll('|', ' or ')
                        .replaceAll('IGW ', 'in games where ')
                        .replaceAll('ISW ', 'where ')}`;
                }

                paramInput = paramInput.trim();
                curNote = curNote.trim();
                column.field = column.field.trim();
            }

            if (column.statPosType) {
                let subScript = document.createElement('sub');
                subScript.innerHTML = column.statPosType;
                column.title += subScript.outerHTML;
            }

            paramInputs.push(paramInput);
            if (curNote) {
                let noteIndex = notes.findIndex(note => note === curNote);
                column.titleFormatter = function (cell, formatterParams, onRendered) {
                    cell.getElement().style.whiteSpace = "pre-wrap";
                    return cell.getValue();
                };
                let superScript = document.createElement('sup');
                if (!~noteIndex) {
                    superScript.innerHTML = notes.length + 1;
                    notes.push(curNote);
                } else {
                    superScript.innerHTML = noteIndex + 1;
                }
                column.title += superScript.outerHTML;
                column.headerTooltip = curNote;
            }
            columns.push(column);
            disStats.push(disStatObj);
        });

        return {
            disStats: disStats,
            paramInputs: paramInputs,
            notes: notes,
            columns: columns,
        };
    }

    /**
     * Creates an array of block objects that contain nested blocks and filter text arrays.
     * @param {any} blocks
     * @param {any} span
     * @param {any} filterType
     */
    function createOrNestFilter(blocks, span, filterType, toStatProp) {
        let spanId = span.id
            .replaceAll('-logOp', '')
            .replace(/-filter-[0-9]*/, '')
            .replace(/criteria-[0-9]*-/, '')
            .replace(/dropZone-[0-9]*-/, '');
        let spanText = span.innerText
            .replaceAll('\nX', '')
        if (spanText === 'AND') spanText = '&';
        if (spanText === 'OR') spanText = '|';
        let spanStatId = span.attributes.statId?.value;
        let spanFilterAttribute = span.attributes.filter?.value;

        let retFilterIndex = blocks.findIndex(curFilter => curFilter.label === spanId);
        if (!!~retFilterIndex) {
            blocks[retFilterIndex].filterText.push(
                ['&', '|'].includes(spanText)
                    ? spanText
                    : filterTextConversion(spanFilterAttribute, toStatProp, spanStatId)
            );
            return;
        }

        retFilterIndex = blocks.findIndex(curFilter => !spanId.indexOf(curFilter.label));
        if (!!~retFilterIndex) {
            createOrNestFilter(blocks[retFilterIndex].nestedBlocks, span, filterType, toStatProp);
            return;
        }

        blocks.push({
            filterType: filterType,
            label: spanId,
            filterText: [
                ['&', '|'].includes(spanText)
                    ? spanText
                    : filterTextConversion(spanFilterAttribute, toStatProp, spanStatId)
            ],
            nestedBlocks: [],
        });
        return;
    }

    /**
     * Converts the stat of the filter based on toStatProp.
     * Returns the new string of the filter.
     * @param {any} filterText
     * @param {any} toStatProp
     */
    function filterTextConversion(filterText, toStatProp, statId) {
        if (['|', '&'].includes(filterText)) {
            return filterText;
        }
        let filterRegex = /(?<stat>[0-9]+|[A-z]+)(?<operator><>|>=|>|=|<=|<)(?<value>[^>= \n]+)/;
        let regex = filterRegex.exec(filterText);
        let stat = regex.groups.stat;
        let operator = regex.groups.operator;
        let value = regex.groups.value;

        let statColumn = allColumns.find(col => col.id == statId);
        if (statColumn === null) {
            return filterText;
        }

        let statProp;
        let valueProp;
        switch (toStatProp) {
            case 'Id':
                statProp = statColumn.id;
                valueProp = value
                break;
            case 'Field':
                statProp = statColumn.field;
                valueProp = getValueLabelFromFormInputs(
                    statColumn.formInputs,
                    value
                );
                break;
            case 'Title':
                statProp = statColumn.title;
                valueProp = getValueLabelFromFormInputs(
                    statColumn.formInputs,
                    value
                );
                break;
            default:
                statProp = stat;
                valueProp = value
                break;
        }

        return (statProp ?? stat)
            + operator
            + (valueProp ?? value);
    }

    /**
     * Appends to the accumulatedFilterText the filters from the filterBlock.
     * Returns the accumulatedFilterText.
     * @param {any} accumulatedFilterText
     * @param {any} filterBlock
     */
    function createFilterText(accumulatedFilterText, filterBlock) {
        let curFilterBlockText = filterBlock.filterText.slice(0);
        if (['|', '&'].includes(curFilterBlockText[0])) {
            accumulatedFilterText += curFilterBlockText[0];
            curFilterBlockText = curFilterBlockText.slice(1);
            return accumulatedFilterText;
        }

        accumulatedFilterText += `(${curFilterBlockText.join('')}`;
        filterBlock.nestedBlocks.forEach(nestedBlock => {
            accumulatedFilterText = createFilterText(accumulatedFilterText, nestedBlock);
        });
        accumulatedFilterText += ')';
        return accumulatedFilterText;
    }

    /**
     * Parses the filterHtml and returns a string representation of the filters based on the statProp.
     * @param {any} filterHtml
     * @param {any} statProp
     */
    function parseFiltersFromHtml(filterHtml, statProp) {
        if (!filterHtml) return '';
        let filterBlocks = [];
        let spans = filterHtml.getElementsByTagName('span');
        let filterType = spans.length === 0
            ? ''
            : spans[0].classList.contains('ISW')
                ? 'ISW'
                : 'IGW';

        Array.prototype.forEach.call(
            spans,
            (span) => createOrNestFilter(
                filterBlocks,
                span,
                span.classList.contains('ISW')
                    ? 'ISW'
                    : 'IGW',
                statProp)
        );

        let filterText = '';
        filterBlocks.forEach(filterBlock => filterText = createFilterText(filterText, filterBlock));

        return filterText ? filterType + ' ' + filterText : '';
    }

    const currentSeason = (new Date()).getMonth() >= 3 ? (new Date()).getFullYear() : (new Date()).getFullYear() - 1;
    let seasons = [];
    for (let season = currentSeason; season >= 2002; season--) {
        seasons.push(season + '');
    }

    let disallowScaleUpThroughXRegex = new RegExp(/(G|GS|PA|IP|RAPP)-\d+/);

    useEffect(() => {
        switch (tabName) {
            case 'season':
                setHeader(<h1>Query - Find Players Meeting Criteria in a Season</h1>)
                setTimePeriodNotSelectable(false);
                setDisplayScaleUpOptions(true);
                break;
            case 'consecutive':
                setHeader(<h1>Query - Find Players Meeting Criteria in Consecutive Years</h1>)
                setThroughX(throughX.category !== 'Stat' ? throughX : { category: 'Any' });
                setTimePeriodNotSelectable(false);
                setDisplayScaleUpOptions(true);
                break;
            case 'throughX':
                setHeader(<h1>Query - Find Players Meeting Criteria Through X</h1>)
                setThroughX(throughX.category !== 'Any' ? throughX : { category: 'Stat', value: 'Seasons-0' });
                setTimePeriodNotSelectable(true);
                changeTimePeriodsToCTD();
                setDisplayScaleUpOptions(!disallowScaleUpThroughXRegex.test(throughX?.value));
                break;
            case 'dateRange':
                setHeader(<h1>Query - Find Players Meeting Criteria in a Date Range</h1>)
                setTimePeriodNotSelectable(true);
                changeTimePeriodsToCTD();
                setDisplayScaleUpOptions(true);
                break;
            case 'players':
                setHeader(<h1>Query - Find Specific Players</h1>)
                setTimePeriodNotSelectable(false);
                setDisplayScaleUpOptions(true);
                break;
            case 'contracts':
                setHeader(<h1>Query - Find Contracts</h1>)
                setTimePeriodNotSelectable(true);
                setDisplayScaleUpOptions(false);
                break;
            default:
                setHeader(<h1>No header</h1>)
        }
        setData([]);
        setDataRowCount(0);
    }, [tabName])

    useEffect(() => {
        if (disallowScaleUpThroughXRegex.test(throughX?.value)) {
            setScaleUpDisplayStats(0);
            setScaleUpFilters(false);
        }
        setDisplayScaleUpOptions(!disallowScaleUpThroughXRegex.test(throughX?.value));
    }, [throughX])

    function changeTimePeriodsToCTD() {
        let tempDisStats = displayStats.slice(0);
        tempDisStats.forEach(tempStat => {
            tempStat.timePeriod = 'Career To Date';
        });
        setDisplayStats(tempDisStats);
        let tempNumCrits = numericalCriteria.slice(0);
        tempNumCrits.forEach(tempStat => {
            tempStat.timePeriod = 'Career To Date';
        });
        setNumericalCriteria(tempNumCrits);
    }

    function updateReport(reportName, newTabName) {
        newTabName = newTabName ?? tabName;
        let statList = [];
        switch (reportName) {
            case 'DefaultBatter':
                statList = createStatListFromColIds(defaultGroups[0]);
                break;
            case 'DefaultCatcher':
                statList = createStatListFromColIds(defaultGroups[1]);
                break;
            case 'DefaultStartingPitcher':
                statList = createStatListFromColIds(defaultGroups[2]);
                break;
            case 'DefaultReliefPitcher':
                statList = createStatListFromColIds(defaultGroups[3]);
                break;
            case 'DefaultContracts':
                statList = createStatListFromColIds(defaultGroups[4]);
                break;
            case 'Custom':
                statList = createStatListFromColIds(
                    newTabName === 'contracts'
                        ? [1, 554, 555, 733, 734]
                        : ['throughX', 'dateRange'].includes(newTabName) ? [1] : [1, 2]); //1 = Player & 2 = Year
                break;
            case 'CopyFromAbove':
                statList = timePeriodNotSelectable
                    ? createStatListFromColIds([1]).concat(createStatListFromNumCrits(createNumCritInputArr(), 1))
                    : createStatListFromColIds([1, 2]).concat(createStatListFromNumCrits(createNumCritInputArr(), 2));
                break;
            default:
                break;
        }
        setDisplayStats(statList);
    }

    function createStatListFromColIds(colIds) {
        let disStatSelectableStatsByGroup = selectableStatsByGroup.map(group => {
            return group.filter(stat => stat.formInputs.isDisStat);
        });
        return colIds.map((colId, index) => {
            let statObj = {
                stat: colIds[index],
                index: index,
                timePeriod: timePeriodNotSelectable
                    ? 'Career To Date'
                    : disStatSelectableStatsByGroup.flat().find(c => c.key == colId)
                        ? disStatSelectableStatsByGroup.flat().find(c => c.key == colId).formInputs.defaultTimePeriod
                        : 'Platform Year'
            }
            return statObj;
        });
    }

    function createStatListFromNumCrits(numCrits, startIndex) {
        return numCrits.map((numCrit, index) => {
            return {
                stat: numCrit.stat,
                index: startIndex + index,
                timePeriod: timePeriodNotSelectable ? 'Career To Date' : numCrit.timePeriod,
                iswFilter: numCrit.iswFilter,
                igwFilter: numCrit.igwFilter,
            }
        })
    }

    return (
        <div className='query-tool'>
            <Navbar bg='light' variant='light' className='queryNav'>
                <Nav>
                    <Nav.Link
                        className={tabName === 'season' ? 'nav-selected' : null}
                        href='#season'
                        onClick={() => {
                            setTabName('season');
                            updateReport('Custom', 'season');
                        }}
                    >
                        {
                            tabName === 'season' &&
                            <span style={{ transform: 'translateX(20px) rotate(135deg)' }}></span>
                        }
                        Season
                    </Nav.Link>
                    <Nav.Link
                        className={tabName === 'consecutive' ? 'nav-selected' : null}
                        href='#consecutive'
                        onClick={() => {
                            setTabName('consecutive');
                            updateReport('Custom', 'consecutive');
                        }}
                    >
                        {
                            tabName === 'consecutive' &&
                            <span style={{ transform: 'translateX(34px) rotate(135deg)' }}></span>
                        }
                        Consecutive
                    </Nav.Link>
                    <Nav.Link
                        className={tabName === 'throughX' ? 'nav-selected' : null}
                        href='#throughX'
                        onClick={() => {
                            setTabName('throughX');
                            updateReport('Custom', 'throughX');
                        }}
                    >
                        {
                            tabName === 'throughX' &&
                            <span style={{ transform: 'translateX(30px) rotate(135deg)' }}></span>
                        }
                        Through X
                    </Nav.Link>
                    <Nav.Link
                        className={tabName === 'dateRange' ? 'nav-selected' : null}
                        href='#dateRange'
                        onClick={() => {
                            setTabName('dateRange');
                            updateReport('Custom', 'dateRange');
                        }}
                    >
                        {
                            tabName === 'dateRange' &&
                            <span style={{ transform: 'translateX(35px) rotate(135deg)' }}></span>
                        }
                        Date Range
                    </Nav.Link>
                    <Nav.Link
                        className={tabName === 'players' ? 'nav-selected' : null}
                        href='#players'
                        onClick={() => {
                            setTabName('players');
                            updateReport('Custom', 'players');
                        }}
                    >
                        {
                            tabName === 'players' &&
                            <span style={{ transform: 'translateX(50px) rotate(135deg)' }}></span>
                        }
                        Player Selection
                    </Nav.Link>
                    <Nav.Link
                        className={tabName === 'contracts' ? 'nav-selected' : null}
                        href='#contracts'
                        onClick={() => {
                            reset();
                            setTabName('contracts');
                            updateReport('Custom', 'contracts');
                        }}
                    >
                        {
                            tabName === 'contracts' &&
                            <span style={{ transform: 'translateX(30px) rotate(135deg)' }}></span>
                        }
                        Contracts
                    </Nav.Link>
                </Nav>
            </Navbar>
            <br />

            <div className='body'>
                <FloatingFilters
                    selectableStatsByGroup={selectableStatsByGroup.map(group => {
                        return group.filter(stat => stat.formInputs.isIGWFilter || stat.formInputs.isISWFilter);
                    })}
                    selectableStatGroups={selectableStatGroups.filter(group => {
                        return tabName !== 'contracts' || [43, 45, 46].includes(group.id);
                    })}
                    setHideFilters={setHideFilters}
                    getStatInfoFromColumnById={getStatInfoFromColumnById}
                />
                <SaveLoadButtons
                    header={header}
                    page={'QueryTool'}
                    resetFunction={reset}
                    getReportFunction={transformInputsToReportQuery}
                    loadReportFunction={(report) => loadReportFromJson(report)}
                />
                <br />
                {
                    ['season', 'consecutive', 'throughX'].includes(tabName) &&
                    <SeasonSelect
                        tabName={tabName}
                        seasons={seasons}
                        selectedSeasons={selectedSeasons}
                        setSelectedSeasons={setSelectedSeasons}
                        curSeason={currentSeason}
                        minSeason={consMinSeason}
                        setMinSeason={setConsMinSeason}
                        maxSeason={consMaxSeason}
                        setMaxSeason={setConsMaxSeason}
                    />
                }
                {
                    tabName === 'dateRange' &&
                    <DateRangeQuery
                        dateRanges={dateRanges}
                        setDateRanges={setDateRanges}
                    />
                }
                {
                    tabName === 'players' &&
                    <PlayerQuery
                        allPlayers={allPlayers}
                        playerSeasons={playerSeasons}
                        setPlayerSeasons={setPlayerSeasons}
                    />
                }
                {
                    ['season', 'consecutive', 'throughX', 'dateRange'].includes(tabName) &&
                    <PlayerCriteria
                        tabName={tabName}
                        playerCriteria={playerCriteria}
                        setPlayerCriteria={setPlayerCriteria}
                        allColumns={allColumns}
                        selectableStatsByGroup={selectableStatsByGroup.map(group => {
                            return group.filter(stat => stat.formInputs.isPlayerCrit);
                        })}
                        selectableStatGroups={selectableStatGroups}
                        hideFilters={hideFilters}
                        timePeriodNotSelectable={timePeriodNotSelectable}
                        getStatInfoFromColumnById={getStatInfoFromColumnById}
                    />
                }
                {
                    ['season', 'consecutive', 'throughX', 'dateRange', 'contracts'].includes(tabName) &&
                    <NumericalCriteria
                        tabName={tabName}
                        numericalCriteria={numericalCriteria}
                        setNumericalCriteria={setNumericalCriteria}
                        allColumns={allColumns}
                        selectableStatsByGroup={selectableStatsByGroup.map(group => {
                            return group.filter(stat => stat.formInputs.isNumCrit);
                        })}
                        selectableStatGroups={selectableStatGroups.filter(group => {
                            return tabName !== 'contracts' || [43, 45, 46].includes(group.id);
                        })}
                        hideFilters={hideFilters}
                        timePeriodNotSelectable={timePeriodNotSelectable}
                        getStatInfoFromColumnById={getStatInfoFromColumnById}
                        displayScaleUpOptions={displayScaleUpOptions && tabName !== 'contracts'}
                        scaleUp={scaleUpFilters}
                        setScaleUp={setScaleUpFilters}
                    />
                }
                {
                    ['consecutive', 'throughX'].includes(tabName) &&
                    <ThroughXQuery
                        tabName={tabName}
                        throughX={throughX}
                        setThroughX={setThroughX}
                        minSeason={consMinSeason}
                        maxSeason={consMaxSeason}
                        consecutiveYears={consecutiveYears}
                        setConsecutiveYears={setConsecutiveYears}
                    />
                }
                <br />
                <DisplayStats
                    tabName={tabName}
                    displayStats={displayStats}
                    setDisplayStats={setDisplayStats}
                    getNumCrits={createNumCritInputArr}
                    allColumns={allColumns}
                    selectableStatsByGroup={selectableStatsByGroup.map(group => {
                        return group.filter(stat => stat.formInputs.isDisStat);
                    })}
                    selectableStatGroups={selectableStatGroups.filter(group => {
                        return tabName !== 'contracts' || [39, 43, 44, 45, 46].includes(group.id);
                    })}
                    hideFilters={hideFilters}
                    timePeriodNotSelectable={timePeriodNotSelectable}
                    defaultGroups={defaultGroups}
                    getStatInfoFromColumnById={getStatInfoFromColumnById}
                    displayScaleUpOptions={displayScaleUpOptions && tabName !== 'contracts'}
                    scaleUp={scaleUpDisplayStats}
                    setScaleUp={setScaleUpDisplayStats}
                    updateReport={updateReport}
                />
                <br />
                <Button variant='success' onClick={() => getData()}>Submit</Button>
                <br />
                <br />
                {
                    loading &&
                    <div>
                        <br />
                        <br />
                        <i className='fa fa-spinner fa-spin loading-icon'></i>
                        <br />
                    </div>
                }
                {
                    errorMsg &&
                    <React.Fragment>
                        <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center' }}>{errorMsg}</p>
                        <br />
                        <br />
                    </React.Fragment>
                }
                {
                    data && data.length > 0 &&
                    <React.Fragment>
                        <ExcelExporter reportName={'QueryTool'} data={data} columns={cols} notes={notes} />
                        {
                            false &&
                            <React.Fragment>
                                <PDFExporter fileName={'QueryTool'} /*scrollPos={scrollPos} columns={cols}*/ />
                                <Button id='PdfExportBtn'>Other PDF</Button>
                            </React.Fragment>
                        }
                        <div id='notes'>
                            <h4>Notes</h4>
                            <p>
                                There are <b>{dataRowCount}</b> rows.
                            </p>
                            {
                                notes.timePeriodNotes &&
                                <React.Fragment>
                                    <p>
                                        <b>The following { tabName === 'dateRange' ? 'date ranges' : 'seasons' } have been selected:</b>
                                    </p>
                                    <p style={{ wordWrap: 'break-word' }}>
                                        {
                                            notes.timePeriodNotes.join(', ')
                                        }
                                    </p>
                                </React.Fragment>
                            }
                            {
                                notes.playerSeasonNotes &&
                                <React.Fragment>
                                    <p>
                                        <b>The following players have been selected:</b>
                                    </p>
                                    {
                                        notes.playerSeasonNotes.map(playerSeason => {
                                            return <p>{playerSeason}</p>
                                        })
                                    }
                                </React.Fragment>
                            }
                            {
                                notes.playerCriteriaNotes &&
                                <React.Fragment>
                                    <p>
                                        <b>The following player criteria have been selected:</b>
                                    </p>
                                    {
                                        notes.playerCriteriaNotes.map(playerCriteriaNote => {
                                            return <p>{playerCriteriaNote}</p>
                                        })
                                    }
                                </React.Fragment>
                            }
                            {
                                notes.numericalCriteriaNotes &&
                                <React.Fragment>
                                    <p>
                                        <b>The following numerical criteria have been selected:</b>
                                    </p>
                                    {
                                        notes.numericalCriteriaNotes.map(numericalCriteriaNote => {
                                            return <p>{numericalCriteriaNote}</p>
                                        })
                                    }
                                </React.Fragment>
                            }
                            {
                                notes.throughXStatNote &&
                                <React.Fragment>
                                    <p>
                                        <b>The following {tabName === 'consecutive' ? 'consecutive' : 'through x'} criteria have been selected:</b>
                                    </p>
                                    <p>{notes.consecutiveYearsNote}</p>
                                    <p>{notes.throughXStatNote}</p>
                                </React.Fragment>
                            }
                            {
                                notes.displayStatNotes &&
                                <React.Fragment>
                                    <p>
                                        <b>Time period annotation key:</b>
                                    </p>
                                    {
                                        notes.displayStatNotes.map((note, index) => {
                                            return <p>{(index + 1) + ' = ' + note}</p>
                                        })
                                    }
                                </React.Fragment>
                            }
                            {
                                notes.scaleUpNotes &&
                                <React.Fragment>
                                    <p style={{ backgroundColor: usingScaleUp ? '#FED8B1' : 'white' }}>
                                        {notes.scaleUpNotes}
                                    </p>
                                </React.Fragment>
                            }
                        </div>
                        <div
                            className='pdfSection'
                            style={{
                                width: data
                                    ? (cols.reduce((acc, cur) => acc + ((cur.width) > 20
                                        ? (cur.width)
                                        : 20), 0)) + 4
                                    : '100%'
                            }}
                        >
                            <div className='pdfChunk'>
                                <TabulatorTable
                                    cols={cols}
                                    data={data}
                                    movableColumns={true}
                                    pdfExport={false}
                                    initSort={[{
                                        column: tabName !== 'contracts' ? 'Platform Year Player' : 'Player',
                                        dir: 'asc'
                                    }]}
                                    dataTreeElementColumn='Team'
                                    paginationSize={150}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

export default QueryTool;

function SeasonSelect(props) {
    return (
        <React.Fragment>
            <h4>Select Season(s)</h4>
            <div
                id='SeasonSelect'
                style={{
                    width: '100%',
                    backgroundColor: 'lightgrey',
                    border: 'solid 1px black',
                    padding: '10px 10px 10px 25px' 
                }}
            >
                {
                    props.tabName === 'consecutive' &&
                    <div className='row'>
                        Start Season:
                        <SeasonDropDown
                            minSeason={2002}
                            maxSeason={props.maxSeason}
                            selectedSeason={props.minSeason}
                            setSeason={props.setMinSeason}
                        />
                        End Season:
                        <SeasonDropDown
                            minSeason={props.minSeason}
                            maxSeason={props.curSeason}
                            selectedSeason={props.maxSeason}
                            setSeason={props.setMaxSeason}
                        />
                    </div>
                }
                {
                    props.tabName !== 'consecutive' &&
                    <MultiSelect
                        label={'Season'}
                        list={props.seasons.map(item => { return { label: item + '', value: item + '' } })}
                        selectedItems={props.selectedSeasons}
                        setSelectedItems={(items) => props.setSelectedSeasons(items)}
                    />
                }
            </div>
        </React.Fragment>
    )
}

function FloatingFilters(props) {
    const [hidden, setHidden] = useState(true);
    const [message, setMessage] = useState('');
    function toggleFilterDisplay() {
        setHidden(!hidden);
    }

    useEffect(() => {
        props.setHideFilters(hidden);
    }, [hidden]);

    let sectionWidth = `-${document.getElementById('floating-filters')?.clientWidth}px`;

    function applyToAll(section) {
        let dropBlocks = Array.prototype.filter.call(document.getElementsByClassName('dropZone dropZone-all')[0].childNodes, (child) => {
            return child;
        });
        if (dropBlocks.length === 0) return;
        let filterGranularities = [];
        Array.prototype.forEach.call(dropBlocks, (dropBlock) => {
            dropBlock.querySelectorAll(`span[draggable='true']`).forEach(filter => {
                filterGranularities.push(filter.getAttribute('granularity'));
            })
        })

        function granularityComparisonAllow(filterType, filterGranularities, dropZoneGranularity) {
            if (filterType == 'ISW') {
                return filterGranularities
                    .map(granularity => parseInt(granularity))
                    .every(granularity => granularity >= parseInt(dropZoneGranularity));
            } else {
                return filterGranularities
                    .map(granularity => parseInt(granularity))
                    .every(granularity => granularity <= 2);
            }
        }
        
        let filterType = dropBlocks[0].classList.contains('ISW') ? 'ISW' : 'IGW';
        let dropZones = Array.prototype.filter.call(document.getElementById(section).getElementsByClassName('dropZone'), (dz) => {
            let dropZoneGranularity = dz.getAttribute('granularity') ?? -1;
            return granularityComparisonAllow(filterType, filterGranularities, dropZoneGranularity)
                && !dz.className.includes('-all')
                && !dz.className.includes('block')
                && dz.className.includes(filterType);
        })
        if (dropZones.length === 0) {
            setMessage(`One or more of the filters can not be applied to any 
                ${section == 'DisStat' ? 'Display Stat' : 'Numerical Criteria'}!`);
            setTimeout(() => { setMessage('') }, 5000);
        }

        Array.prototype.forEach.call(dropBlocks, (dropBlock) => {
            Array.prototype.forEach.call(dropZones, dz => {
                let nullEv = {
                    preventDefault: () => { },
                    stopPropagation: () => { },
                };
                dropElement(nullEv, dz, dropBlock.id)
            });
        });
    }

    return (
        <div style={{ left: hidden ? sectionWidth : '0px' }} id='floating-filters'>
            <FilterCreation
                selectableStatsByGroup={props.selectableStatsByGroup}
                colGroups={
                    props.selectableStatGroups.filter(group => {
                        return !['consecutive', 'throughX', 'dateRange'].includes(props.tabName)
                            || group.id !== 43;
                    })
                }
                tabName={props.tabName}
                timePeriodNotSelectable={true}
                hideFilters={true}
                getStatInfoFromColumnById={props.getStatInfoFromColumnById}
            />
            <Row style={{ minHeight: '36px' }}>
                <Col xs={3} xl={1}>
                    <Button variant='info' onClick={() => applyToAll('NumCrit')}>
                        Apply to All Numerical Criteria
                    </Button>
                </Col>
                <Col xs={3} xl={1}>
                    <Button variant='info' onClick={() => applyToAll('DisStat')}>
                        Apply to All Display Stats
                    </Button>
                </Col>
                <Col xs={9} lg={6} style={{ minHeight: '36px' }}>
                    <div
                        className='dropZone dropZone-all'
                        onDragOver={(ev) => {
                            ev.preventDefault();
                            ev.dataTransfer.dropEffect = 'move';
                        }}
                        onDrop={(ev) => dropElement(ev)}
                    >
                    </div>
                </Col>
            </Row>
            <Row className='justify-content-md-center'>
                {
                    message &&
                    <Col xs={6} style={{ border: 'solid 2px red', borderRadius: '5px', color: 'red', background: '#d2d2d2'}}>
                        {message}
                    </Col>
                }
            </Row>
            <span className='span-button' onClick={toggleFilterDisplay} type='button'>{hidden ? '>' : '<'}</span>
            <span className='span-filter-label'>ADVANCED FILTERS</span>
        </div>
    )
}

function ThroughXQuery(props) {
    const [header, setHeader] = useState('');
    const [radioBtnCategories, setRadioBtnCategories] = useState(props.tabName === 'consecutive'
        ? ['Any', 'Age', 'Arb']
        : ['Stat', 'Age', 'Arb']
    );
    const [statVal, setStatVal] = useState(props.throughX.category === 'Stat' ? props.throughX.value : 'Seasons-0');
    const [age, setAge] = useState(props.throughX.category === 'Age' ? props.throughX.value : 'Age-18');
    const [arbVal, setArbVal] = useState(props.throughX.category === 'Arb' ? props.throughX.value : 'Arb-1');

    function changeThroughX(category) {
        switch (category) {
            case 'Age':
                props.setThroughX({ category: 'Age', value: age });
                break;
            case 'Arb':
            case 'MLS':
                props.setThroughX({ category: 'Arb', value: arbVal });
                break;
            case 'Stat':
                props.setThroughX({ category: 'Stat', value: statVal });
                break;
            case 'Any':
                props.setThroughX({ category: 'Any' });
                break;
            default:
                break;
        }
    }

    function changeStatVal(field, value) {
        let curStat = statVal.split('-')[0];
        let curStatVal = statVal.split('-')[1];
        if (field === 'stat') {
            curStat = value;
        } else {
            curStatVal = value;
        }
        setStatVal(`${curStat}-${curStatVal}`);
    }

    useEffect(() => {
        if (props.throughX.category === 'Age') {
            props.setThroughX({ category: 'Age', value: age });
        }
    }, [age])

    useEffect(() => {
        if (['Arb', 'MLS'].includes(props.throughX.category)) {
            props.setThroughX({ category: 'Arb', value: arbVal });
        }
    }, [arbVal])

    useEffect(() => {
        if (props.throughX.category === 'Stat') {
            props.setThroughX({ category: 'Stat', value: statVal });
        }
    }, [statVal])

    let arbDropDownOptions = [
        { value: 'Arb-1', label: 'First-time arb eligible' },
        { value: 'Arb-2', label: '2nd-time arb eligible' },
        { value: 'Arb-3', label: '3rd-time arb eligible' },
        { value: 'Arb-4', label: '4th-time arb eligible' },
        { value: 'MLS-1', label: 'MLS 1' },
        { value: 'MLS-1.171', label: 'MLS 1+' },
        { value: 'MLS-2.171', label: 'MLS 2+' },
        { value: 'MLS-3.171', label: 'MLS 3+' },
        { value: 'MLS-4.171', label: 'MLS 4+' },
        { value: 'MLS-5.171', label: 'MLS 5+' },
        { value: 'MLS-6.171', label: 'MLS 6+' },
        { value: 'MLS-7.171', label: 'MLS 7+' },
        { value: 'MLS-8.171', label: 'MLS 8+' },
        { value: 'MLS-9.171', label: 'MLS 9+' },
        { value: 'MLS-10.171', label: 'MLS 10+' },
    ]

    let statDropdownOptions = [
        { label: 'Seasons', value: 'Seasons' },
        { label: 'Games', value: 'G' },
        { label: 'Games Started', value: 'GS' },
        { label: 'PA', value: 'PA' },
        { label: 'IP', value: 'IP' },
        { label: 'Relief Appearances', value: 'RAPP' },
    ]

    let radioBtns = [
        {
            category: 'Any',
            label: <div className='row'>Any time in the Player's Career</div>,
        },
        {
            category: 'Stat',
            label:
                <OverlayTrigger
                    placement={'top'}
                    overlay={
                        <Tooltip id={'tooltip'}>
                            <p>Can be less than input stat if player meets the criteria above</p>
                            <p>Through X must at least partially fall in the selected seasons</p>
                            <p>Games represents games of any type (fielding, pitching or hitting)</p>
                            <p>Players that debuted before 2002 are excluded</p>
                        </Tooltip>
                    }
                >
                    <div className='row'>
                        Through the Player's First
                        <Form.Control
                            defaultValue={props.throughX.category === 'Stat' ? Number(props.throughX.value.split('-')[1]) : 0}
                            style={{ width: '80px', marginLeft: '10px', marginRight: '10px' }}
                            type='number'
                            onChange={(event) => changeStatVal('value', event.target.value)}
                        />
                        <Form.Control
                            style={{ width: '200px', marginLeft: '10px', marginRight: '10px' }}
                            as='select'
                            onChange={(event) => changeStatVal('stat', event.target.value)}
                        >
                            {
                                statDropdownOptions.map((option, index) => {
                                    if (props.throughX.category === 'Stat' && props.throughX.value.split('-')[0] === option.value) {
                                        return (
                                            <option
                                                selected
                                                key={index}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )
                                    }
                                    return (
                                        <option
                                            key={index}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    )
                                })
                            }
                        </Form.Control>
                    </div>
                </OverlayTrigger>,
        },
        {
            category: 'Age',
            label:
                <div className='row'>
                    Through the Player's Age
                    <IntRangeDropdown
                        minVal={18}
                        maxVal={42}
                        selectedVal={props.throughX.category === 'Age' ? Number(props.throughX.value.split('-')[1]) : age}
                        sortOrder={'asc'}
                        setSelectedVal={(val) => setAge(`Age-${val}`)}
                    />
                    Season
                </div>,
        },
        {
            category: 'Arb',
            label:
                <div className='row'>
                    Through the Player's
                    <Form.Control
                        style={{ width: '200px', marginLeft: '10px', marginRight: '10px' }}
                        as='select'
                        onChange={(event) => setArbVal(event.target.value)}
                    >
                        {
                            arbDropDownOptions.map((option, index) => {
                                if (props.throughX.category === 'Arb' && props.throughX.value === option.value) {
                                    return (
                                        <option
                                            selected
                                            key={index}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    )
                                }
                                return (
                                    <option
                                        key={index}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                )
                            })
                        }
                    </Form.Control>
                    Platform Season
                </div>,
        },
    ];

    useEffect(() => {
        if (props.tabName === 'consecutive') {
            setRadioBtnCategories(['Any', 'Age', 'Arb']);
            setHeader(<h4>Consecutive Criteria?</h4>);
        } else {
            setRadioBtnCategories(['Stat', 'Age', 'Arb']);
            setHeader(<h4>Through X Criteria?</h4>);
        }
    }, [props.tabName])

    return (
        <React.Fragment>
            {header}
            <div
                style={{
                    width: '100%',
                    backgroundColor: 'lightgrey',
                    border: 'solid 1px black',
                    padding: '10px'
                }}
            >
                {
                    props.tabName === 'consecutive' &&
                    <div className='row'>
                        In
                        <IntRangeDropdown
                                minVal={1}
                                maxVal={props.maxSeason - props.minSeason + 1}
                                selectedVal={props.consecutiveYears}
                                sortOrder={'asc'}
                                setSelectedVal={props.setConsecutiveYears}
                            />
                        Consecutive Years
                    </div>
                }
                {
                    radioBtnCategories.map((category, index) => {
                        if (props.throughX.category === category) {
                            return <Form.Check
                                checked
                                key={index}
                                type={'radio'}
                                name='throughX-radio'
                                id={`throughX-${category}-radio`}
                                label={radioBtns.find(radioBtn => radioBtn.category === category).label}
                                onChange={() => changeThroughX(category)}
                            />
                        } else {
                            return <Form.Check
                                key={index}
                                type={'radio'}
                                name='throughX-radio'
                                id={`throughX-${category}-radio`}
                                label={radioBtns.find(radioBtn => radioBtn.category === category).label}
                                onChange={() => changeThroughX(category)}
                            />
                        }
                    })
                }
            </div>
        </React.Fragment>
    )
}

function DateRangeQuery(props) {
    function setStartDate(index, startDate) {
        let tempDateRanges = props.dateRanges.slice(0);
        tempDateRanges[index].startDate = startDate;
        props.setDateRanges(tempDateRanges);
    }

    function setEndDate(index, endDate) {
        let tempDateRanges = props.dateRanges.slice(0);
        tempDateRanges[index].endDate = endDate;
        props.setDateRanges(tempDateRanges);
    }

    function removeDateRange(index) {
        let tempDateRanges = props.dateRanges.slice(0);
        tempDateRanges.splice(index, 1);
        props.setDateRanges(tempDateRanges);
    }

    return (
        <React.Fragment>
            <h4>Select Date Range</h4>
            <div
                style={{
                    width: '100%',
                    backgroundColor: 'lightgrey',
                    border: 'solid 1px black',
                    padding: '10px'
                }}
            >
                {
                    props.dateRanges.map((dateRange, index) => {
                        let endDate = customDateString(dateRange.endDate);
                        let startDate = customDateString(dateRange.startDate);
                        return (
                            <Row key={index}>
                                <Col xs={6} lg={5} xl={2}>
                                    <Row>
                                        <b className='label' style={{ paddingRight: '25px' }}>From: </b>
                                        <input
                                            value={startDate}
                                            type='date'
                                            min={customDateString(new Date(2002, 0, 0))}
                                            max={endDate}
                                            onChange={(e) => {
                                                if (e.target.value) { setStartDate(index, new Date(e.target.value + 'T00:00:00')) }
                                            }}
                                        />
                                    </Row>
                                </Col>
                                <Col xs={5} lg={5} xl={2}>
                                    <Row>
                                        <b className='label' style={{ padding: '0px 25px' }}>To: </b>
                                        <input
                                            value={endDate}
                                            type='date'
                                            min={startDate}
                                            max={customDateString(new Date())}
                                            onChange={(e) => {
                                                if (e.target.value) { setEndDate(index, new Date(e.target.value + 'T00:00:00')) }
                                            }}
                                        />
                                    </Row>
                                </Col>
                                <Col xs={1}>
                                    <Badge
                                        style={{ cursor: 'pointer' }}
                                        tabIndex='0'
                                        variant='danger'
                                        onClick={() => removeDateRange(index)}
                                    >
                                        X
                                    </Badge>
                                </Col>
                            </Row>
                        )
                    })
                }
                <Button variant='info' onClick={() => {
                    if (props.dateRanges.length < 9) {
                        let tempDateRanges = props.dateRanges.slice(0);
                        tempDateRanges.push({ startDate: new Date(2002, 1, 1), endDate: new Date() });
                        props.setDateRanges(tempDateRanges);
                    }
                }}>Add Date Range</Button>
            </div>
        </React.Fragment>
    )
}

function PlayerQuery(props) {
    function setPlayer(index, playerId, platformSeason) {
        let tempPlayerSeasons = props.playerSeasons.slice(0);
        tempPlayerSeasons[index] = { playerId: playerId, platformSeason: platformSeason };
        props.setPlayerSeasons(tempPlayerSeasons);
    }

    function removeSelectedPlayer(index) {
        let tempPlayerSeasons = props.playerSeasons.slice(0);
        tempPlayerSeasons.splice(index, 1);
        props.setPlayerSeasons(tempPlayerSeasons);
    }

    return (
        <React.Fragment>
            <h4>Select Players and Seasons</h4>
            <div
                style={{
                    width: '100%',
                    backgroundColor: 'lightgrey',
                    border: 'solid 1px black',
                    padding: '10px'
                }}
            >
                {
                    props.playerSeasons.map((playerSeason, index) => {
                        return (
                            <Row>
                                <PlayerSearch
                                    key={index}
                                    players={props.allPlayers}
                                    label={'Player'}
                                    selectedPlayer={playerSeason}
                                    onSelect={(playerId, platformSeason, posFlag) => setPlayer(index, playerId, platformSeason)}
                                />
                                <Badge
                                    style={{ cursor: 'pointer' }}
                                    tabIndex='0'
                                    variant='danger'
                                    onClick={() => removeSelectedPlayer(index)}
                                >
                                    X
                                </Badge>
                            </Row>
                        )
                    })
                }
                <Button variant='info' onClick={() => {
                    /*if (props.playerSeasons.length < 9) {*/
                        let tempPlayerSeasons = props.playerSeasons.slice(0);
                        tempPlayerSeasons.push({ playerId: 0, platformSeason: 0 })
                        props.setPlayerSeasons(tempPlayerSeasons);
                    /*}*/
                }}>Add Player</Button>
            </div>
        </React.Fragment>    
    )
}
