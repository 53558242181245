import React from 'react';
import StatSelection from './StatSelection';

function PlayerCriteria(props) {
    let hasBlankCriteria = props.playerCriteria.some(crit => crit.stat === '' || crit.stat == null)

    return (
        <React.Fragment>
            <h4>Select Player Criteria</h4>
            <div
                id='PlayerCrit'
                style={{
                    width: '100%',
                    backgroundColor: 'lightgrey',
                    border: hasBlankCriteria ? 'solid 2px red' : 'solid 1px black',
                    padding: '10px'
                }}>
                <StatSelection
                    tabName={props.tabName}
                    selectableStatsByGroup={props.selectableStatsByGroup}
                    colGroups={props.selectableStatGroups.filter(group => group.id === 44)}
                    section={'Player Criteria'}
                    stats={props.playerCriteria}
                    setStats={props.setPlayerCriteria}
                    hideFilters={props.hideFilters}
                    timePeriodNotSelectable={true}
                    getStatInfoFromColumnById={props.getStatInfoFromColumnById}
                />
            </div>
        </React.Fragment>
    )
}

export default PlayerCriteria;
