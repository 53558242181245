import React, { useState } from 'react';
import { Accordion, Card, Form, Button, Row, Col } from 'react-bootstrap';
import StatSelectionForm from './StatSelectionForm';
import useForceUpdate from '../../Hooks/useForceUpdate';
import RadioButton from '../Common/RadioButton';

function StatSelection(props) {
    const [origTimePeriod, setOrigTimePeriod] = useState('Platform Year');
    const [updatedTimePeriod, setUpdatedTimePeriod] = useState('Platform Year');
    const [timePeriodManipulationType, setTimePeriodManipulationType] = useState('change');
    const [intersperseNewStats, setIntersperseNewStats] = useState(false);
    const forceUpdate = useForceUpdate();
    let sectionId = props.section === 'Stat' ? 'DisStat' : props.section === 'Criteria' ? 'NumCrit' : '';

    function setStat(statIndex, field, value) {
        let tempStats = props.stats.slice(0);
        let tempStatIndex = tempStats.findIndex(ts => ts.index === statIndex);
        tempStats[tempStatIndex][field] = value;
        if (field === 'stat') {
            let curStatFormInputs = getFormInputsFromColumnsById(value);
            tempStats[tempStatIndex].timePeriod =
                props.timePeriodNotSelectable
                    ? 'Career To Date'
                    : curStatFormInputs.defaultTimePeriod;

            if (curStatFormInputs.inputType === 'Date') {
                tempStats[tempStatIndex].value = new Date();
            }
            if (['Select', 'MultiSelect'].includes(curStatFormInputs.inputType)) {
                tempStats[tempStatIndex].operator = '=';
                tempStats[tempStatIndex].value = curStatFormInputs.selectOptions[0]?.value ?? '0';
            }
        }
        props.setStats(tempStats);
    }

    function removeStat(statIndex) {
        let iswFilterMap = new Map();
        let igwFilterMap = new Map();
        for (let i = statIndex + 1; i < props.stats.length; i++) {
            iswFilterMap.set(i - 1, document.getElementById(`${sectionId}-ISW-dropZone-${i}`)?.children ?? [])
            igwFilterMap.set(i - 1, document.getElementById(`${sectionId}-IGW-dropZone-${i}`)?.children ?? [])
        }

        let tempStats = props.stats.slice(0);
        tempStats.splice(statIndex, 1);
        props.setStats(tempStats, () => {
            for (const [key, value] of iswFilterMap) {
                for (let i = 0; i < value.length; i++) {
                    document.getElementById(`${sectionId}-ISW-dropZone-${key}`)?.appendChild(value[i]);
                }
            }
            for (const [key, value] of igwFilterMap) {
                for (let i = 0; i < value.length; i++) {
                    document.getElementById(`${sectionId}-IGW-dropZone-${key}`)?.appendChild(value[i]);
                }
            }
            forceUpdate();
        });
    }

    function addNewStat() {
        let tempStats = props.stats.slice(0);
        let maxIndex = 0;
        if (tempStats.length > 0) {
            maxIndex = tempStats.reduce((prev, cur) => prev.index > cur.index ? prev : cur).index;
        }
        tempStats.push({
            index: maxIndex + 1,
            stat: null,
            operator: '>',
            value: '0',
            timePeriod: props.timePeriodNotSelectable ? 'Career To Date' : 'Platform Year',
        });
        props.setStats(tempStats);
    }

    function replaceClassAndIdIndexOnFilterAndChildren(curFilter, curIndex, newIndex) {
        curFilter.id = curFilter.id?.replace(/dropzone-\d+/, `dropZone-${newIndex}`);
        curFilter.id = curFilter.id?.replace(/criteria-\d+/, `criteria-${newIndex}`);
        curFilter.className = curFilter.className?.replace(/dropzone-\d+/, `dropZone-${newIndex}`);
        curFilter.className = curFilter.className?.replace(/criteria-\d+/, `criteria-${newIndex}`);
        for (let i = 0; i < curFilter.childNodes.length; i++) {
            replaceClassAndIdIndexOnFilterAndChildren(curFilter.childNodes[i], curIndex, newIndex);
        }
    }

    function appendAllFiltersToEle(filters, ele, curIndex, newIndex) {
        let docFrag = document.createDocumentFragment();
        filters.forEach(node => {
            replaceClassAndIdIndexOnFilterAndChildren(node, curIndex, newIndex);
        })
        docFrag.append(...filters);
        ele?.appendChild(docFrag);
    }

    function reorderStat(statIndex, direction) {
        let newIndex = statIndex + (direction === 'up' ? -1 : 1);
        let curIGWId = `DisStat-IGW-dropZone-${statIndex}`;
        let newIGWId = `DisStat-IGW-dropZone-${newIndex}`;
        let curIGWFilters = document.getElementById(curIGWId)?.childNodes ?? [];
        let newIGWFilters = document.getElementById(newIGWId)?.childNodes ?? [];
        let curISWId = `DisStat-ISW-dropZone-${statIndex}`;
        let newISWId = `DisStat-ISW-dropZone-${newIndex}`;
        let curISWFilters = document.getElementById(curISWId)?.childNodes ?? [];
        let newISWFilters = document.getElementById(newISWId)?.childNodes ?? [];

        let offset = direction === 'up' ? -1 : 2;
        let newStatIndex = statIndex + offset;
        let tempStats = props.stats.slice(0);
        let statToMove = tempStats.slice(statIndex, statIndex + 1)[0];
        statToMove.iswFilter = null;
        statToMove.igwFilter = null;
        tempStats.splice(newStatIndex, 0, statToMove);
        tempStats.splice(statIndex + (direction === 'up' ? 1 : 0), 1);
        props.setStats(tempStats, () => {
            appendAllFiltersToEle(curIGWFilters, document.getElementById(newIGWId), statIndex, newIndex);
            appendAllFiltersToEle(newIGWFilters, document.getElementById(curIGWId), statIndex, newIndex);
            appendAllFiltersToEle(curISWFilters, document.getElementById(newISWId), statIndex, newIndex);
            appendAllFiltersToEle(newISWFilters, document.getElementById(curISWId), statIndex, newIndex);
            forceUpdate();
        });
    }

    function getTimePeriodsFromFormInputs(formInputs) {
        let retTimePeriods = [];
        if ((props.section === 'Criteria' && formInputs.criteriaSingleYearTimePeriods) ||
            (props.section === 'Stat' && formInputs.disStatSingleYearTimePeriods)) {
            retTimePeriods.push(...singleYearTimePeriods);
        }
        if ((props.section === 'Criteria' && formInputs.criteriaAggTimePeriods) ||
            (props.section === 'Stat' && formInputs.disStatAggTimePeriods)) {
            retTimePeriods.push(...aggTimePeriods);
        }
        return retTimePeriods;
    }

    function updateStatsTimePeriods() {
        let tempStats = props.stats.slice(0);

        tempStats.forEach(curTempStat => {
            if (curTempStat.timePeriod === origTimePeriod
                && getTimePeriodsFromFormInputs(props.getStatInfoFromColumnById(curTempStat.stat)?.formInputs)
                    .includes(updatedTimePeriod)) {
                curTempStat.timePeriod = updatedTimePeriod;
            }
        })

        props.setStats(tempStats);
    }

    function duplicateStatsAsTimePeriod() {
        let tempStats = props.stats.slice(0);

        let newStats = [];
        let dupeStats = [];
        tempStats.forEach((curTempStat, loopIndex) => {
            curTempStat.statWasCopied = null;
            curTempStat.origIndexIGWFilters = null;
            curTempStat.origIndexISWFilters = null;
            curTempStat.origIndex = loopIndex;
            curTempStat.index = loopIndex + (intersperseNewStats ? dupeStats.length : 0);
            newStats.push(curTempStat);
            if (curTempStat.timePeriod === origTimePeriod
                && getTimePeriodsFromFormInputs(props.getStatInfoFromColumnById(curTempStat.stat)?.formInputs)
                    .includes(updatedTimePeriod)) {
                let dupeTempStat = Object.assign({}, curTempStat);
                curTempStat.statWasCopied = true;
                curTempStat.origIndexIGWFilters = document
                    .getElementById(`DisStat-IGW-dropZone-${loopIndex}`)?.childNodes ?? [];
                curTempStat.origIndexISWFilters = document
                    .getElementById(`DisStat-ISW-dropZone-${loopIndex}`)?.childNodes ?? [];
                dupeTempStat.timePeriod = updatedTimePeriod;
                if (intersperseNewStats) {
                    dupeTempStat.index = loopIndex + dupeStats.length;
                    newStats.push(dupeTempStat);
                } else {
                    dupeTempStat.index = tempStats.length + dupeStats.length;
                }
                dupeTempStat.origIndex = loopIndex;
                dupeStats.push(dupeTempStat);
            }
        })

        if (!intersperseNewStats) {
            newStats = newStats.concat(dupeStats);
        }
        props.setStats(newStats, () => {
            newStats.forEach(newStat => {
                if (newStat.statWasCopied) {
                    appendAllFiltersToEle(
                        newStat.origIndexIGWFilters,
                        document.getElementById(`DisStat-IGW-dropZone-${newStat.index}`),
                        newStat.origIndex,
                        newStat.index
                    );
                    appendAllFiltersToEle(
                        newStat.origIndexISWFilters,
                        document.getElementById(`DisStat-ISW-dropZone-${newStat.index}`),
                        newStat.origIndex,
                        newStat.index
                    );
                }
            })
            forceUpdate();
        });
    }

    function getFormInputsFromColumnsById(id) {
        return props.selectableStatsByGroup.flat().find(c => c.key == id)
            ? props.selectableStatsByGroup.flat().find(c => c.key == id).formInputs
            : {};
    }

    let displayTimePeriod = !props.timePeriodNotSelectable
        || (props.section === 'Criteria' && ['throughX', 'dateRange'].includes(props.tabName));
    let statSelectionHtml = [];
    statSelectionHtml.push(
        <Row key='0'>
            <Col className='d-none d-lg-block' lg={3} xl={2}>
                Stat
            </Col>
            {
                ['Player Criteria', 'Criteria'].includes(props.section) &&
                <React.Fragment>
                    <Col className='d-none d-lg-block' lg={2}>
                        Operator
                    </Col>
                    <Col className='d-none d-lg-block' lg={2}>
                        &nbsp;&nbsp;Value
                    </Col>
                </React.Fragment>
            }
            {
                displayTimePeriod &&
                <Col className='d-none d-lg-block' lg={2}>
                    &nbsp;&nbsp;Time Period
                </Col>
            }
            <Col className='d-none d-lg-block' lg={1}></Col>
        </Row>
    );

    props.stats.forEach((curStat, index) => {
        statSelectionHtml.push(
            <StatSelectionForm
                selectableStatsByGroup={props.selectableStatsByGroup}
                colGroups={props.colGroups}
                section={props.section}
                tabName={props.tabName}
                timePeriodNotSelectable={props.timePeriodNotSelectable}
                hideFilters={props.hideFilters}
                stats={props.stats}
                curStat={curStat}
                index={index}
                setStat={setStat}
                removeStat={removeStat}
                reorderStat={reorderStat}
                getStatInfoFromColumnById={props.getStatInfoFromColumnById}
                getTimePeriodsFromFormInputs={getTimePeriodsFromFormInputs}
            />
        )
    });

    return (
        <React.Fragment>
            {
                props.stats.length > 0 && !props.timePeriodNotSelectable &&
                <Accordion>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant='link' eventKey='0'>Change/Add Time Periods for Multiple Stats</Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey='0'>
                            <Card.Body>
                                <Row>
                                    <Col
                                        xs={12}
                                        md={{ span: 6, offset: 3 }}
                                        lg={{ span: 4, offset: 4 }}
                                        style={{ border: 'solid 1px black' }}
                                    >
                                        <Row>
                                            <Col>
                                                <RadioButton
                                                    checked={timePeriodManipulationType === 'change'}
                                                    key='1'
                                                    name='timePeriodManipulationType'
                                                    id='change'
                                                    value='change'
                                                    label='Change Existing Stats'
                                                    onClick={(event) => setTimePeriodManipulationType(event.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <RadioButton
                                                    checked={timePeriodManipulationType === 'duplicate'}
                                                    key='2'
                                                    name='timePeriodManipulationType'
                                                    id='duplicate'
                                                    value='duplicate'
                                                    label='Duplicate Existing Stats'
                                                    onClick={(event) => setTimePeriodManipulationType(event.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} lg={{ span: 5, offset: 1 }} style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ marginRight: '5px' }}>
                                            {timePeriodManipulationType === 'change' ? 'Change:' : 'Duplicate:'}
                                        </span>
                                        <Form.Control as='select' onChange={(event) => setOrigTimePeriod(event.target.value)}>
                                        {
                                            singleYearTimePeriods
                                                .concat(aggTimePeriods)
                                                .filter(timePeriod => props.stats.findIndex(stat => stat.timePeriod === timePeriod) !== -1)
                                                .map((timePeriod) => {
                                                    return (
                                                        <option
                                                            selected={origTimePeriod === timePeriod ? 'selected' : false}
                                                            key={timePeriod}
                                                            value={timePeriod}
                                                        >
                                                            {timePeriod}
                                                        </option>
                                                    )
                                                })
                                        }
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} lg={5} style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ marginRight: '5px' }}>
                                            {timePeriodManipulationType === 'change' ? 'To:' : 'As:'}
                                        </span>
                                        <Form.Control as='select' onChange={(event) => setUpdatedTimePeriod(event.target.value)}>
                                            {
                                                singleYearTimePeriods
                                                    .concat(aggTimePeriods)
                                                    .map((timePeriod, mapIndex) => {
                                                        return (
                                                            <option
                                                                selected={updatedTimePeriod === timePeriod ? 'selected' : false}
                                                                key={mapIndex}
                                                                value={timePeriod}
                                                            >
                                                                {timePeriod}
                                                            </option>
                                                        )
                                                    })
                                            }
                                        </Form.Control>
                                    </Col>
                                </Row>
                                {
                                    timePeriodManipulationType !== 'change' &&
                                    <Row>
                                        <Col
                                            xs={12}
                                            md={{ span: 6, offset: 3 }}
                                            lg={{ span: 4, offset: 4 }}
                                            style={{ border: 'solid 1px black' }}
                                        >
                                            <Row>
                                                <Col>
                                                    <RadioButton
                                                        checked={intersperseNewStats}
                                                        disabled={timePeriodManipulationType === 'change'}
                                                        key='1'
                                                        name='intersperse'
                                                        label='Intersperse Duplicate Stats'
                                                        onClick={() => setIntersperseNewStats(true)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <RadioButton
                                                        checked={!intersperseNewStats}
                                                        disabled={timePeriodManipulationType === 'change'}
                                                        key='2'
                                                        name='intersperse'
                                                        label='Show Duplicate Stats at End'
                                                        onClick={() => setIntersperseNewStats(false)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col>
                                        {
                                            timePeriodManipulationType === 'change' &&
                                            <Button
                                                variant='info'
                                                onClick={() => updateStatsTimePeriods()}
                                            >
                                                Apply Change
                                            </Button>
                                        }
                                        {
                                            timePeriodManipulationType !== 'change' &&
                                            <Button
                                                variant='info'
                                                onClick={() => duplicateStatsAsTimePeriod()}
                                            >
                                                Apply Change
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            }
            {statSelectionHtml}
            <Row>
                <Col lg={3}>
                <Button variant='info' onClick={() => addNewStat()}>
                    <img width='14' height='13' alt='' src={'./images/Filter.png'} type='button' />
                    Add {props.section}
                </Button>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default StatSelection;

let singleYearTimePeriods = [
    'Platform Year',
    'Platform Year - 1',
    'Platform Year - 2',
    'Platform Year - 3',
    'Platform Year - 4',
    'Platform Year + 1',
    'Platform Year + 2',
    'Platform Year + 3',
    'Platform Year + 4',
]

let aggTimePeriods = [
    'Career To Date',
    'Last 2 Years',
    'Last 3 Years',
    'Last 4 Years',
    'Next 2 Years',
    'Next 3 Years',
    'Next 4 Years',
]
