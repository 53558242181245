import { customDateString } from "../Components/Common/CommonFunctions";

const baseUrl = process.env.REACT_APP_BASE_URL;
let initOptions = {
    headers: new Headers(),
}

async function getPlayers(token) {
    let options = {
        headers: new Headers(),
    }
    options.headers.append('Authorization', `Bearer ${token}`);

    let players = [];
    players = fetch(`${baseUrl}/compreport/players`, options)
        .then(response => response.json())
        .then((data) => {
            let playerNames = new Map();
            let dupNameIndices = new Set();
            const players = data.data.map((p, index) => {
                let hasDuplicateName = playerNames.has(`${p.commonName} ${p.lastName}`);
                if (hasDuplicateName) {
                    dupNameIndices.add(playerNames.get(`${p.commonName} ${p.lastName}`));
                }
                playerNames.set(`${p.commonName} ${p.lastName}`, index);
                return {
                    name: p.commonName + ' ' + p.lastName,
                    id: p.playerId,
                    posFlag: p.posFlag,
                    minSeason: p.minSeason,
                    maxSeason: p.maxSeason,
                    dob: (new Date(p.dob)).toLocaleDateString(),
                    hasDupName: hasDuplicateName
                }
            });
            for (let dupNameIndex of dupNameIndices) {
                players[dupNameIndex].hasDupName = true;
            }
            return players;
        });
    return players;
}

export {
    getPlayers,
}