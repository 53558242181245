import React from 'react';
import { Form } from 'react-bootstrap';

function RadioButton(props) {

    return (
        <React.Fragment>
            {
                props.disabled &&
                <Form.Check
                    disabled
                    key={props.key}
                    type='radio'
                    name={props.name}
                    id={props.id}
                    value={props.value}
                    label={props.label}
                    onClick={props.onClick}
                    onChange={props.onChange}
                />
            }
            {
                props.checked && !props.disabled &&
                <Form.Check
                    checked
                    key={props.key}
                    type='radio'
                    name={props.name}
                    id={props.id}
                    value={props.value}
                    label={props.label}
                    onClick={props.onClick}
                    onChange={props.onChange}
                />
            }
            {
                !props.disabled && !props.checked &&
                <Form.Check
                    key={props.key}
                    type='radio'
                    name={props.name}
                    id={props.id}
                    value={props.value}
                    label={props.label}
                    onClick={props.onClick}
                    onChange={props.onChange}
                />
            }
            
        </React.Fragment>
    )
}

export default RadioButton;