import { customDateString } from "../Components/Common/CommonFunctions";

const scaleUpSeasons = [2020];
const compareReportTimePeriods = [
    'Platform Year - 1',
    'Platform Year - 2',
    'Last 2 Years',
    'Last 3 Years',
    'Career To Date',
    'Platform Year',
]

const awardsReportAwardNames = [
    '(Award=MVP)',
    '(Award=Cy Young)',
    '(Award=Gold Glove)',
    '(Award=Silver Slugger)',
    '(Award=ROY)',
    '(Award=LCS MVP)',
    '(Award=WS MVP)',
    '(Award=AS MVP)',
    '(Award=CPOY)',
    '(Award=SNPOY)',
    '(Award=RRM)',
    '(Award=AS)',
    '(Award=ASE)',
    '(Award=FOY)',
    '(Award=Hoffman/Rivera)',
    '(Award=All-MLB TM)',
    '(Award=FBA)',
]

function nestChildRows(data, cumulative) {

    function addChildren(season) {
        season['_children'] = [];
        season['_children'].push(...data
            .filter(
                d => d.PlayerId === season.PlayerId
                    && d.Pos === season.Pos
                    && d.Starter === season.Starter
                    && d.TotalCategory === season.TotalCategory
                    && d.Team && !d.Team.includes('Tms')
                    && (cumulative === 1 || d.Season === season.Season)));
        return season;
    }

    function addScaleChildren(season) {
        season['_children'] = [];
        season['_children'].push(...data
            .filter(
                d => d.PlayerId === season.PlayerId
                    && d.Pos === season.Pos
                    && d.Starter === season.Starter
                    && d.TotalCategory
                    && (d.TotalCategory.includes(season.TotalCategory) && d.TotalCategory !== season.TotalCategory)
                        //|| (season.Season === '2020' &&  d.TotalCategory === 'scaleUp'))
                    && (cumulative === 1 || d.Season === season.Season)));
        return season;
    }

    function addScaleSeasonChildren(season) {
        if (!season['_children']) season['_children'] = [];
        season['_children'].push(...data
            .filter(
                d => d.PlayerId === season.PlayerId
                    && d.Pos === season.Pos
                    && d.Starter === season.Starter
                    && d.TotalCategory
                    && season.Season === '2020'
                    && d.TotalCategory === 'scaleUp'
                    && d.Team === season.Team
                    && (cumulative === 1 || d.Season === season.Season)));
        return season;
    }

    let multiTeamSeasons = data
        .filter(d => d.Team && d.Team.includes('Tms'))
        .map(d => addChildren(d));

    let nestedData = data.filter(d => !multiTeamSeasons.map(mts => mts['_children']).flat().includes(d))
    //nestedData.push(...multiTeamSeasons);

    if (!!~data.findIndex(d => d.Season === '2020')) {
        let nestedScaleSeasons = nestedData
            .filter(d => (d.TotalCategory !== null && !d.TotalCategory.includes('scaleUp')))
            .map(d => addScaleChildren(d))

        let scaleSeason = nestedData
            .filter(d => !d.TotalCategory && d.Season === '2020')
            .map(d => addScaleSeasonChildren(d));

        nestedData = nestedData.filter(d => 
            !nestedScaleSeasons.map(mts => mts['_children']).flat().includes(d)
            && !scaleSeason.map(ss => ss['_children']).flat().includes(d)
        )
    }

    return nestedData;
}

function nestContractYearRows(contractsRes) {
    let data = contractsRes.data;

    let contractIdColIndex = contractsRes.columns.find(column => column.displayName === 'ContractId').displayNumber;
    let seasonColIndex = contractsRes.columns.find(column => column.displayName === 'Season').displayNumber;

    function findMinSeasonRow(acc, cur) {
        return acc[seasonColIndex] < cur[seasonColIndex]
            ? acc
            : cur;
    }

    let minSeasonRows = [];
    data.forEach(row => {
        if (row.Season !== null) {
            if (minSeasonRows.every(minSeasonRow => minSeasonRow[contractIdColIndex] !== row[contractIdColIndex])) {
                let contractRows = data.filter(dataRow => dataRow[contractIdColIndex] === row[contractIdColIndex]);
                minSeasonRows.push(contractRows.reduce(findMinSeasonRow));
            }
        }
    });

    minSeasonRows.forEach(minSeasonRow => {
        minSeasonRow['_children'] = [];
        minSeasonRow['_children'].unshift(
            ...data
                .filter(row => row[contractIdColIndex] === minSeasonRow[contractIdColIndex]
                    && row[seasonColIndex] !== minSeasonRow[seasonColIndex])
        );
    });

    contractsRes.data = minSeasonRows;
    return contractsRes;
}

function nestScaleUpDataForQueryToolByPlayer(res, bySeason) {
    function getUniqKey(row) {
        if (!bySeason) {
            return row.PlayerId;
        }
        let seasonColIndex = res.columns.find(column => column.displayName === 'Platform Year Season').displayNumber;
        return `${row.PlayerId}-${row[seasonColIndex]}`;
    }

    let startTime = new Date();
    let returnRes = Object.assign({}, res);

    let dataObj = {};
    res.data.forEach(row => {
        let uniqKey = getUniqKey(row);
        let objRow = Object.assign({}, dataObj[uniqKey]);
        if (row.ScaleUp) {
            row.TotalCategory = 'scaleUp';
            objRow['_children'] = [row];
        } else {
            objRow = Object.assign(objRow, row);
        }
        dataObj[uniqKey] = objRow;
    });
    returnRes.data = Object.values(dataObj);
    console.log(`milliseconds to complete nesting: ${Date.now() - startTime}`);
    return returnRes;
}

function alignTimePeriodRows(data) {
    let abbreviations = {
        'Career to Date': 'CTD',
        'Platform Year - 1': 'PY-1',
        'Platform Year - 2': 'PY-2',
        'Platform Year - 3': 'PY-3',
        'Platform Year - 4': 'PY-4',
        'Platform Year + 1': 'PY+1',
        'Platform Year + 2': 'PY+2',
        'Platform Year + 3': 'PY+3',
        'Platform Year + 4': 'PY+4',
        'Last 2 Years': 'L2Y',
        'Last 3 Years': 'L3Y',
        'Last 4 Years': 'L4Y',
        'Next 2 Years': 'N2Y',
        'Next 3 Years': 'N3Y',
        'Next 4 Years': 'N4Y',
    }

    function alignTimePeriods(row) {
        Object.entries(abbreviations).forEach(abbr => {
            data
                .filter(
                    d => d.PlayerId === row.PlayerId
                        && d.Season === row.Season
                        && d.TotalCategory === abbr[0]
                )
                .forEach(r => {
                    Object.keys(r).forEach(k => {
                        row[k + '(' + abbr[1] + ')'] = r[k];
                    })
                })
        })
        return row;
    }

    data
        .filter(d => d.TotalCategory && d.TotalCategory === 'Platform Year')
        .map(d => alignTimePeriods(d));

    let retData = data.filter(d => d.TotalCategory === 'Platform Year');

    return retData;
}

function transformJsonToObjArray(json) {
    let objArray = [];
    json.forEach((jsonObj) => {
        let newObj = {};
        for (const [key, value] of Object.entries(jsonObj)) {
            newObj[key] = value;
        }
        objArray.push(newObj);
    });
    return objArray;
}

async function convertColumnsToQueryToolDisplayStats(columns, reportType) {
    const columnIds = columns.flatMap(column => column.id ?? column.columns.flatMap(col => col.id))

    let displayStats = columnIds.flatMap(columnId => {
        return compareReportTimePeriods.map(timePeriod => {
            return `${columnId} in ${timePeriod}`;
        });
    })
    return displayStats;
}

function convertColumnToColumnHeader(column) {
    let header = column.field;
    switch (column.statPosType) {
        case 'b':
            header += ' as Batter';
            break;
        case 'p':
            header += ' as Pitcher';
            break;
        case 'f':
            header += ' as Fielder';
        default:
            break;
    }
    return header;
}

function convertQueryToolResultToAwardsReportOutput(qtResult, columns, selectedAward) {
    let displayAward = selectedAward;

    let columnsByAwardMap = {
        mvpColumns: [],
        cyColumns: [],
        ggColumns: [],
        ssColumns: [],
        royColumns: [],
        lcsmvpColumns: [],
        wsmvpColumns: [],
        asmvpColumns: [],
        cpoyColumns: [],
        snpoyColumns: [],
        rrmColumns: [],
        asColumns: [],
        aseColumns: [],
        foyColumns: [],
        hofRivColumns: [],
        allMlbColumns: [],
        fbaColumns: [],
    }

    qtResult.columns.forEach(qtResultColumn => {
        if (!qtResultColumn.displayName.includes('(Award=')) {
            columnsByAwardMap.mvpColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.cyColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.ggColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.ssColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.royColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.lcsmvpColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.wsmvpColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.asmvpColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.cpoyColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.snpoyColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.rrmColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.asColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.aseColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.foyColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.hofRivColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.allMlbColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
            columnsByAwardMap.fbaColumns.push({
                displayNumber: qtResultColumn.displayNumber,
                displayName: qtResultColumn.displayName,
            });
        }

        if (qtResultColumn.displayName.includes('(Award=')) {
            for (var i = 0; i < awardsReportAwardNames.length; i++) {
                if (qtResultColumn.displayName.includes(awardsReportAwardNames[i])) {
                    let awardSplit = qtResultColumn.displayName.split(' where');
                    switch (awardsReportAwardNames[i]) {
                        case '(Award=MVP)':
                            columnsByAwardMap.mvpColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=Cy Young)':
                            columnsByAwardMap.cyColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=Gold Glove)':
                            columnsByAwardMap.ggColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=Silver Slugger)':
                            columnsByAwardMap.ssColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=ROY)':
                            columnsByAwardMap.royColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=LCS MVP)':
                            columnsByAwardMap.lcsmvpColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=WS MVP)':
                            columnsByAwardMap.wsmvpColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=AS MVP)':
                            columnsByAwardMap.asmvpColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=CPOY)':
                            columnsByAwardMap.cpoyColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=SNPOY)':
                            columnsByAwardMap.snpoyColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=RRM)':
                            columnsByAwardMap.rrmColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=AS)':
                            columnsByAwardMap.asColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=ASE)':
                            columnsByAwardMap.aseColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=FOY)':
                            columnsByAwardMap.foyColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=Hoffman/Rivera)':
                            columnsByAwardMap.hofRivColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=All-MLB TM)':
                            columnsByAwardMap.allMlbColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                        case '(Award=FBA)':
                            columnsByAwardMap.fbaColumns.push({
                                displayNumber: qtResultColumn.displayNumber,
                                displayName: awardSplit[0].toString(),
                            });
                            break;
                    }
                }
            }
        }
    });

    let displayNumberToNameMap = {};
    qtResult.columns.forEach(column => {
        displayNumberToNameMap[column.displayNumber] = column.displayName;
    });

    function getUniqueTeamKey(row) {
        return `${row.PlayerId}`;
    }
    let nestedData = nestData(
        qtResult.data,
        (row) => row.TeamId !== 0,
        getUniqueTeamKey
    );

    let columnDisplayNameMap = {};
    columns
        .filter(column => column.id < 10000)
        .map(column => {
            columnDisplayNameMap[`Platform Year ${convertColumnToColumnHeader(column)}`] = column;
        });

    let mvpData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.mvpColumns, columnDisplayNameMap));
    let cyData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.cyColumns, columnDisplayNameMap));
    let ggData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.ggColumns, columnDisplayNameMap));
    let ssData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.ssColumns, columnDisplayNameMap));
    let royData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.royColumns, columnDisplayNameMap));
    let lcsmvpData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.lcsmvpColumns, columnDisplayNameMap));
    let wsmvpData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.wsmvpColumns, columnDisplayNameMap));
    let asmvpData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.asmvpColumns, columnDisplayNameMap));
    let cpoyData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.cpoyColumns, columnDisplayNameMap));
    let snpoyData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.snpoyColumns, columnDisplayNameMap));
    let rrmData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.rrmColumns, columnDisplayNameMap));
    let asData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.asColumns, columnDisplayNameMap));
    let aseData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.aseColumns, columnDisplayNameMap));
    let foyData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.foyColumns, columnDisplayNameMap));
    let hofRivData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.hofRivColumns, columnDisplayNameMap));
    let allMlbData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.allMlbColumns, columnDisplayNameMap));
    let fbaData = nestedData.map(row => mapColumnNameToNumber(row, columnsByAwardMap.fbaColumns, columnDisplayNameMap));

    let allAwardsData = [];
    mvpData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    cyData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    ggData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    ssData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    royData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    lcsmvpData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    wsmvpData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    asmvpData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    cpoyData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    snpoyData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    rrmData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    asData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    aseData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    foyData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    hofRivData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    allMlbData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });
    fbaData.forEach(award => {
        let awardEntries = Object.entries(award);
        awardEntries.forEach(awardEntry => {
            if (awardEntry[0] === 'AwardVotes' && awardEntry[1] !== null) {
                allAwardsData.push(award);
            }
        });
    });

    return allAwardsData.flat();

    function mapColumnNameToNumber(row, columns, columnDisplayNameMap) {
        let retRow = {};
        columns.forEach(column => {
            let columnName = columnDisplayNameMap[column.displayName].field;
            retRow[columnName] = row[column.displayNumber];
        });
        if (row._children)
            retRow._children = row._children
                .map(childRow => mapColumnNameToNumber(childRow, columns, columnDisplayNameMap));
        if (row.PlayerId === -1)
            retRow.Player = 'Average';
        return retRow;
    }
}

function convertQueryToolResultToCompReportOutput(qtResult, columns, playerSeasons) {
    let platformYearSeasonColumn;
    let platformYearMinusOneSeasonColumn;
    let platformYearMinusTwoSeasonColumn;

    let columnsByTimePeriodMap = {
        platformYearColumns: [],
        platformYearMinusOneColumns: [],
        platformYearMinusTwoColumns: [],
        lastTwoYearsColumns: [],
        lastThreeYearsColumns: [],
        careerToDateColumns: [],
    };

    qtResult.columns.forEach(qtResultColumn => {
        const columnRegex =
            new RegExp(`(?<timePeriod>${compareReportTimePeriods.map(timePeriod => `(${timePeriod})`).join('|')
                }) (?<columnName>.+)`)
        const columnFound = qtResultColumn.displayName.match(columnRegex);
        if (columnFound == null)
            return; // Something went wrong

        const columnName = columnFound.groups.columnName;
        const columnTimePeriod = columnFound.groups.timePeriod;
        
        switch (columnTimePeriod) {
            case 'Platform Year':
                if (columnName === 'Season')
                    platformYearSeasonColumn = qtResultColumn.displayNumber.toString();

                columnsByTimePeriodMap.platformYearColumns.push({
                    displayNumber: qtResultColumn.displayNumber,
                    columnName: columnName,
                });
                break;
            case 'Platform Year - 1':
                if (columnName === 'Season')
                    platformYearMinusOneSeasonColumn = qtResultColumn.displayNumber.toString();

                columnsByTimePeriodMap.platformYearMinusOneColumns.push({
                    displayNumber: qtResultColumn.displayNumber,
                    columnName: columnName,
                });
                break;
            case 'Platform Year - 2':
                if (columnName === 'Season')
                    platformYearMinusTwoSeasonColumn = qtResultColumn.displayNumber.toString();

                columnsByTimePeriodMap.platformYearMinusTwoColumns.push({
                    displayNumber: qtResultColumn.displayNumber,
                    columnName: columnName,
                });
                break;
            case 'Last 2 Years':
                columnsByTimePeriodMap.lastTwoYearsColumns.push({
                    displayNumber: qtResultColumn.displayNumber,
                    columnName: columnName,
                });
                break;
            case 'Last 3 Years':
                columnsByTimePeriodMap.lastThreeYearsColumns.push({
                    displayNumber: qtResultColumn.displayNumber,
                    columnName: columnName,
                });
                break;
            case 'Career To Date':
                columnsByTimePeriodMap.careerToDateColumns.push({
                    displayNumber: qtResultColumn.displayNumber,
                    columnName: columnName,
                });
                break;
        }
    });

    let output = {
        playersData: {},
        platformYearData: [],
        platformYearMinusOneData: [],
        platformYearMinusTwoData: [],
        lastTwoYearsData: [],
        lastThreeYearsData: [],
        careerToDateData: [],
    };
    output.columns = columns.map(mapColumnHeaderToField)

    function getUniqueScaleUpKey(row) {
        return `${row.PlayerId}-${row[platformYearSeasonColumn]}`;
    }
    function getUniqueTeamKey(row) {
        return `${row.PlayerId}-${row[platformYearSeasonColumn]}-${row.ScaleUp}`;
    }
    let newData = nestData(
        nestData(
            qtResult.data,
            (row) => row.TeamId !== 0,
            getUniqueTeamKey
        ),
        (row) => row.ScaleUp === 1,
        getUniqueScaleUpKey
    );

    let playerSeasonDataMap = {};
    playerSeasons.forEach(playerSeason => {
        const playerSeasonKey = `${playerSeason.playerId}-${playerSeason.platformYear}`;
        if (!playerSeasonDataMap[playerSeason.playerId])
            playerSeasonDataMap[playerSeason.playerId] = {
                playerData: [],
            };

        playerSeasonDataMap[playerSeason.playerId][playerSeasonKey] = {
            platformYearRow: {},
            platformYearMinusOneRow: null,
            platformYearMinusTwoRow: null,
        };
    });

    let platformYearMinusOneKeys = {};
    let platformYearMinusTwoKeys = {};
    newData.forEach(row => {
        if (!playerSeasonDataMap[row.PlayerId])
            return;

        playerSeasonDataMap[row.PlayerId].playerData.push(Object.assign({}, row));
        const playerSeasonKey = `${row.PlayerId}-${row[platformYearSeasonColumn]}`;

        if (playerSeasonDataMap[row.PlayerId][playerSeasonKey]) {
            playerSeasonDataMap[row.PlayerId][playerSeasonKey].platformYearRow = Object.assign({}, row);
            if (row[platformYearMinusOneSeasonColumn])
                platformYearMinusOneKeys[`${row.PlayerId}-${row[platformYearMinusOneSeasonColumn]}`] =
                    playerSeasonKey;
            if (row[platformYearMinusTwoSeasonColumn])
                platformYearMinusTwoKeys[`${row.PlayerId}-${row[platformYearMinusTwoSeasonColumn]}`] =
                    playerSeasonKey;
        }
    });

    newData.forEach(row => {
        if (!playerSeasonDataMap[row.PlayerId])
            return;

        const playerSeasonKey = `${row.PlayerId}-${row[platformYearSeasonColumn]}`;
        if (platformYearMinusOneKeys[playerSeasonKey]) {
            playerSeasonDataMap[row.PlayerId][platformYearMinusOneKeys[playerSeasonKey]].platformYearMinusOneRow =
                Object.assign({}, row);
        }
        if (platformYearMinusTwoKeys[playerSeasonKey]) {
            playerSeasonDataMap[row.PlayerId][platformYearMinusTwoKeys[playerSeasonKey]].platformYearMinusTwoRow =
                Object.assign({}, row);
        }
    })

    playerSeasons.forEach(playerSeason => insertPlayerSeasonDataToOutput(playerSeason, newData));

    let officialOutput = Object.assign({}, output);
    officialOutput.playersData = Object.values(output.playersData);
    return officialOutput;

    function insertPlayerSeasonDataToOutput(playerSeason, data) {
        const playerSeasonKey = `${playerSeason.playerId}-${playerSeason.platformYear}`;
        let playerObject = Object.assign({}, playerSeasonDataMap[playerSeason.playerId.toString()]);
        let playerSeasonObject = Object.assign({}, playerObject[playerSeasonKey]);

        const playerData = playerObject.playerData
            .slice(0)
            .map(row => {
                return mapColumnNumbersToNames(row, columnsByTimePeriodMap.platformYearColumns);
            });
        output.playersData[playerSeasonKey] = playerData.filter(row => Number(row.Season) <= playerSeason.platformYear);

        output.platformYearData.push(
            mapColumnNumbersToNames(playerSeasonObject.platformYearRow, columnsByTimePeriodMap.platformYearColumns)
        );
        if (playerSeasonObject.platformYearMinusOneRow) {
            output.platformYearMinusOneData.push(
                mapColumnNumbersToNames(playerSeasonObject.platformYearMinusOneRow, columnsByTimePeriodMap.platformYearColumns)
            );
        }
        if (playerSeasonObject.platformYearMinusTwoRow) {
            output.platformYearMinusTwoData.push(
                mapColumnNumbersToNames(playerSeasonObject.platformYearMinusTwoRow, columnsByTimePeriodMap.platformYearColumns)
            );
        }
        output.lastTwoYearsData.push(
            mapColumnNumbersToNames(playerSeasonObject.platformYearRow, columnsByTimePeriodMap.lastTwoYearsColumns)
        );
        output.lastThreeYearsData.push(
            mapColumnNumbersToNames(playerSeasonObject.platformYearRow, columnsByTimePeriodMap.lastThreeYearsColumns)
        );
        const careerToDateData =
            mapColumnNumbersToNames(playerSeasonObject.platformYearRow, columnsByTimePeriodMap.careerToDateColumns);
        output.careerToDateData.push(careerToDateData)
        output.playersData[playerSeasonKey].push(labelCareerTotalsRow(careerToDateData));
    }

    function labelCareerTotalsRow(careerTotal) {
        let careerTotalRow = Object.assign({}, careerTotal);
        if (careerTotalRow._children) {
            careerTotalRow._children = careerTotalRow._children.map(childRow => labelCareerTotalsRow(childRow));
        }
        careerTotalRow.Player = 'Career Totals';
        return careerTotalRow;
    }

    function mapColumnHeaderToField(column) {
        const retColumn = Object.assign({}, column);
        if (column.columns) {
            retColumn.columns = column.columns.map(nestedColumn => mapColumnHeaderToField(nestedColumn))
        } else {
            retColumn.field = convertColumnToColumnHeader(column);
        }
        return retColumn;
    }

    function mapColumnNumbersToNames(row, columns) {
        let retRow = {
            scaleUp: row.ScaleUp,
            playerId: row.PlayerId,
        };

        let nestScaleUpChildren = false;
        let nestTeamChildren = false;
        columns.forEach(column => {
            retRow[column.columnName] = row[column.displayNumber];
            if (column.columnName === 'Season') {
                nestScaleUpChildren = shouldNestScaleUpChildren(row[column.displayNumber]);
                nestTeamChildren = !row[column.displayNumber].includes('-');
            }
        });

        if (row._children &&
            (
                nestScaleUpChildren
                || (nestTeamChildren && row._children.some(childRow => childRow.TeamId > 0 && childRow.ScaleUp === 0))
            )
        ) {
            if (!retRow._children)
                retRow._children = [];
            row._children
                .filter(childRow => childRow.ScaleUp === 1 && nestScaleUpChildren)
                .forEach(childRow => {
                    retRow._children.push(mapColumnNumbersToNames(childRow, columns));
                })
            row._children
                .filter(childRow => childRow.TeamId > 0 && childRow.ScaleUp === 0 && nestTeamChildren)
                .forEach(childRow => {
                    retRow._children.push(mapColumnNumbersToNames(childRow, columns));
                })
        }
        return retRow;

        function shouldNestScaleUpChildren(seasonValue) {
            if (seasonValue == null)
                return false

            if (!seasonValue.includes('-'))
                return scaleUpSeasons.map(season => season.toString()).includes(seasonValue);

            let twoDigitYears = {
                start: Number(seasonValue.substring(2, seasonValue.indexOf('-'))),
                end: Number(seasonValue.substring(seasonValue.indexOf('-') + 1))
            }
            let startSeason = Number(seasonValue.substring(0, 4));
            // In case the year range spans the turn of the century
            let endSeason = Number((
                twoDigitYears.end > twoDigitYears.start
                    ? seasonValue.substring(0, 2)
                    : (Number(seasonValue.substring(0, 2)) + 1).toString())
                + twoDigitYears.end.toString());

            return scaleUpSeasons.some(season => season >= startSeason && season <= endSeason);
        }
    }
}

function nestData(data, shouldNest, getUniqueKey) {
    let dataDictionary = {};
    let dataToNest = [];
    data
        .forEach(row => {
            if (shouldNest(row)) {
                dataToNest.push(row);
            } else {
                dataDictionary[getUniqueKey(row)] = row;
            }
        });
    dataToNest.forEach(childRow => {
        if (!dataDictionary[getUniqueKey(childRow)]['_children'])
            dataDictionary[getUniqueKey(childRow)]['_children'] = [];
        dataDictionary[getUniqueKey(childRow)]['_children'].push(childRow);
    })

    return Object.values(dataDictionary);
}

function generateFilterTextFromFilterInput(filterInput) {
    let filterText = [];

    if (filterInput.value == null)
        return filterText;

    switch (filterInput.formInputs.inputType) {
        case 'Number':
        case 'Date':
        case 'DateRange':
            if (filterInput.value.lowerBound != null)
                filterText.push(`${filterInput.statId}>=${filterInput.value?.lowerBound}`);
            if (filterInput.value.upperBound != null)
                filterText.push(`${filterInput.statId}<=${filterInput.value?.upperBound}`);
            break;
        case 'MultiSelect':
            if (filterInput.value !== filterInput.formInputs.selectOptions.map(option => option.value).join(','))
                filterText.push(`${filterInput.statId}=${filterInput.value}`);
            break;
    }

    return filterText;
}

export function GetPlayers() {
    let players = [];
    players = fetch(`${process.env.REACT_APP_BASE_URL}/compreport/players`)
        .then(response => response.json())
        .then((data) => {
            let playerNames = new Map();
            let dupNameIndices = new Set();
            const players = data.data.map((p, index) => {
                let hasDuplicateName = playerNames.has(`${p.commonName} ${p.lastName}`);
                if (hasDuplicateName) {
                    dupNameIndices.add(playerNames.get(`${p.commonName} ${p.lastName}`));
                }
                playerNames.set(`${p.commonName} ${p.lastName}`, index);
                return {
                    name: p.commonName + ' ' + p.lastName,
                    id: p.playerId,
                    posFlag: p.posFlag,
                    minSeason: p.minSeason,
                    maxSeason: p.maxSeason,
                    dob: (new Date(p.dob)).toLocaleDateString(),
                    hasDupName: hasDuplicateName
                }
            });
            for (let dupNameIndex of dupNameIndices) {
                players[dupNameIndex].hasDupName = true;
            }
            return players;
        });
    return players;
}

export async function GetQualifierThresholds() {
    let response = await fetch(`${process.env.REACT_APP_BASE_URL}/compreport/qualifierThresholds`);
    let resJson = await response.json();
    return transformJsonToObjArray(resJson);
}

export async function GetStatTabProps() {
    let response = await fetch(`${process.env.REACT_APP_BASE_URL}/compreport/statTabulatorProps`);
    let resJson = await response.json();
    return transformJsonToObjArray(resJson);
}

export async function GetColumnGroups() {
    let response = await fetch(`${process.env.REACT_APP_BASE_URL}/compreport/colGroups`);
    let resJson = await response.json();
    return transformJsonToObjArray(resJson);
}

export async function GetStatFormInputs() {
    let response = await fetch(`${process.env.REACT_APP_BASE_URL}/querytool/statFormInputs`);
    let resJson = await response.json();
    return transformJsonToObjArray(resJson);
}

export async function GetAwardsData(formOptions, columns) {
    let startSeason = Number(formOptions.startSeason);
    let endSeason = Number(formOptions.endSeason);

    const queryToolReqBody = {
        seasons: null,
        playerCriteria: null,
        numericalFilters: null,
        displayStats: null,
        consecutiveYears: 0,
        throughX: null,
        dateRanges: null,
        playerSeasons: null,
        scaleUpFilters: false,
        scaleUpDisplayStats: 0,
        includeAverage: false,
        cumulative: false,
        reportType: 8,
        positions: null,
    };

    let awards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 12, 15, 18, 19, 20, 21, 22, 30];
    let displayStats = [];
    columns.forEach(column => {
        if (column.id !== 617 && column.id !== 618 && column.id !== 619 && column.id !== 769 && column.id !== 770 && column.id !== 771) {
            displayStats.push(`${column.id} in Platform Year`);
        }
        else {
            if (formOptions.award == 0) {
                for (var i = 0; i < awards.length; i++) {
                    displayStats.push(`${column.id} in Platform Year ISW (591=${awards[i]})`);
                }
            }
            else {
                displayStats.push(`${column.id} in Platform Year ISW (591=${formOptions.award})`);
            }
        }
    });
    queryToolReqBody.displayStats = displayStats.join(' && ');

    let seasons = [];
    let playerSeasons = [];
    for (var curSeason = startSeason; curSeason <= endSeason; curSeason++) {
        seasons.push(curSeason);
        if (formOptions.player.playerId != null) {
            playerSeasons.push(`${formOptions.player.playerId}-${curSeason}`);
        }
    }
    queryToolReqBody.seasons = seasons.join(',');
    queryToolReqBody.playerSeasons = playerSeasons.join('&&');

    let numericalCriteria = [];
    numericalCriteria.push(`618>0 in Platform Year`);
    if (formOptions.pos > 0)
        numericalCriteria.push(`770=${formOptions.pos} in Platform Year`);
    queryToolReqBody.numericalFilters = numericalCriteria.join(' && ');

    let userCookie = JSON.parse(localStorage.getItem('user'));
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/querytool/players`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + userCookie.authdata,
        },
        body: JSON.stringify(queryToolReqBody),
    });
    let qtJson = await res.json();

    if (qtJson.exceptionMsg)
        return { exceptionMsg: qtJson.exceptionMsg };
    if (qtJson.errors)
        return { exceptionMsg: `There was a problem with your query. TraceId: ${qtJson.traceId ?? 'Unknown'}` };

    return convertQueryToolResultToAwardsReportOutput(qtJson, columns, formOptions.award);
}

export function GetCompReportPitcherData(id, platformSeason) {
    let data = [];
    data = fetch(`${process.env.REACT_APP_BASE_URL}/compreport/pitcher?playerId=${id}&platformSeason=${platformSeason}`)
        .then(response => response.json())
        .then((json) => {
            const data = json.data.map((p) => {
                return {
                    Player: p.player,
                    PlayerId: p.playerId,
                    BRefId: p.bRefId,
                    StatsId: p.statsId,
                    MLBDotComId: p.mlbDotComId,
                    Season: p.season,
                    TotalCategory: p.totalCategory,
                    Team: p.team,
                    MLS: p.mls,
                    Starter: p.starter,
                    Age: p.age,
                    IL: p.il,
                    Pitches: p.pitches,
                    pPA: p.pa,
                    pBIP: p.bip,
                    pBBE: p.bbe,
                    pLA: p.la,
                    pSwSp: p.swSp,
                    TEV: p.tev,
                    pMaxEV: p.maxEV,
                    pAvgEV: p.avgEV,
                    pFBLDEV: p.fbldev,
                    pGBEV: p.gbev,
                    TDist: p.tDist,
                    pMaxDist: p.maxDist,
                    pAvgDist: p.avgDist,
                    pAvgHRDist: p.avgHRDist,
                    pHardHitTotal: p.hardHitTotal,
                    pHardHitPercent: p.hardHitPercent,
                    pBarrels: p.barrels,
                    pBarrelPerBBE: p.barrelPerBBE,
                    pBarrelPerPA: p.barrelPerPA,
                    pStrikeOutPercentage: p.strikeOutPercentage,
                    pWalkPercentage: p.walkPercentage,
                    pHomeRunPercentage: p.homeRunPercentage,
                    SwingStrikePercent: p.swingStrikePercent,
                    pxAVG: p.xAVG,
                    pxSLG: p.xSLG,
                    pwOBA: p.wOBA,
                    pxwOBA: p.xwOBA,
                    ffSpin: p.fbSpin,
                    siSpin: p.siSpin,
                    slSpin: p.slSpin,
                    chSpin: p.chSpin,
                    cuSpin: p.cbSpin,
                    ffMPH: p.fbVelo,
                    siMPH: p.siVelo,
                    slMPH: p.slVelo,
                    chMPH: p.chVelo,
                    cuMPH: p.cbVelo,
                    pG: p.g,
                    pGS: p.gs,
                    InnPitched: p.innPitched,
                    InnPPerGS: p.innPPerGS,
                    W: p.wins,
                    L: p.losses,
                    QS: p.qs,
                    ERA: p.era,
                    FIP: p.fip,
                    xFIP: p.xFIP,
                    ERAPlus: p.eraPlus,
                    ERC: p.erc,
                    pStrikeouts: p.strikeouts,
                    pWalks: p.walks,
                    WHIP: p.whip,
                    pBatAvg: p.batAvg,
                    pOPS: p.ops,
                    WS: p.ws,
                    rWAR: p.rWAR,
                    fWAR: p.fWAR,
                    Saves: p.saves,
                    BlownSaves: p.blownSaves,
                    SavePercent: p.savePercent,
                    Holds: p.holds,
                    pLI: p.pLI,
                    gmLI: p.gmLI,
                    LC: p.lc,
                    LCPercent: p.lcPercent,
                    Awards: p.awards,
                    YSNext: p.ysNext,
                    AAVNext: p.aavNext,
                    TimesElig: p.timesElig,
                    YS: p.ys,
                    pWAR: p.pWAR,
                    SIERA: p.siera,
                    ERAMinus: p.eraMinus,
                    FIPMinus: p.fipMinus,
                    cFIP: p.cFIP,
                    DRA: p.dra,
                    pBABIP: p.babip,
                    StrikeoutsPerNine: p.kPerNine,
                    WalksPerNine: p.bbPerNine,
                    StrikeoutsPerWalk: p.kPerBB,
                    HRPerNine: p.hrPerNine,
                    pOBP: p.obp,
                    pSLG: p.slg,
                    LOBPercentage: p.lobPercentage,
                    pGBPercentage: p.gbPercentage,
                    pFBPercentage: p.fbPercentage,
                    pLDPercentage: p.ldPercentage,
                    GBPerFB: p.gbPerFB,
                    pHRPerFB: p.hrPerFB,
                    SoftPercentage: p.softPercentage,
                    MedPercentage: p.medPercentage,
                    HardPercentage: p.hardPercentage,
                    QSPercentage: p.qsPercentage,
                    CG: p.cg,
                    CGSO: p.cgso,
                    GF: p.gf,
                    BF: p.bf,
                    pH: p.h,
                    pR: p.r,
                    ER: p.er,
                    pHR: p.hr,
                    RAPP: p.rapp,
                    WPA: p.wpa,
                    exLI: p.exLI,
                    SD: p.sd,
                    MD: p.md,
                    IR: p.ir,
                    IRS: p.irs,
                    IRSPercentage: p.irsPercentage,
                    EasySV: p.easySV,
                    RegSV: p.regSV,
                    ToughSV: p.toughSV,
                    LevHi: p.levHi,
                    LevMd: p.levMd,
                    LevLo: p.levLo,
                    IPMulti: p.ipMulti,
                    ThreeOutsPlus: p.threeOutsPlus,
                    ffPitches: p.fF_Pitches,
                    ffPercentage: p.fF_Percent,
                    ffExVelo: p.fF_ExVelo,
                    ffLA: p.fF_LA,
                    ffWhiff: p.fF_Whiff,
                    ffPutAway: p.fF_PutAway,
                    siPitches: p.sI_Pitches,
                    siPercentage: p.sI_Percent,
                    siExVelo: p.sI_ExVelo,
                    siLA: p.sI_LA,
                    siWhiff: p.sI_Whiff,
                    siPutAway: p.sI_PutAway,
                    slPitches: p.sL_Pitches,
                    slPercentage: p.sL_Percent,
                    slExVelo: p.sL_ExVelo,
                    slLA: p.sL_LA,
                    slWhiff: p.sL_Whiff,
                    slPutAway: p.sL_PutAway,
                    fcPitches: p.fC_Pitches,
                    fcPercentage: p.fC_Percent,
                    fcMPH: p.fC_MPH,
                    fcSpin: p.fC_Spin,
                    fcExVelo: p.fC_ExVelo,
                    fcLA: p.fC_LA,
                    fcWhiff: p.fC_Whiff,
                    fcPutAway: p.fC_PutAway,
                    cuPitches: p.cU_Pitches,
                    cuPercentage: p.cU_Percent,
                    cuExVelo: p.cU_ExVelo,
                    cuLA: p.cU_LA,
                    cuWhiff: p.cU_Whiff,
                    cuPutAway: p.cU_PutAway,
                    chPitches: p.cH_Pitches,
                    chPercentage: p.cH_Percent,
                    chExVelo: p.cH_ExVelo,
                    chLA: p.cH_LA,
                    chWhiff: p.cH_Whiff,
                    chPutAway: p.cH_PutAway
                }
            });
            return nestChildRows(data);
        });
    return data;
}

export function GetCompReportBatterData(id, platformSeason) {
    let data = [];
    data = fetch(`${process.env.REACT_APP_BASE_URL}/compreport/batter?playerId=${id}&platformSeason=${platformSeason}`)
        .then(response => response.json())
        .then((json) => {
            const data = json.data.map((p) => {
                return {
                    Player: p.player,
                    PlayerId: p.playerId,
                    BRefId: p.bRefId,
                    StatsId: p.statsId,
                    MLBDotComId: p.mlbDotComId,
                    Season: p.season,
                    TotalCategory: p.totalCategory,
                    Team: p.team,
                    Age: p.age,
                    Pos: p.pos,
                    MLS: p.mls,
                    IL: p.il,
                    PA: p.pa,
                    BBE: p.bbe,
                    LA: p.la,
                    SwSp: p.swSp,
                    TEV: p.tev,
                    MaxEV: p.maxEV,
                    AvgEV: p.avgEV,
                    FBLDEV: p.fbldev,
                    GBEV: p.gbev,
                    TDist: p.tDist,
                    MaxDist: p.maxDist,
                    AvgDist: p.avgDist,
                    AvgHRDist: p.avgHRDist,
                    HardHitTotal: p.hardHitTotal,
                    HardHitPercent: p.hardHitPercent,
                    Barrels: p.barrels,
                    BarrelPerBBE: p.barrelPerBBE,
                    BarrelPerPA: p.barrelPerPA,
                    StrikeOutPercentage: p.strikeOutPercentage,
                    WalkPercentage: p.walkPercentage,
                    xAVG: p.xAVG,
                    xSLG: p.xSLG,
                    wOBA: p.wOBA,
                    xwOBA: p.xwOBA,
                    SprintSpeed: p.sprintSpeed,
                    HPToFirst: p.hpToFirst,
                    sDRS: p.sDRS,
                    DRSPre: p.drsPre,
                    OAA: p.oaa,
                    G: p.g,
                    GS: p.gs,
                    R: p.r,
                    HR: p.hr,
                    RBI: p.rbi,
                    Walks: p.bb,
                    Strikeouts: p.k,
                    SB: p.sb,
                    CS: p.cs,
                    BatAvg: p.batAvg,
                    OBP: p.obp,
                    SLG: p.slg,
                    OPS: p.ops,
                    ISO: p.iso,
                    ABPerHR: p.abPerHR,
                    WS: p.ws,
                    rWAR: p.rWAR,
                    fWAR: p.fWAR,
                    wRCPlus: p.wRCPlus,
                    DRS: p.drs,
                    UZR: p.uzr,
                    Awards: p.awards,
                    YSNext: p.ysNext,
                    AAVNext: p.aavNext,
                    TimesElig: p.timesElig,
                    YS: p.ys,
                    CCSPercent: p.ccsPercent,
                    ExSt: p.exSt,
                    cERA: p.cERA,
                    cGm: p.cGm,
                    cGS: p.cGS,
                    Inn: p.inn,
                    cPA: p.cPA,
                    cR: p.cR,
                    cHR: p.cHR,
                    cRBI: p.cRBI,
                    cBB: p.cBB,
                    cK: p.cK,
                    cSB: p.cSB,
                    cCS: p.cCS,
                    cAVG: p.cAVG,
                    cOBP: p.cOBP,
                    cSLG: p.cSLG,
                    cOPS: p.cOPS,
                    ThrowArm: p.throwArm,
                    ThrowExchange: p.throwExchange,
                    SecondPopAtt: p.secondPopAtt,
                    SecondPopAll: p.secondPopAll,
                    SecondPopCS: p.secondPopCS,
                    SecondPopSB: p.secondPopSB,
                    ThirdPopAtt: p.thirdPopAtt,
                    ThirdPopAll: p.thirdPopAll,
                    ThirdPopCS: p.thirdPopCS,
                    ThirdPopSB: p.thirdPopSB,
                    FramePitches: p.framePitches,
                    FrameRunsExtraStrikes: p.frameRunsExtraStrikes,
                    FrameStrikeRate: p.frameStrikeRate,
                    AB: p.ab,
                    H: p.h,
                    Singles: p.singles,
                    Doubles: p.doubles,
                    Triples: p.triples,
                    XBH: p.xbh,
                    TB: p.tb,
                    GOARBI: p.goarbi,
                    PAPerRBI: p.paPerRBI,
                    RPlusRBI: p.rPlusRBI,
                    BABIP: p.babip,
                    pWAR: p.pWAR,
                    RC: p.rc,
                    OPSPlus: p.opsPlus,
                    DRCPlus: p.drcPlus,
                    HomeRunPercentage: p.hrPercentage,
                    LDPercentage: p.ldPercentage,
                    GBPercentage: p.gbPercentage,
                    FBPercentage: p.fbPercentage,
                    HRPerFB: p.hrPerFB,
                    fCG: p.compG,
                    A: p.assists,
                    E: p.errors,
                    FieldPercentage: p.fieldPercentage,
                    RF: p.rf,
                    GFP: p.gfp,
                    DM: p.dm,
                    cDRS: p.cDRS,
                    csDRS: p.csDRS,
                    cDRSPre: p.cDRSPre,
                    BatCompG: p.batCompG,
                    LfWAR: p.lfWAR,
                }
            });
            return nestChildRows(data);
        });
    return data;
}


export async function GetCompReportData(playerSeasons, columns, position) {
    let displayStats = await convertColumnsToQueryToolDisplayStats(columns, 'CompReport');

    let userCookie = JSON.parse(localStorage.getItem('user'));
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/querytool/players`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + userCookie.authdata,
        },
        body: JSON.stringify({
            seasons: null,
            playerCriteria: null,
            numericalFilters: null,
            displayStats: displayStats.join('&&'),
            consecutiveYears: 0,
            throughX: null,
            dateRanges: null,
            playerSeasons: playerSeasons.map(player => `${player.playerId}-${player.platformYear}`).join('&&'),
            scaleUpFilters: false,
            scaleUpDisplayStats: 2,
            reportType: 3,
            positions: position.toString(),
        }),
    });
    let qtJson = await res.json();

    if (qtJson.exceptionMsg)
        return { exceptionMsg: qtJson.exceptionMsg };
    if (qtJson.errors)
        return { exceptionMsg: `There was a problem with your query. TraceId: ${qtJson.traceId ?? 'Unknown'}` };

    return convertQueryToolResultToCompReportOutput(qtJson, columns, playerSeasons);
}

export async function GetLargestContractsData(formOptions, columns) {
    const queryToolReqBody = {
        seasons: formOptions.season.toString(),
        playerCriteria: null,
        numericalFilters: null,
        displayStats: null,
        consecutiveYears: 0,
        throughX: null,
        dateRanges: null,
        playerSeasons: null,
        scaleUpFilters: false,
        scaleUpDisplayStats: 0,
        includeAverage: false,
        cumulative: false,
        reportType: 7,
        teams: formOptions.teams.length > 1 || (formOptions.teams.length > 0 && formOptions.teams[0] > 0) ? formOptions.teams.join(',') : null,
        positions: null,
    };

    let numericalCriteria = [];
    if (formOptions.pos.length > 1 || formOptions.pos != 0)
        numericalCriteria.push(`612=${formOptions.pos} in Platform Year`);
    if (formOptions.ageMin > 15)
        numericalCriteria.push(`764>=${formOptions.ageMin} in Platform Year`);
    if (formOptions.ageMax < 50)
        numericalCriteria.push(`765<=${formOptions.ageMax} in Platform Year`);
    if (formOptions.signingTeams.length > 1 || (formOptions.signingTeams.length > 0 && formOptions.signingTeams[0] > 0)) {
        let signingTeams = formOptions.signingTeams.join(',');
        numericalCriteria.push(`516=${signingTeams} in Platform Year`);
    }

    queryToolReqBody.numericalFilters = numericalCriteria.join(' && ');

    let columnDisplayNameMap = {};
    queryToolReqBody.displayStats = columns
        .filter(column => column.id < 10000)
        .map(column => {
            let queryName = `${column.id} in Platform Year`;
            columnDisplayNameMap[`Platform Year ${convertColumnToColumnHeader(column)}`] = column;
            return queryName;
        })
        .join(' && ');

    let userCookie = JSON.parse(localStorage.getItem('user'));
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/querytool/players`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + userCookie.authdata,
        },
        body: JSON.stringify(queryToolReqBody),
    });
    let qtJson = await res.json();

    if (qtJson.exceptionMsg)
        return { exceptionMsg: qtJson.exceptionMsg };
    if (qtJson.errors)
        return { exceptionMsg: `There was a problem with your query. TraceId: ${qtJson.traceId ?? 'Unknown'}` };

    let displayNumberToNameMap = {};
    qtJson.columns.forEach(column => {
        displayNumberToNameMap[column.displayNumber] = column.displayName;
    });

    function getUniqueTeamKey(row) {
        return `${row.PlayerId}`;
    }

    let nestedData = nestData(
        qtJson.data,
        (row) => row.TeamId !== 0,
        getUniqueTeamKey
    );

    let retData = nestedData.map(row => mapColumnNameToNumber(row, qtJson.columns, columnDisplayNameMap));

    return retData;

    function mapColumnNameToNumber(row, columns, columnDisplayNameMap) {
        let retRow = {};
        columns.forEach(column => {
            let columnName = columnDisplayNameMap[column.displayName].field;
            retRow[columnName] = row[column.displayNumber];
        });
        if (row._children)
            retRow._children = row._children
                .map(childRow => mapColumnNameToNumber(childRow, columns, columnDisplayNameMap));
        if (row.PlayerId === -1)
            retRow.Player = 'Average';
        return retRow;
    }
}

export async function GetLeaderboardsData(formOptions, columns) {


    let season = Number(formOptions.season);
    let startSeason = Number(formOptions.startSeason);
    let endSeason = Number(formOptions.endSeason);
    let startDate = formOptions.startDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' });
    let endDate = formOptions.endDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' });
    let leagues = formOptions.tabName === 'mlb' ? 1 : 0;
    let teams = formOptions.tabName === 'teams' ? 1 : 0;
    let mode = formOptions.mode;
    let leagueId = formOptions.league;
    let teamIds = formOptions.teams;
    let position = formOptions.pos;
    let starterSub = formOptions.starterSub;
    let ageMin = formOptions.ageMin;
    let ageMax = formOptions.ageMax;
    let filter = formOptions.filterObj;
    let withAverage = formOptions.withAverage
    let scaleUp = formOptions.scaleUp;

    const queryToolReqBody = {
        seasons: null,
        playerCriteria: null,
        numericalFilters: null,
        displayStats: null,
        consecutiveYears: 0,
        throughX: null,
        dateRanges: null,
        playerSeasons: null,
        scaleUpFilters: scaleUp ? true : false,
        scaleUpDisplayStats: scaleUp ? 1 : 0,
        reportType: leagues ? 6 : teams ? 5 : 4,
        includeAverage: withAverage,
        teams: teamIds.length > 1 || (teamIds.length > 0 && teamIds[0] > 0) ? teamIds.join(',') : null,
        leagues: leagueId > 0 ? leagueId.toString() : null,
        positions: null,
    }

    let timePeriod = 'Platform Year';
    switch (mode) {
        case 'date':
            queryToolReqBody.dateRanges = `${startDate}-${endDate}`;
            timePeriod = 'Career To Date';
            break;
        case 'single':
            queryToolReqBody.seasons = season.toString();
            break;
        case 'cumulative':
            let seasonsCount = 1 + endSeason - startSeason;
            if (seasonsCount > 1)
                timePeriod = `Last ${seasonsCount} Years`;
            queryToolReqBody.seasons = endSeason.toString();
            break;
        case 'separate':
            let seasons = [];
            for (var curSeason = startSeason; curSeason <= endSeason; curSeason++) {
                seasons.push(curSeason);
            }
            queryToolReqBody.seasons = seasons.join(',');
            break;
        default:
            break;
    }

    let numericalCriteria = [];
    if (formOptions.category !== 'pitching')
        numericalCriteria.push(`612=${position} in ${timePeriod}`);
    numericalCriteria.push(`764>=${ageMin} in ${timePeriod}`);
    numericalCriteria.push(`765<=${ageMax} in ${timePeriod}`);
    if (filter) {
        const statLookup = {
            bG: 45,
            bGS: 46,
            fG: 215,
            fGS: 216,
            HLD: 67,
            IP: 47,
            Inn: 119,
            PA: 8,
            pG: 228,
            pGS: 229,
            RAPP: 280,
            SV: 64
        }
        let filterNumericalCriteria = `${statLookup[filter.stat]}${filter.operator}${filter.value}`
        numericalCriteria.push(`${filterNumericalCriteria} in ${timePeriod}${starterSub > -1
            ? ` IGW (${formOptions.category === 'pitching'
                ? statLookup.pGS
                : formOptions.category === 'batting' 
                    ? statLookup.bGS
                    : statLookup.fGS
                }=${starterSub})`
            : ''
        }`)
    }
    queryToolReqBody.numericalFilters = numericalCriteria.join(' && ');

    let columnDisplayNameMap = {};

    queryToolReqBody.displayStats = columns
        .filter(column => {
            return column.id < 10000 &&
                (mode !== 'date' || ![4, 5, 6].includes(column.formInputs.granularity)) &&
                (starterSub === -1 || column.formInputs.granularity < 3)
        })
        .map(column => {
            let starterSubFilter = `(46=${starterSub}|229=${starterSub})`;
            let useStarterSubFilter = starterSub > -1 && ![1, 2, 3].includes(column.id);

            let queryName =
                `${column.id} in ${timePeriod}${
                    useStarterSubFilter
                        ? ` IGW ${starterSubFilter}`
                        : ''
                }`;
            let columnDisplayName = `${timePeriod} ${convertColumnToColumnHeader(column)}${
                useStarterSubFilter
                ? ` in games where (GS=${starterSub} or pGS=${starterSub})`
                : ''
                }`;

            columnDisplayNameMap[columnDisplayName] = column;
            return queryName;
        })
        .join('&&');

    let userCookie = JSON.parse(localStorage.getItem('user'));
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/querytool/players`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + userCookie.authdata,
        },
        body: JSON.stringify(queryToolReqBody),
    });
    let qtJson = await res.json();

    if (qtJson.exceptionMsg)
        return { exceptionMsg: qtJson.exceptionMsg };
    if (qtJson.errors)
        return { exceptionMsg: `There was a problem with your query. TraceId: ${qtJson.traceId ?? 'Unknown'}` };

    const platformYearSeasonColumn = qtJson.columns
        .find(column => column.displayName === `${timePeriod} Season`).displayNumber.toString();

    function getUniqueKey(row) {
        return `${
            leagues
                ? row.LeagueId
                : teams
                    ? row.TeamId
                    : row.PlayerId
            }${!['cumulative', 'date'].includes(mode) ? '-' + row[platformYearSeasonColumn] : ''}`;
    }

    const nestedData = !teams && !leagues
        ? nestData(
            qtJson.data,
            (row) => row.TeamId !== 0,
            getUniqueKey
        )
        : qtJson.data;

    let displayNumberToNameMap = {};
    qtJson.columns.forEach(column => {
        displayNumberToNameMap[column.displayNumber] = column.displayName;
    });

    let retData = nestedData.map(row => mapColumnNameToNumber(row, qtJson.columns, columnDisplayNameMap));

    return retData;

    function mapColumnNameToNumber(row, columns, columnDisplayNameMap) {
        let retRow = {};
        columns.forEach(column => {
            let columnName = columnDisplayNameMap[column.displayName].field;
            retRow[columnName] = row[column.displayNumber];
        });
        if (row._children)
            retRow._children = row._children
                .map(childRow => mapColumnNameToNumber(childRow, columns, columnDisplayNameMap));
        if (row.PlayerId === -1)
            retRow.Player = 'Average';
        return retRow;
    }
}

export function GetRankReportsBatters(season, startSeason, endSeason, startDate, endDate, mode, starterSub, percentile, filters, playerId) {
    let filterPA = filters.valuePA || 0;
    let opPA = filters.opPA || '>=';
    let filterG = filters.valueG || 0;
    let opG = filters.opG || '>=';
    let filterGS = filters.valueGS || 0;
    let opGS = filters.opGS || '>=';

    let cumulative = ['date', 'cumulative'].includes(mode) ? 1 : 0;
    let startTime = new Date(startDate).getTime();
    let endTime = new Date(endDate).getTime();
    let url = '';
    if (mode === 'date') {
        url = `${process.env.REACT_APP_BASE_URL}/rankreports/batters?cumulative=${cumulative}&startTime=${startTime}&endTime=${endTime}`;
    }
    else if (mode === 'single') {
        url = `${process.env.REACT_APP_BASE_URL}/rankreports/batters?cumulative=${cumulative}&startSeason=${season}&endSeason=${season}`;
    }
    else {
        url = `${process.env.REACT_APP_BASE_URL}/rankreports/batters?cumulative=${cumulative}&startSeason=${startSeason}&endSeason=${endSeason}`;
    }
    url += `&filterPA=${filterPA}&opPA=${opPA}&filterG=${filterG}&opG=${opG}&filterGS=${filterGS}&opGS=${opGS}`;
    url += `&starterSub=${starterSub}&percentile=${percentile}&playerId=${playerId}`;
    let data = [];
    data = fetch(url)
        .then(response => response.json())
        .then((json) => {
            const data = json.data.map((p) => {
                return {
                    Player: p.player,
                    PlayerId: p.playerId,
                    Season: p.startSeason === p.endSeason ? p.startSeason : p.startSeason + '-' + p.endSeason.toString().substring(2, 4),
                    League: p.league,
                    PlayerCount: 'out of ' + p.playerCount,
                    Team: p.team,
                    Age: p.age,
                    G: p.g,
                    GS: p.gs,
                    PA: p.pa,
                    R: p.r,
                    HR: p.hr,
                    RBI: p.rbi,
                    WalkPercentage: p.bbPercentage,
                    StrikeOutPercentage: p.kPercentage,
                    SB: p.sb,
                    BatAvg: p.batAvg,
                    OBP: p.obp,
                    SLG: p.slg,
                    OPS: p.ops,
                    ISO: p.iso,
                    BABIP: p.babip,
                    wOBA: p.wOBA,
                    wRCPlus: p.wRCPlus,
                    rWAR: p.rWAR,
                    fWAR: p.fWAR,
                    AB: p.ab,
                    H: p.h,
                    Singles: p.singles,
                    Doubles: p.doubles,
                    Triples: p.triples,
                    XBH: p.xbh,
                    TB: p.tb,
                    GOARBI: p.goarbi,
                    PAPerRBI: p.paPerRBI,
                    RPlusRBI: p.rPlusRBI,
                    LOB: p.lob,
                    GIDP: p.gidp,
                    SF: p.sf,
                    SH: p.sh,
                    Strikeouts: p.k,
                    Walks: p.bb,
                    IBB: p.ibb,
                    HBP: p.hbp,
                    CS: p.cs,
                    WS: p.ws,
                    pWAR: p.pWAR,
                    WPA: p.wpa,
                    RC: p.rc,
                    OPSPlus: p.opsPlus,
                    DRCPlus: p.drcPlus,
                    ABPerHR: p.abPerHR,
                    HomeRunPercentage: p.hrPercentage,
                    LDPercentage: p.ldPercentage,
                    GBPercentage: p.gbPercentage,
                    FBPercentage: p.fbPercentage,
                    HRPerFB: p.hrPerFB,
                    BBE: p.bbe,
                    LA: p.la,
                    SwSp: p.swSp,
                    MaxEV: p.maxEV,
                    AvgEV: p.avgEV,
                    FBLDEV: p.fbldev,
                    GBEV: p.gbev,
                    MaxDist: p.maxDist,
                    AvgDist: p.avgDist,
                    AvgHRDist: p.avgHrDist,
                    HardHitTotal: p.hardHitTotal,
                    HardHitPercent: p.hardHitPercent,
                    Barrels: p.barrels,
                    BarrelPerBBE: p.barrelsPerBBE,
                    BarrelPerPA: p.barrelsPerPA,
                    xAVG: p.xAVG,
                    xSLG: p.xSLG,
                    xwOBA: p.xwOBA,
                    SprintSpeed: p.sprint,
                    HPToFirst: p.hpToFirst,
                    DRS: p.drs,
                    UZR: p.uzr,
                    sDRS: p.sDRS,
                    LfWAR: p.lfWAR,
                }
            });
            return data;
        });
    return data;
}

export function GetRankReportsFielders(season, startSeason, endSeason, startDate, endDate, mode, starterSub, percentile, filters, playerId) {
    let filterInn = filters.valueInn || 0;
    let opInn = filters.opInn || '>=';
    let filterG = filters.valueG || 0;
    let opG = filters.opG || '>=';
    let filterGS = filters.valueGS || 0;
    let opGS = filters.opGS || '>=';

    let cumulative = ['date', 'cumulative'].includes(mode) ? 1 : 0;
    let startTime = new Date(startDate).getTime();
    let endTime = new Date(endDate).getTime();
    let url = '';
    if (mode === 'date') {
        url = `${process.env.REACT_APP_BASE_URL}/rankreports/fielders?cumulative=${cumulative}&startTime=${startTime}&endTime=${endTime}`;
    }
    else if (mode === 'single') {
        url = `${process.env.REACT_APP_BASE_URL}/rankreports/fielders?cumulative=${cumulative}&startSeason=${season}&endSeason=${season}`;
    }
    else {
        url = `${process.env.REACT_APP_BASE_URL}/rankreports/fielders?cumulative=${cumulative}&startSeason=${startSeason}&endSeason=${endSeason}`;
    }
    url += `&filterInn=${filterInn}&opInn=${opInn}&filterG=${filterG}&opG=${opG}&filterGS=${filterGS}&opGS=${opGS}`;
    url = url + `&starterSub=${starterSub}&percentile=${percentile}&playerId=${playerId}`;
    let data = [];
    data = fetch(url)
        .then(response => response.json())
        .then((json) => {
            const data = json.data.map((p) => {
                return {
                    Player: p.player,
                    PlayerId: p.playerId,
                    Season: p.startSeason === p.endSeason ? p.startSeason : p.startSeason + '-' + p.endSeason.toString().substring(2, 4),
                    League: p.league,
                    PlayerCount: 'out of ' + p.playerCount,
                    Team: p.team,
                    Age: p.age,
                    fG: p.g,
                    fGS: p.gs,
                    fCG: p.cg,
                    fInn: p.inn,
                    A: p.assists,
                    E: p.errors,
                    FieldPercentage: p.fieldPercentage,
                    RF: p.rf,
                    GFP: p.gfp,
                    DM: p.dm,
                    DRS: p.drs,
                    UZR: p.uzr,
                    sDRS: p.sDRS
                }
            });
            return data;
        });
    return data;
}

export function GetRankReportsPitchers(season, startSeason, endSeason, startDate, endDate, mode, starterSub, percentile, filters, playerId) {
    let filterIP = filters.valueIP || 0;
    let opIP = filters.opIP || '>=';
    let filterG = filters.valueG || 0;
    let opG = filters.opG || '>=';
    let filterGS = filters.valueGS || 0;
    let opGS = filters.opGS || '>=';
    let filterRAPP = filters.valueRAPP || 0;
    let opRAPP = filters.opRAPP || '>=';
    let filterSV = filters.valueSV || 0;
    let opSV = filters.opSV || '>=';
    let filterHLD = filters.valueHLD || 0;
    let opHLD = filters.opHLD || '>=';

    let cumulative = ['date', 'cumulative'].includes(mode) ? 1 : 0;
    let startTime = new Date(startDate).getTime();
    let endTime = new Date(endDate).getTime();
    let url = '';
    if (mode === 'date') {
        url = `${process.env.REACT_APP_BASE_URL}/rankreports/pitchers?cumulative=${cumulative}&startTime=${startTime}&endTime=${endTime}`;
    }
    else if (mode === 'single') {
        url = `${process.env.REACT_APP_BASE_URL}/rankreports/pitchers?cumulative=${cumulative}&startSeason=${season}&endSeason=${season}`;
    }
    else {
        url = `${process.env.REACT_APP_BASE_URL}/rankreports/pitchers?cumulative=${cumulative}&startSeason=${startSeason}&endSeason=${endSeason}`;
    }
    url += `&filterIP=${filterIP}&opIP=${opIP}&filterG=${filterG}&opG=${opG}&filterGS=${filterGS}&opGS=${opGS}&filterRAPP=${filterRAPP}&opRAPP=${opRAPP}&filterSV=${filterSV}&opSV=${opSV}&filterHLD=${filterHLD}&opHLD=${opHLD}`;
    url += `&starterSub=${starterSub}&percentile=${percentile}&playerId=${playerId}`;
    let data = [];
    data = fetch(url)
        .then(response => response.json())
        .then((json) => {
            const data = json.data.map((p) => {
                return {
                    Player: p.player,
                    PlayerId: p.playerId,
                    Season: p.startSeason === p.endSeason ? p.startSeason : p.startSeason + '-' + p.endSeason.toString().substring(2, 4),
                    League: p.league,
                    PlayerCount: 'out of ' + p.playerCount,
                    Team: p.team,
                    Age: p.age,
                    pG: p.g,
                    pGS: p.gs,
                    InnPitched: p.innP,
                    InnPPerGS: p.innPPerGS,
                    W: p.w,
                    L: p.l,
                    Saves: p.sv,
                    ERA: p.era,
                    FIP: p.fip,
                    xFIP: p.xFIP,
                    SIERA: p.siera,
                    pStrikeOutPercentage: p.kPercentage,
                    pWalkPercentage: p.bbPercentage,
                    pHomeRunPercentage: p.hrPercentage,
                    WHIP: p.whip,
                    pBABIP: p.babip,
                    SwStr: p.swStr,
                    pLI: p.pLI,
                    WLPercentage: p.winPercentage,
                    QS: p.qs,
                    QSPercentage: p.qsPercentage,
                    CG: p.cg,
                    CGSO: p.cgso,
                    GF: p.gf,
                    BlownSaves: p.bs,
                    SavePercent: p.svPercentage,
                    Holds: p.hld,
                    BF: p.bf,
                    pH: p.h,
                    pR: p.r,
                    ER: p.er,
                    pHR: p.hr,
                    pStrikeouts: p.k,
                    pWalks: p.bb,
                    pIBB: p.ibb,
                    pHBP: p.hbp,
                    WP: p.wp,
                    BK: p.bk,
                    ERC: p.erc,
                    ERAPlus: p.eraPlus,
                    ERAMinus: p.eraMinus,
                    FIPMinus: p.fipMinus,
                    cFIP: p.cFIP,
                    DRA: p.dra,
                    StrikeoutsPerNine: p.kPerNine,
                    WalksPerNine: p.bbPerNine,
                    StrikeoutsPerWalk: p.kPerBB,
                    HRPerNine: p.hrPerNine,
                    pBatAvg: p.batAvg,
                    pOBP: p.obp,
                    pSLG: p.slg,
                    pOPS: p.ops,
                    LOBPercentage: p.lobPercentage,
                    RAPP: p.rapp,
                    gmLI: p.gmLI,
                    exLI: p.exLI,
                    LC: p.lc,
                    LCPercent: p.lcPercentage,
                    SD: p.sd,
                    MD: p.md,
                    IR: p.ir,
                    IRS: p.irs,
                    IRSPercentage: p.irsPercentage,
                    EasySV: p.easySV,
                    RegSV: p.regSV,
                    ToughSV: p.toughSV,
                    LevHi: p.levHi,
                    LevMd: p.levMd,
                    LevLo: p.levLo,
                    IPMulti: p.ipMulti,
                    ThreeOutsPlus: p.threeOutsPlus,
                    Balls: p.balls,
                    Strikes: p.strikes,
                    Pitches: p.pitches,
                    pGBPercentage: p.gbPercentage,
                    pFBPercentage: p.fbPercentage,
                    pLDPercentage: p.ldPercentage,
                    GBPerFB: p.gbPerFB,
                    pHRPerFB: p.hrPerFB,
                    SoftPercentage: p.softPercentage,
                    MedPercentage: p.medPercentage,
                    HardPercentage: p.hardPercentage,
                    fWAR: p.fWAR,
                    rWAR: p.rWAR,
                    WS: p.ws,
                    pWAR: p.pWAR,
                    WPA: p.wpa,
                    pPA: p.pa,
                    pBIP: p.bip,
                    pBBE: p.bbe,
                    pLA: p.la,
                    pSwSp: p.swSp,
                    pMaxEV: p.maxEV,
                    pAvgEV: p.avgEV,
                    pFBLDEV: p.fbldev,
                    pGBEV: p.gbev,
                    pBarrels: p.barrels,
                    pBarrelPerBBE: p.barrelsPerBBE,
                    pBarrelPerPA: p.barrelsPerPA,
                    pHardHitTotal: p.hardHitTotal,
                    pHardHitPercent: p.hardHitPercent,
                    pxAVG: p.xAVG,
                    pxSLG: p.xSLG,
                    pwOBA: p.wOBA,
                    pxwOBA: p.xwOBA,
                    ffPitches: p.fF_Pitches,
                    ffPercentage: p.fF_Percent,
                    ffMPH: p.fF_MPH,
                    ffSpin: p.fF_Spin,
                    ffExVelo: p.fF_ExVelo,
                    ffLA: p.fF_LA,
                    ffWhiff: p.fF_Whiff,
                    ffPutAway: p.fF_PutAway,
                    siPitches: p.sI_Pitches,
                    siPercentage: p.sI_Percent,
                    siMPH: p.sI_MPH,
                    siSpin: p.sI_Spin,
                    siExVelo: p.sI_ExVelo,
                    siLA: p.sI_LA,
                    siWhiff: p.sI_Whiff,
                    siPutAway: p.sI_PutAway,
                    slPitches: p.sL_Pitches,
                    slPercentage: p.sL_Percent,
                    slMPH: p.sL_MPH,
                    slSpin: p.sL_Spin,
                    slExVelo: p.sL_ExVelo,
                    slLA: p.sL_LA,
                    slWhiff: p.sL_Whiff,
                    slPutAway: p.sL_PutAway,
                    fcPitches: p.fC_Pitches,
                    fcPercentage: p.fC_Percent,
                    fcMPH: p.fC_MPH,
                    fcSpin: p.fC_Spin,
                    fcExVelo: p.fC_ExVelo,
                    fcLA: p.fC_LA,
                    fcWhiff: p.fC_Whiff,
                    fcPutAway: p.fC_PutAway,
                    cuPitches: p.cU_Pitches,
                    cuPercentage: p.cU_Percent,
                    cuMPH: p.cU_MPH,
                    cuSpin: p.cU_Spin,
                    cuExVelo: p.cU_ExVelo,
                    cuLA: p.cU_LA,
                    cuWhiff: p.cU_Whiff,
                    cuPutAway: p.cU_PutAway,
                    chPitches: p.cH_Pitches,
                    chPercentage: p.cH_Percent,
                    chMPH: p.cH_MPH,
                    chSpin: p.cH_Spin,
                    chExVelo: p.cH_ExVelo,
                    chLA: p.cH_LA,
                    chWhiff: p.cH_Whiff,
                    chPutAway: p.cH_PutAway
                }
            });
            return data;
        });
    return data;
}

export async function GetReliefPitcherReportData(formOptions) {
    const queryToolReqBody = {
        seasons: null,
        playerCriteria: null,
        numericalFilters: null,
        displayStats: null,
        consecutiveYears: 0,
        throughX: null,
        dateRanges: null,
        playerSeasons: null,
        scaleUpFilters: false,
        scaleUpDisplayStats: 0,
        includeAverage: false,
        cumulative: false,
        reportType: 1,
        positions: null,
    };

    let timePeriod = ' in Platform Year';

    switch (formOptions.mode) {
        case 'single':
            queryToolReqBody.seasons = formOptions.season;
            queryToolReqBody.playerSeasons = `${formOptions.playerId}-${formOptions.season}`;
            break;
        case 'multiple':
            queryToolReqBody.seasons = formOptions.endSeason;
            timePeriod = ` in Last ${formOptions.endSeason - formOptions.startSeason + 1} Years`;
            queryToolReqBody.playerSeasons = `${formOptions.playerId}-${formOptions.endSeason}`
            break;
        case 'date':
            queryToolReqBody.dateRanges =
                formOptions.startDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' })
                + '-'
                + formOptions.endDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' });
            let lastSeason = (new Date(formOptions.endDate)).getFullYear();
            queryToolReqBody.playerSeasons = `${formOptions.playerId}-${lastSeason}`
            break;
    }

    let filterText = {
        iswFilters: [],
        igwFilters: formOptions.displayStatId !== 736 ? [`${formOptions.displayStatId}=1`] : [],
    }
    
    formOptions.filters.forEach(filterInput => {
        let filter = generateFilterTextFromFilterInput(filterInput);
        if (filterInput.formInputs.isISWFilter)
            filterText.iswFilters.push(...filter);
        if (filterInput.formInputs.isIGWFilter)
            filterText.igwFilters.push(...filter);
    })


    queryToolReqBody.displayStats = getDisplayStatsParameter(
        filterText.igwFilters.length > 0 ? ` IGW (${filterText.igwFilters.join('&')})` : '',
        filterText.iswFilters.length > 0 ? '&' + filterText.iswFilters.join('&') : '',
        timePeriod
    );


    let userCookie = JSON.parse(localStorage.getItem('user'));
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/querytool/players`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + userCookie.authdata,
        },
        body: JSON.stringify(queryToolReqBody),
    });
    let qtJson = await res.json();

    if (qtJson.exceptionMsg)
        return { exceptionMsg: qtJson.exceptionMsg };
    if (qtJson.errors)
        return { exceptionMsg: `There was a problem with your query. TraceId: ${qtJson.traceId ?? 'Unknown'}` };
    if (qtJson.data?.length == 0)
        return { exceptionMsg: 'There is no data' };

    let data = qtJson.data.find(row => row.PlayerId === formOptions.playerId);

    let rows = {
        All: { label: 'All' },
        BattingTeamLeadsby4: { label: 'Batting Team Leads By 4+' },
        BattingTeamLeadsby3: { label: 'Batting Team Leads By 3' },
        BattingTeamLeadsby2: { label: 'Batting Team Leads By 2' },
        BattingTeamLeadsby1: { label: 'Batting Team Leads By 1' },
        TieGame: { label: 'Tie Game' },
        PitchingTeamLeadsby1: { label: 'Pitching Team Leads By 1' },
        PitchingTeamLeadsby2: { label: 'Pitching Team Leads By 2' },
        PitchingTeamLeadsby3: { label: 'Pitching Team Leads By 3' },
        PitchingTeamLeadsby4: { label: 'Pitching Team Leads By 4+' },
    }

    let runDiffRegex = /Run Differential Before=(?<runDiff>(.+ \d)|(Tie Game))( |\)|\+)/;
    let inningRegex = /(Inning>*=)(?<inning>\d+)/
    qtJson.columns.forEach(column => {
        let runDiffMatch = column.displayName.match(runDiffRegex);
        let runDiff = runDiffMatch?.groups?.runDiff.replaceAll(' ', '').replaceAll('+', '') || 'All';
        let inningMatch = column.displayName.match(inningRegex);
        let inning = inningMatch?.groups?.inning || 'All';
        rows[runDiff][inning] = data[column.displayNumber];
    })

    return {
        rows: Object.values(rows)
    };

    function getDisplayStatsParameter(igw, isw, timePeriod) {
        return `736${timePeriod}${isw !== '' ? ' ISW (' + isw.replace('&', '') + ')' : ''}${igw}` +
            `&&736${timePeriod} ISW (604=1${isw})${igw}&&736${timePeriod} ISW (604=2${isw})${igw}` +
            `&&736${timePeriod} ISW (604=3${isw})${igw}&&736${timePeriod} ISW (604=4${isw})${igw}` +
            `&&736${timePeriod} ISW (604=5${isw})${igw}&&736${timePeriod} ISW (604=6${isw})${igw}` +
            `&&736${timePeriod} ISW (604=7${isw})${igw}&&736${timePeriod} ISW (604=8${isw})${igw}` +
            `&&736${timePeriod} ISW (604=9${isw})${igw}&&736${timePeriod} ISW (604>=10${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-4${isw})${igw}&&736${timePeriod} ISW (614=-3${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-2${isw})${igw}&&736${timePeriod} ISW (614=-1${isw})${igw}` +
            `&&736${timePeriod} ISW (614=0${isw})${igw}&&736${timePeriod} ISW (614=1${isw})${igw}` +
            `&&736${timePeriod} ISW (614=2${isw})${igw}&&736${timePeriod} ISW (614=3${isw})${igw}` +
            `&&736${timePeriod} ISW (614=4${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-4&604=1${isw})${igw}&&736${timePeriod} ISW (614=-3&604=1${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-2&604=1${isw})${igw}&&736${timePeriod} ISW (614=-1&604=1${isw})${igw}` +
            `&&736${timePeriod} ISW (614=0&604=1${isw})${igw}&&736${timePeriod} ISW (614=1&604=1${isw})${igw}` +
            `&&736${timePeriod} ISW (614=2&604=1${isw})${igw}&&736${timePeriod} ISW (614=3&604=1${isw})${igw}` +
            `&&736${timePeriod} ISW (614=4&604=1${isw})${igw}&&736${timePeriod} ISW (614=-4&604=2${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-3&604=2${isw})${igw}&&736${timePeriod} ISW (614=-2&604=2${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-1&604=2${isw})${igw}&&736${timePeriod} ISW (614=0&604=2${isw})${igw}` +
            `&&736${timePeriod} ISW (614=1&604=2${isw})${igw}&&736${timePeriod} ISW (614=2&604=2${isw})${igw}` +
            `&&736${timePeriod} ISW (614=3&604=2${isw})${igw}&&736${timePeriod} ISW (614=4&604=2${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-4&604=3${isw})${igw}&&736${timePeriod} ISW (614=-3&604=3${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-2&604=3${isw})${igw}&&736${timePeriod} ISW (614=-1&604=3${isw})${igw}` +
            `&&736${timePeriod} ISW (614=0&604=3${isw})${igw}&&736${timePeriod} ISW (614=1&604=3${isw})${igw}` +
            `&&736${timePeriod} ISW (614=2&604=3${isw})${igw}&&736${timePeriod} ISW (614=3&604=3${isw})${igw}` +
            `&&736${timePeriod} ISW (614=4&604=3${isw})${igw}&&736${timePeriod} ISW (614=-4&604=4${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-3&604=4${isw})${igw}&&736${timePeriod} ISW (614=-2&604=4${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-1&604=4${isw})${igw}&&736${timePeriod} ISW (614=0&604=4${isw})${igw}` +
            `&&736${timePeriod} ISW (614=1&604=4${isw})${igw}&&736${timePeriod} ISW (614=2&604=4${isw})${igw}` +
            `&&736${timePeriod} ISW (614=3&604=4${isw})${igw}&&736${timePeriod} ISW (614=4&604=4${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-4&604=5${isw})${igw}&&736${timePeriod} ISW (614=-3&604=5${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-2&604=5${isw})${igw}&&736${timePeriod} ISW (614=-1&604=5${isw})${igw}` +
            `&&736${timePeriod} ISW (614=0&604=5${isw})${igw}&&736${timePeriod} ISW (614=1&604=5${isw})${igw}` +
            `&&736${timePeriod} ISW (614=2&604=5${isw})${igw}&&736${timePeriod} ISW (614=3&604=5${isw})${igw}` +
            `&&736${timePeriod} ISW (614=4&604=5${isw})${igw}&&736${timePeriod} ISW (614=-4&604=6${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-3&604=6${isw})${igw}&&736${timePeriod} ISW (614=-2&604=6${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-1&604=6${isw})${igw}&&736${timePeriod} ISW (614=0&604=6${isw})${igw}` +
            `&&736${timePeriod} ISW (614=1&604=6${isw})${igw}&&736${timePeriod} ISW (614=2&604=6${isw})${igw}` +
            `&&736${timePeriod} ISW (614=3&604=6${isw})${igw}&&736${timePeriod} ISW (614=4&604=6${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-4&604=7${isw})${igw}&&736${timePeriod} ISW (614=-3&604=7${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-2&604=7${isw})${igw}&&736${timePeriod} ISW (614=-1&604=7${isw})${igw}` +
            `&&736${timePeriod} ISW (614=0&604=7${isw})${igw}&&736${timePeriod} ISW (614=1&604=7${isw})${igw}` +
            `&&736${timePeriod} ISW (614=2&604=7${isw})${igw}&&736${timePeriod} ISW (614=3&604=7${isw})${igw}` +
            `&&736${timePeriod} ISW (614=4&604=7${isw})${igw}&&736${timePeriod} ISW (614=-4&604=8${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-3&604=8${isw})${igw}&&736${timePeriod} ISW (614=-2&604=8${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-1&604=8${isw})${igw}&&736${timePeriod} ISW (614=0&604=8${isw})${igw}` +
            `&&736${timePeriod} ISW (614=1&604=8${isw})${igw}&&736${timePeriod} ISW (614=2&604=8${isw})${igw}` +
            `&&736${timePeriod} ISW (614=3&604=8${isw})${igw}&&736${timePeriod} ISW (614=4&604=8${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-4&604=9${isw})${igw}&&736${timePeriod} ISW (614=-3&604=9${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-2&604=9${isw})${igw}&&736${timePeriod} ISW (614=-1&604=9${isw})${igw}` +
            `&&736${timePeriod} ISW (614=0&604=9${isw})${igw}&&736${timePeriod} ISW (614=1&604=9${isw})${igw}` +
            `&&736${timePeriod} ISW (614=2&604=9${isw})${igw}&&736${timePeriod} ISW (614=3&604=9${isw})${igw}` +
            `&&736${timePeriod} ISW (614=4&604=9${isw})${igw}&&736${timePeriod} ISW (614=-4&604>=10${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-3&604>=10${isw})${igw}&&736${timePeriod} ISW (614=-2&604>=10${isw})${igw}` +
            `&&736${timePeriod} ISW (614=-1&604>=10${isw})${igw}&&736${timePeriod} ISW (614=0&604>=10${isw})${igw}` +
            `&&736${timePeriod} ISW (614=1&604>=10${isw})${igw}&&736${timePeriod} ISW (614=2&604>=10${isw})${igw}` +
            `&&736${timePeriod} ISW (614=3&604>=10${isw})${igw}&&736${timePeriod} ISW (614=4&604>=10${isw})${igw}`;
    }
}

export async function GetRecentSigningsReport(formOptions) {
    const queryToolReqBody = {
        numericalFilters: null,
        displayStats: formOptions.displayStats.join('&&'),
        reportType: 2,
    };

    // Set Filters
    let filters = formOptions.filters
        .slice()
        .flatMap(generateFilterTextFromFilterInput);
    filters.push(`385>=${customDateString(formOptions.startDate)}`)
    filters.push(`385<=${customDateString(formOptions.endDate)}`)
    queryToolReqBody.numericalFilters = filters.join('&&');

    let userCookie = JSON.parse(localStorage.getItem('user'));
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/querytool/contracts`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + userCookie.authdata,
        },
        body: JSON.stringify(queryToolReqBody),
    });
    let qtJson = await res.json();

    if (qtJson.exceptionMsg)
        return { exceptionMsg: qtJson.exceptionMsg };
    if (qtJson.errors)
        return { exceptionMsg: `There was a problem with your query. TraceId: ${qtJson.traceId ?? 'Unknown'}` };
    if (qtJson.data?.length == 0)
        return { exceptionMsg: 'There is no data' };

    let rows = {};

    let contractDescDisplayNumber = qtJson.columns
        .find(column => column.displayName === 'ContractYearDescription').displayNumber;
    qtJson.data.forEach(row => {
        if (rows[row.ContractId] == null) {
            rows[row.ContractId] = Object.assign({}, row);
        }

        if (rows[row.ContractId].Season < row.Season && row[contractDescDisplayNumber])
            rows[row.ContractId][contractDescDisplayNumber] = row[contractDescDisplayNumber];
    })
    qtJson.rows = Object.values(rows);

    let extraColumns = ['ContractId', 'Season', 'IsOptionYear'];
    qtJson.columnMap = {}
    qtJson.columns.forEach(column => {
            if (!extraColumns.includes(column.displayName))
                qtJson.columnMap[column.displayName] = column.displayNumber;
        });

    return qtJson;
}

export async function GetPlayerPageAwards(player) {
    const queryToolReqBody = {
        seasons: null,
        playerCriteria: null,
        numericalFilters: null,
        displayStats: null,
        consecutiveYears: 0,
        throughX: null,
        dateRanges: null,
        playerSeasons: null,
        scaleUpFilters: false,
        scaleUpDisplayStats: 0,
        includeAverage: false,
        cumulative: false,
        reportType: 1,
        positions: null,
    };

    //player.id
    //player.maxSeason
    //player.minSeason
    //player.posFlag
    queryToolReqBody.playerSeasons = Array.from(
        Array(player.maxSeason - player.minSeason + 1).fill(player.minSeason),
        ((season, index) => `${player.id}-${season + index}`)
    ).join(',');

    queryToolReqBody.displayStats = playerAwardsDisplayStats();

    let userCookie = JSON.parse(localStorage.getItem('user'));
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/querytool/players`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + userCookie.authdata,
        },
        body: JSON.stringify(queryToolReqBody),
    });
    let qtJson = await res.json();

    if (qtJson.exceptionMsg)
        return { exceptionMsg: qtJson.exceptionMsg };
    if (qtJson.errors)
        return { exceptionMsg: `There was a problem with your query. TraceId: ${qtJson.traceId ?? 'Unknown'}` };
    if (qtJson.data?.length == 0)
        return { exceptionMsg: 'There is no data' };

    return qtJson;

    function playerAwardsDisplayStats() {
        return '2 in Platform Year&&3 in Platform Year&&612 in Platform Year&&5 in Career To Date'
            + '&&4 in Platform Year&&382 in Platform Year&&63 in Platform Year&&62 in Platform Year&&72 in Platform Year'
            + '&&617 in Platform Year ISW (591=1)&&617 in Platform Year ISW (591=2)&&617 in Platform Year ISW (591=3)'
            + '&&617 in Platform Year ISW (591=4)&&617 in Platform Year ISW (591=5)&&617 in Platform Year ISW (591=6)'
            + '&&617 in Platform Year ISW (591=7)&&617 in Platform Year ISW (591=8)&&617 in Platform Year ISW (591=9)'
            + '&&617 in Platform Year ISW (591=12)&&617 in Platform Year ISW (591=15)&&617 in Platform Year ISW (591=18)'
            + '&&617 in Platform Year ISW (591=19)&&617 in Platform Year ISW (591=20)&&617 in Platform Year ISW (591=21)'
            + '&&617 in Platform Year ISW (591=22)&&617 in Platform Year ISW (591=30)';
    }
}

export async function GetPlayerPageAwardsLeaderboards(awardsBySeason, columns) {
    let userCookie = JSON.parse(localStorage.getItem('user'));
    let requests = [];

    Object.keys(awardsBySeason).forEach(season => {
        const queryToolReqBody = {
            seasons: season,
            playerCriteria: null,
            numericalFilters: null,
            displayStats: null,
            consecutiveYears: 0,
            throughX: null,
            dateRanges: null,
            playerSeasons: null,
            scaleUpFilters: false,
            scaleUpDisplayStats: 0,
            includeAverage: false,
            cumulative: false,
            reportType: 1,
            positions: null,
        };
        let awardIds = awardsBySeason[season].map(seasonAwards => seasonAwards.value);
        queryToolReqBody.numericalFilters = `617>0 in Platform Year ISW (591=${awardIds.join(',')})`;
        queryToolReqBody.displayStats = awardsLeaderboardsDisplayStats(awardIds, columns);

        requests.push(callApi(userCookie.authdata, JSON.stringify(queryToolReqBody)));
    })

    let resJsons = [];
    await Promise.all(requests)
        .then(values => {
            resJsons = values;
        });

    return resJsons;

    function awardsLeaderboardsDisplayStats(awardIds, columns) {
        let awardColumns = [617, 618, 619, 770, 771];
        return columns
            .filter(column => column.id !== 591)
            .map(column => {
                if (!awardColumns.includes(column.id))
                    return `${column.id} in ${column.formInputs.defaultTimePeriod}`;

                return awardIds
                    .map(awardId => `${column.id} in ${column.formInputs.defaultTimePeriod} ISW (591=${awardId})`)
                    .join('&&');
            })
            .join('&&');
    }

    async function callApi(auth, body) {
        let response = await fetch(`${process.env.REACT_APP_BASE_URL}/querytool/players`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + auth,
            },
            body: body,
        });

        let resJson = await response.json();

        return resJson;
    }
}

export async function GetQueryToolPlayerData(seasons, playerCriteria, numericalFilters, displayStats,
        consecutiveYears, throughX, dateRanges, playerSeasons, scaleUpFilters, scaleUpDisplayStats) {
    let userCookie = JSON.parse(localStorage.getItem('user'));
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/querytool/players`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + userCookie.authdata,
        },
        body: JSON.stringify({
            seasons: seasons,
            playerCriteria: playerCriteria,
            numericalFilters: numericalFilters,
            displayStats: displayStats,
            consecutiveYears: consecutiveYears,
            throughX: throughX,
            dateRanges: dateRanges,
            playerSeasons: playerSeasons,
            scaleUpFilters: scaleUpFilters,
            scaleUpDisplayStats: scaleUpDisplayStats,
            reportType: 1,
        }),
    });
    let resJson = await res.json();

    if (scaleUpDisplayStats === 2) {
        return nestScaleUpDataForQueryToolByPlayer(resJson, throughX === null)
    }
    return resJson;
}

export async function GetQueryToolContractsData(numericalFilters, displayStats) {
    let userCookie = JSON.parse(localStorage.getItem('user'));
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/querytool/contracts`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + userCookie.authdata,
        },
        body: JSON.stringify({
            numericalFilters: numericalFilters,
            displayStats: displayStats,
            reportType: 2,
        }),
    });
    let resJson = await res.json();

    if (resJson.exceptionMsg) {
        return resJson;
    }
    return nestContractYearRows(resJson);
}

export function GetInjuries(playerId) {
    let injuries = [];
    injuries = fetch(`${process.env.REACT_APP_BASE_URL}/playerpage/injuries?playerId=${playerId}`)
        .then(response => response.json())
        .then((json) => {
            const injuries = json.data.map((i) => {
                return {
                    TickId: i.tickId,
                    DateInjured: i.dateInjured,
                    NextAppearance: i.nextAppearance,
                    Injury: i.injury,
                    Region: i.region,
                    RegionPart: i.regionPart,
                    Grade: i.grade,
                    ILTime: i.ilTime,
                    Diagnosis: i.diagnosis,
                    Prognosis: i.prognosis,
                    UpdateId: i.updateId,
                    Notes: i.notes,
                    NoteSource: i.noteSource
                }
            });
            return injuries;
        });
    return injuries;
}

export function GetPlayerGlobalInfo(playerId) {
    let player = {};
    player = fetch(`${process.env.REACT_APP_BASE_URL}/playerpage/globalinfo?playerId=${playerId}`)
        .then(response => response.json())
        .then((json) => {
            return json.data
        });
    return player;
}

export async function GetPlayerContractInfo(playerId) {
    let response = await fetch(`${process.env.REACT_APP_BASE_URL}/playerpage/contractInfo?playerId=${playerId}`);
    let resJson = await response.json();
    let data = resJson.data.map(contract => {
        let retContract = Object.assign({}, contract);
        retContract.ContractYears = contract.ContractYears.map(contractYear => {
            let retContractYear = Object.assign({}, contractYear);

            retContractYear.NoTradeTeams = contractYear.NoTradeTeams
                .map(noTradeTeam => noTradeTeam?.NoTradeTeamAbbr ?? '')
                .join(', ');
            return retContractYear
        })
        return retContract;
    })
    return transformJsonToObjArray(data);
}

export function GetTransactions(id) {
    let transactions = [];
    transactions = fetch(`${process.env.REACT_APP_BASE_URL}/playerpage/transactions?playerId=${id}`)
        .then(response => response.json())
        .then((json) => {
            const transactions = json.data.map((t) => {
                let tDate = new Date(t.transactionDate);

                return {
                    TransactionId: t.transactionId,
                    SequenceNumber: t.sequenceNumber,
                    Season: t.season,
                    TransactionDate: (tDate.getMonth() + 1) + '/' + tDate.getDate() + '/' + tDate.getFullYear(),
                    PlayerId: t.playerId,
                    TransactionCode: t.transactionCode,
                    TransactionDetail: t.transactionDetail
                }
            });
            return transactions;
        });
    return transactions;
}

export function GetSeasonRosterData(playerId) {
    let seasons = [];
    seasons = fetch(`${process.env.REACT_APP_BASE_URL}/playerpage/seasonRosterData?playerId=${playerId}`)
        .then(response => response.json())
        .then((json) => {
            const seasons = json.data.map((s) => {
                return {
                    Season: s.season,
                    StartDate: s.startDate,
                    EndDate: s.endDate,
                    RosterStatus: s.rosterStatus,
                    CTDMLS: s.ctdmls,
                    MLS: s.mls,
                    BurnedOption: s.burnedOption
                }
            });
            return seasons;
        });
    return seasons;
}

export function GetRosterStatus(playerId) {
    let rosterStatus = [];
    rosterStatus = fetch(`${process.env.REACT_APP_BASE_URL}/playerpage/rosterStatus?playerId=${playerId}`)
        .then(response => response.json())
        .then((json) => {
            const rosterStatus = json.data.map((rg) => {
                return {
                    Season: rg.season,
                    Date: rg.date,
                    PlayerId: rg.playerId,
                    RosterStatusGeneral: rg.rosterStatus_General,
                    RosterStatusSpecific: rg.rosterStatus_Specific,
                    FortyMan: rg.fortyMan,
                    ActiveRoster: rg.activeRoster,
                    ILDay: rg.ilDay,
                    OptionDay: rg.optionDay
                }
            });
            return rosterStatus
        });
    return rosterStatus;
}

export async function GetRosterStatusSequence(playerId) {
    let response = await fetch(`${process.env.REACT_APP_BASE_URL}/playerpage/rosterStatusSequence?playerId=${playerId}`);
    let resJson = await response.json();
    return transformJsonToObjArray(resJson.data);
}