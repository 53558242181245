import React, { useState } from 'react';
import { render } from 'react-dom';
import { Badge, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import StatSelectionForm from './StatSelectionForm';
import { getValueLabelFromFormInputs } from '../Common/CommonFunctions';

export function FilterCreation(props) {
    const [iswFilter, setIswFilter] = useState({
        index: 1,
        stat: null,
        operator: '>',
        value: '0',
        timePeriod: props.timePeriodNotSelectable ? 'Career To Date' : 'Platform Year',
    });
    const [igwFilter, setIgwFilter] = useState({
        index: 1,
        stat: null,
        operator: '>',
        value: '0',
        timePeriod: props.timePeriodNotSelectable ? 'Career To Date' : 'Platform Year',
    });
    const [filters, setFilters] = useState([]);

    function changeFilterByType(type, field, value) {
        let tempFilter = {};
        if (type === 'isw') tempFilter = Object.assign({}, iswFilter);
        else if (type === 'igw') tempFilter = Object.assign({}, igwFilter);

        tempFilter = changeFilter(tempFilter, field, value);

        if (type === 'isw') setIswFilter(tempFilter);
        else if (type === 'igw') setIgwFilter(tempFilter);
    }

    function changeFilter(tempFilter, field, value) {
        tempFilter[field] = value;
        
        if (field === 'stat') {
            let curStatFormInputs = props.getStatInfoFromColumnById(value).formInputs;
            tempFilter.timePeriod =
                props.timePeriodNotSelectable
                    ? 'Career To Date'
                    : curStatFormInputs.defaultTimePeriod;

            switch (curStatFormInputs.inputType) {
                case 'Date':
                    tempFilter.value = new Date();
                    break;
                case 'DateNoYear':
                    tempFilter.operator = '=';
                    tempFilter.value = new Date();
                    break;
                case 'Select':
                    tempFilter.operator = '=';
                    tempFilter.value = curStatFormInputs.selectOptions[0]?.value ?? '0';
                    tempFilter.valueLabel = curStatFormInputs.selectOptions[0]?.label ?? '0';
                    break;
                case 'MultiSelect':
                    tempFilter.operator = '=';
                    tempFilter.value = null;
                    tempFilter.valueLabel = null;
                    break;
            }
        }
        if (tempFilter.stat) {
            tempFilter.valueLabel = getValueLabelFromFormInputs(
                props.getStatInfoFromColumnById(tempFilter.stat).formInputs,
                tempFilter.value
            )
        }
        return tempFilter
    }

    /**
     * Adds the filter to the list of draggable html elements.
     * @param {string} filterType Can either be "sit"(situational) or "igw"(in games where)
     */
    const createFilter = (filterType) => {
        let tempFilters = filters.slice(0);
        switch (filterType) {
            case 'isw':
                if (iswFilter && iswFilter.stat && iswFilter.stat !== 'Select a Split') {
                    let filter = Object.assign({}, iswFilter);
                    filter.split = props.selectableStatsByGroup.flat().find(c => c.key == filter.stat).title;
                    filter.statId = filter.stat;
                    filter.filterType = 'ISW';
                    filter.granularity = props.getStatInfoFromColumnById(filter.stat)?.formInputs?.granularity;
                    tempFilters.push(filter);
                }
                break;
            case 'igw':
                if (igwFilter && igwFilter.stat && igwFilter.stat !== 'Select a Split') {
                    let filter = Object.assign({}, igwFilter);
                    filter.split = props.selectableStatsByGroup.flat().find(c => c.key == filter.stat).title;
                    filter.statId = filter.stat;
                    filter.filterType = 'IGW';
                    filter.granularity = props.getStatInfoFromColumnById(filter.stat)?.formInputs?.granularity;
                    tempFilters.push(filter);
                }
                break;
            default:
                break;
        }
        setFilters(tempFilters);
    }


    let filterHtml = [];
    filters.forEach((filter, index) => {
        if (!filter.operator) {
            filter.operator = '=';
        }
        let badge =
            <DraggableFilter
                className={filter.filterType}
                filterText={filter.split + filter.operator + (filter.valueLabel ?? filter.value)}
                filter={filter.statId + filter.operator + filter.value}
                id={'filter-' + index}
                statId={filter.statId}
                granularity={filter.granularity}
            />
        if (index === 0) {
            filterHtml.push(
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip>
                            Drag Me!
                        </Tooltip>
                    }
                >
                    {badge}
                </OverlayTrigger>
            )
        } else {
            filterHtml.push(
                badge
            )
        }
    })

    return (
        <React.Fragment>
            <Row>
                <Col xs={9}>
                    <Row key='0'>
                        <Col className='d-none d-lg-block' lg={3} xl={2}>
                            Split
                    </Col>
                        <Col className='d-none d-lg-block' lg={2}>
                            Operator
                    </Col>
                        <Col className='d-none d-lg-block' lg={2}>
                            Value
                    </Col>
                        <Col className='d-none d-lg-block' lg={1}></Col>
                    </Row>
                </Col>
                <Col xs={3} lg={2} />
            </Row>
            <Row>
                <Col xs={9}>
                    <StatSelectionForm
                        selectableStatsByGroup={props.selectableStatsByGroup.map(group => {
                            return group.filter(stat => stat.formInputs.isISWFilter);
                        })}
                        colGroups={props.colGroups.filter(group => group.groupId === 47)}
                        section={'Filter'}
                        tabName={props.tabName}
                        timePeriodNotSelectable={props.timePeriodNotSelectable}
                        hideFilters={props.hideFilters}
                        stats={[]}
                        curStat={iswFilter}
                        index={1}
                        setStat={(index, field, value) => changeFilterByType('isw', field, value)}
                        getStatInfoFromColumnById={props.getStatInfoFromColumnById}
                        placeholder='In Situations Where'
                    />
                </Col>
                <Col xs={3} lg={2}>
                    <Button variant='info' onClick={() => createFilter('isw')}>Create In Situations Where (ISW) Filter</Button>
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <StatSelectionForm
                        selectableStatsByGroup={props.selectableStatsByGroup.map(group => {
                            return group.filter(stat => stat.formInputs.isIGWFilter);
                        })}
                        colGroups={props.colGroups}
                        section={'Filter'}
                        tabName={props.tabName}
                        timePeriodNotSelectable={props.timePeriodNotSelectable}
                        hideFilters={props.hideFilters}
                        stats={[]}
                        curStat={igwFilter}
                        index={1}
                        setStat={(index, field, value) => changeFilterByType('igw', field, value)}
                        getStatInfoFromColumnById={props.getStatInfoFromColumnById}
                        placeholder='In Games Where'
                    />
                </Col>
                <Col xs={3} lg={2}>
                    <Button variant='info' onClick={() => createFilter('igw')}>Create In Games Where (IGW) Filter</Button>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {filterHtml}
                </Col>
            </Row>
        </React.Fragment>
    )
}

function startDrag(ev, className) {
    function granularityComparisonAllow(filterType, filterGranularities, dropZoneGranularity) {
        if (filterType == 'ISW') {
            return filterGranularities
                .map(granularity => parseInt(granularity))
                .every(granularity => granularity >= parseInt(dropZoneGranularity));
        } else {
            return filterGranularities
                .map(granularity => parseInt(granularity))
                .every(granularity => granularity <= 2);
        }
    }

    function hasMatchingUniqueFilter(uniqueFiltersAllowed, filterId) {
        return uniqueFiltersAllowed.split(',').includes(filterId);
    }

    localStorage.setItem('filterType', className);
    let targetId = ev.target.id;
    let filterGranularities = [];
    let filterId;
    if (targetId.includes('filter')) {
        let filterEle = document.getElementById(ev.target.id);
        filterGranularities.push(filterEle.getAttribute('granularity'));
        filterId = filterEle.getAttribute('statid');
    } else {
        document.getElementById(ev.target.id)?.querySelectorAll(`span[draggable='true']`).forEach(filter => {
            filterGranularities.push(filter.getAttribute('granularity'));
        })
    }

    document.body.style.cursor = 'grabbing';
    let notDropZones = document.getElementsByTagName('input');
    Array.prototype.forEach.call(notDropZones, (nd) => {
        nd.ondragover = (ev) => {
            ev.preventDefault();
            ev.dataTransfer.dropEffect = 'none';
        }
    })

    let dropZones = document.getElementsByClassName('dropZone');
    Array.prototype.forEach.call(dropZones, (dropZone) => {
        let nestedFilters = dropZone.getElementsByTagName('span');
        let hasSameFilterType = !!~Array.prototype.findIndex.call(
            nestedFilters,
            (nestedFilter => nestedFilter.classList.contains(className))
        )
        let dropZoneGranularity = dropZone.getAttribute('granularity') ?? -1;
        let dropZoneAllowableFilters = dropZone.getAttribute('allowableFilters') ?? '';
        //let isWhereBlock = Array.prototype.some.call(
        //    dropZone.classList,
        //    (className => {
        //        let whereBlockRegex = /dropZone-([0-9]+|all)$/;
        //        return whereBlockRegex.test(className);
        //    })
        //);
        if (
            (
                hasMatchingUniqueFilter(dropZoneAllowableFilters, filterId)
                || granularityComparisonAllow(className, filterGranularities, dropZoneGranularity)
            )
            && (
                dropZone.classList.contains(className)
                || (
                    dropZone.classList.contains('dropZone-all')
                    && (nestedFilters.length === 0 || hasSameFilterType)
                )
            )
        ) {
            dropZone.classList.add('available');
            dropZone.ondragover = (ev) => {
                ev.preventDefault();
                ev.dataTransfer.dropEffect = 'copy';
            }
        } else {
            dropZone.ondragover = (ev) => {
                ev.preventDefault();
                ev.dataTransfer.dropEffect = 'none';
                ev.dataTransfer.effectAllowed = 'none';
            }
        }
    })
    ev.dataTransfer.setData('text/id', ev.target.id);
    ev.dataTransfer.effectAllowed = 'move';
}

function endDrag(ev) {
    document.body.style.cursor = 'default';
    let dropZones = document.getElementsByClassName('dropZone');
    Array.prototype.forEach.call(dropZones, (dropZone) => {
        dropZone.classList.remove('available');
    });
    return false;
}

function CloseButton(props) {
    return (
        <div
            type='button'
            className='deleteButton'
            style={{ float: 'right' }}
            onDragOver={(ev) => {
                ev.preventDefault();
                ev.dataTransfer.dropEffect = 'none';
                ev.stopPropagation();
            }}
            onClick={(ev) => {
                let parentId = ev.target.parentNode.id;
                let prevLogOp = document.getElementById(parentId + '-logOp');
                if (prevLogOp) {
                    prevLogOp.remove();
                }
                else {
                    let nextLogOp = document.getElementById(parentId).nextSibling;
                    if (nextLogOp) nextLogOp.remove();
                }
                document.getElementById(parentId).remove();
            }}
        >
            X
        </div>
    )
}

export function DraggableFilter(props) {
    return (
        <Badge
            className={`draggable ${props.className}`}
            id={props.id}
            statid={props.statId}
            granularity={props.granularity}
            style={{ margin: '5px' }}
            variant='secondary'
            draggable='true'
            onDragStart={(ev) => startDrag(ev, props.className)}
            onDragEnd={(ev) => endDrag(ev)}
            filter={props.filter}
        >
            {props.filterText}
            <CloseButton />
        </Badge>
    )
}

export function DropBlock(props) {
    return (
        <div
            className={`dropZone ${props.id} ${props.className}`}
            id={props.id}
            onDragOver={(ev) => {
                ev.preventDefault();
                ev.dataTransfer.dropEffect = 'move';
            }}
            onDrop={dropElement}
            draggable={true}
            onDragStart={(ev) => startDrag(ev, props.className)}
            onDragEnd={(ev) => endDrag(ev)}
        >
            {props.children}
            <CloseButton />
        </div>
    )
}

export function LogOp(props) {
    const [operator, setOperator] = useState(props.operator);

    function toggleLogOp() {
        if (operator === 'AND') {
            setOperator('OR');
        }
        else {
            setOperator('AND');
        }
    }

    return (
        <span className={`logOp ${props.id}`} id={props.id} onClick={toggleLogOp}>{operator}</span>
    )
}

export function dropElement(ev, target, prevId) {
    ev.preventDefault();
    target = target || ev.target;
    prevId = prevId || ev.dataTransfer.getData('text/id');

    // Unhighlight drop zones
    let dropZones = document.getElementsByClassName('dropZone');
    Array.prototype.forEach.call(dropZones, (d) => {
        d.classList.remove('available');
    })

    // Check if drop is allowed
    let nestedFilters = target.getElementsByTagName('span');
    let filterTypeFromCookie = localStorage.getItem('filterType');
    //localStorage.removeItem('filterType');

    let hasSameFilterType = !!~Array.prototype.findIndex.call(
        nestedFilters,
        (nestedFilter => nestedFilter.classList.contains(filterTypeFromCookie))
    )
    if ((!target.classList.contains('dropZone-all') && !target.classList.contains(filterTypeFromCookie))
        || (target.classList.contains('dropZone-all') && (nestedFilters.length > 0 && !hasSameFilterType))) {
        return;
    }

    // Create element and append it to target
    let dropZoneClass = Array.prototype.find.call(target.classList, (cl) => cl.includes('dropZone-'));
    let filterType = document.getElementById(prevId).classList.contains('ISW') ? 'ISW' : 'IGW';
    let ele = document.createDocumentFragment();

    let id = '';
    let lastEle = target.lastChild;
    let lastId = lastEle && (lastEle.className.includes('dropZone') || lastEle.className.includes('draggable'))
        ? 1 + +lastEle.id.slice(lastEle.id.lastIndexOf('-') + 1)
        : 0;
    if (prevId.includes('filter') && !prevId.includes('logOp')) { // If it's a filter
        if (target.className.includes('block')) {
            id = `${dropZoneClass.replace('dropZone', 'criteria')}-filter-${lastId}`;
            let filterText = document.getElementById(prevId).firstChild.data;
            let filterStatId = document.getElementById(prevId).attributes.statid;
            let filterRaw = document.getElementById(prevId).attributes.filter.value;
            let filterGranularity = document.getElementById(prevId).attributes.granularity;
            render(
                <DraggableFilter
                    className={filterType}
                    statId={filterStatId?.value}
                    filterText={filterText}
                    filter={filterRaw}
                    id={id}
                    granularity={filterGranularity?.value}
                />,
                ele
            );
        }
        else {
            id = `${filterType}-dropZone-${dropZoneClass.split('-')[1]}-block-${lastId}`;
            render(<DropBlock id={id} className={filterType} />, ele);
            dropElement(ev, ele.firstChild);
        }
    }
    else if (prevId.includes('logOp')) {
        id = `${target.id}-logOp-${lastId}`;
        let operator = document.getElementById(prevId).innerText;
        render(<LogOp operator={operator} id={id + '-logOp'} className={'logOp'} />, ele);
        Array.prototype.forEach.call(document.getElementById(prevId).children, htmlEle => {
            if (htmlEle.className.includes('dropZone') || htmlEle.className.includes('draggable')) {
                dropElement(ev, ele.firstChild, htmlEle.id);
            }
        });
    }
    else { // If it's a drop block
        if (target.className.includes('block')) {
            id = `${target.id}-block-${lastId}`;
        }
        else {
            id = `${filterType}-dropZone-${dropZoneClass.split('-')[1]}-block-${lastId}`
        }
        render(<DropBlock id={id} className={filterType} />, ele);
        // Add all nested filters and dropzones to the dropBlock
        Array.prototype.forEach.call(document.getElementById(prevId).children, htmlEle => {
            if (htmlEle.className.includes('dropZone') || htmlEle.className.includes('draggable') || htmlEle.className.includes('logOp')) {
                dropElement(ev, ele.firstChild, htmlEle.id);
            }
        });
    }
    let filters = Array.prototype.filter.call(target.children, htmlEle => {
        return htmlEle.tagName.toLowerCase() === 'span' || htmlEle.className.includes('dropZone');
    })
    let filterIndex = filters.length - 1;
    let requiresLogOp = filters.length > 0 && !prevId.includes("logOp") && !filters[filterIndex].id.includes("logOp");
    if (requiresLogOp) {
        let logOpEle = document.createDocumentFragment();
        render(<LogOp operator={'AND'} id={id + '-logOp'} className={'logOp'} />, logOpEle);
        target.appendChild(logOpEle.firstChild);
    }
    target.appendChild(ele);

    document.body.style.cursor = 'default';
    ev.stopPropagation();
    return;
}
