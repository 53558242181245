import React from 'react';
import ReactDOM from 'react-dom';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import Router from './Router';
import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_TEST_ENV === 'Prod') {
    let googleAnalyticsFragment = document.createDocumentFragment();

    let googleAnalyticsScript = document.createElement('script');
    googleAnalyticsScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-Z6TR15FXSY';
    googleAnalyticsFragment.appendChild(googleAnalyticsScript);

    let googleTagScript = document.createElement('script');
    googleTagScript.textContent = `
window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('js', new Date());

gtag('config', 'G-Z6TR15FXSY');`;
    googleAnalyticsFragment.appendChild(googleTagScript);

    document.head.prepend(googleAnalyticsFragment);
}

const msalInstance = new PublicClientApplication(msalConfig);
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

const successfulEventTypes = [
    EventType.LOGIN_SUCCESS,
    EventType.ACQUIRE_TOKEN_SUCCESS,
    EventType.SSO_SILENT_SUCCESS
];

msalInstance.addEventCallback((event) => {
    if (successfulEventTypes.includes(event.eventType) && event.payload.account) {
        msalInstance.setActiveAccount(event.payload.account);
    }
})

ReactDOM.render(<Router msalInstance={msalInstance} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
