import React, { useState, useEffect } from 'react';
import { Button, Modal, ListGroup } from 'react-bootstrap';
import { getReports, saveReport, deleteReport } from '../../Services/UserSvc';

function ReportModal(props) {
    const [showOverwriteModal, setShowOverwriteModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showSaveAsModal, setShowSaveAsModal] = useState(false);
    const [saveName, setSaveName] = useState('Default');
    const [selectedReport, setSelectedReport] = useState('');
    const [reportList, setReportList] = useState([]);
    const [reportListHtml, setReportListHtml] = useState([]);
    const [showReportList, setShowReportList] = useState(false);
    
    useEffect(() => {
        if (props.showModal) {
            getReports(props.page)
                .then((data) => {
                    let reports = [];
                    data.forEach(report => {
                        reports.push(
                            {
                                reportName: report.reportName,
                                reportQuery: report.reportQuery,
                                dateSaved: report.dateSaved
                            }
                        )
                    })
                    return reports;
                })
                .then((reports) => {
                    setReportList(reports);
                });
        }
    }, [props.showModal])

    useEffect(() => {
        let reports = [];
        if (reportList.length > 0) {
            reportList.forEach(r => {
                let reportDate = new Date(r.dateSaved);
                let dateString = 'N/A';
                if (reportDate > new Date(2000, 1, 1)) {
                    dateString = (reportDate.getMonth() + 1) + '/' + reportDate.getDate() + '/' + reportDate.getFullYear();
                }
                if (r.reportName === selectedReport) {
                    reports.push(<ListGroup.Item action onClick={() => setSelectedReport(r.reportName)} active><div className='row'>{r.reportName}<div style={{ margin: '0px 0px 0px auto' }}>{dateString}</div></div></ListGroup.Item>);
                }
                else {
                    reports.push(<ListGroup.Item action onClick={() => setSelectedReport(r.reportName)}><div className='row'>{r.reportName}<div style={{ margin: '0px 0px 0px auto' }}>{dateString}</div></div></ListGroup.Item>);
                }
            })
        }
        setReportListHtml(reports);
    }, [selectedReport, reportList])

    function closeModal() {
        setShowReportList(false);
        setSelectedReport('');
        props.hideModal();
    }

    function Save(reportName, cols, overwrite = false) {
        if (reportList.findIndex(r => r.reportName === reportName) > -1 && !overwrite) {
            setSelectedReport(reportName);
            setShowOverwriteModal(true);
        }
        else {
            saveReport(props.page, reportName, cols);
            setShowOverwriteModal(false);
            setShowSaveAsModal(false);
            closeModal();
        }
    }

    function Load() {
        props.loadReport(reportList.find(r => r.reportName === selectedReport).reportQuery);
        closeModal();
    }

    function Delete(reportName) {
        if (reportList.findIndex(r => r.reportName === reportName) > -1) {
            deleteReport(props.page, reportName);
            setShowDeleteModal(false);
            closeModal();
        }
    }

    return (
        <React.Fragment>
            {
                props.showModal &&
                <ModalTemplate
                    show={props.showModal}
                    size='lg'
                    header={props.reportType + ' Report'}
                    body={
                        (showReportList || props.reportType === 'Load') &&
                        <React.Fragment>
                            <ListGroup>
                                {reportListHtml}
                            </ListGroup>
                        </React.Fragment>
                    }
                    buttons={[
                        {
                            variant: 'warning',
                            show: props.reportType === 'Load' && selectedReport !== '',
                            click: () => setShowDeleteModal(true),
                            text: 'Delete'
                        },
                        {
                            variant: 'success',
                            show: props.reportType === 'Load' && selectedReport !== '',
                            click: () => Load(),
                            text: 'Load'
                        },
                        {
                            variant: 'success',
                            show: props.reportType === 'Save',
                            click: () => setShowSaveAsModal(true),
                            text: 'Save New'
                        },
                        {
                            variant: 'warning',
                            show: props.reportType === 'Save' && showReportList === false,
                            click: () => setShowReportList(true),
                            text: 'Overwrite a saved report'
                        },
                        {
                            variant: 'warning',
                            show: props.reportType === 'Save' && selectedReport !== '',
                            click: () => setShowOverwriteModal(true),
                            text: 'Overwrite ' + selectedReport
                        },
                        {
                            variant: 'danger',
                            show: true,
                            click: () => closeModal(),
                            text: 'Cancel'
                        }
                    ]}
                />
            }

            {
                showDeleteModal &&
                <ModalTemplate show={showDeleteModal} size='md' className='overwrite-modal' header={'Delete?'} body={'Are you sure you wish to delete ' + selectedReport + '?'}
                    buttons={[
                        { variant: 'success', show: true, click: () => Delete(selectedReport), text: 'Delete' },
                        { variant: 'danger', show: true, click: () => setShowDeleteModal(false), text: 'Cancel' }
                    ]}
                />
            }

            {
                showOverwriteModal &&
                <ModalTemplate show={showOverwriteModal} size='md' className='overwrite-modal' header={'Overwrite?'} body={'Are you sure you wish to overwrite ' + selectedReport + '?'}
                    buttons={[
                        { variant: 'success', show: true, click: () => Save(selectedReport, props.getReportQuery(), true), text: 'Overwrite' },
                        { variant: 'danger', show: true, click: () => setShowOverwriteModal(false), text: 'Cancel' }
                    ]}
                />
            }

            {
                showSaveAsModal &&
                <ModalTemplate show={showSaveAsModal} size='md' header={'Save As...'}
                    body={
                        <React.Fragment>
                            <label>Save As:</label>
                            <input value={saveName} onChange={(event) => setSaveName(event.target.value)} />
                            {
                                ['-', '#', '*', '/', '\\', '%', '&', '|'].some(char => { return saveName.indexOf(char) >= 0 }) &&
                                <p style={{ color: 'red' }}>No special characters allowed.</p>
                            }
                        </React.Fragment>
                    }
                    buttons={[
                        {
                            variant: 'success',
                            show: !['-', '#', '*', '/', '\\', '%', '&', '|'].some(char => { return saveName.indexOf(char) >= 0 }) && saveName !== '',
                            click: () => Save(saveName, props.getReportQuery()),
                            text: 'Save'
                        },
                        {
                            variant: 'danger',
                            show: true,
                            click: () => setShowSaveAsModal(false),
                            text: 'Cancel'
                        }
                    ]}
                />
            }
        </React.Fragment>
    )
}

export default ReportModal;


function ModalTemplate(props) {
    let buttons = [];
    props.buttons.forEach((b) => {
        buttons.push(
            <React.Fragment>
                {
                    b.show &&
                    <Button variant={b.variant} onClick={() => b.click()}>{b.text}</Button>
                }
            </React.Fragment>
        )
    })

    return (
        <Modal show={props.show} centered size={props.size} dialogClassName={props.className} >
            {
                props.header &&
                <Modal.Header>
                    {props.header}
                </Modal.Header>
            }
            <Modal.Body>
                <p>{props.body}</p>
            </Modal.Body>
            <Modal.Footer>
                {buttons}
            </Modal.Footer>
        </Modal>
    )
}